import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import NotificationItem from "./NotificationItem";

class Index extends Component {
    render() {
        let { translate } = this.props
        const conversations = this.props.conversations.filter(c => c.last_message !== null).slice(0, 3);

        const hasUnread = conversations.filter(c => c.unread_count > 0);

        return (
            <div className="header-messages">
                <div data-toggle="dropdown" data-display="static">
                    <a>
                        <i className="icon-chat"></i>
                        {
                            hasUnread.length > 0 &&
                            <div className="messages-number"></div>
                        }
                    </a>
                </div>
                <div className="dropdown-menu header-messages-menu">
                    <div className="header-messages-top">
                        <h3>{translate('header.message.title')}</h3>
                        <Link to="/chat">{translate('articles.details.view_all')}</Link>
                    </div>

                    <div className="header-messages-body">
                        {
                            conversations.map((conversation, index) => {
                                return <NotificationItem
                                    key={index}
                                    conversation={conversation}
                                    unread={conversation.unread_count > 0}
                                    selectConversation={this.props.selectConversation}
                                    history={this.props.history}
                                />
                            })
                        }

                        {/*<div className="header-messages-older">*/}
                        {/*    <h3>Earlier</h3>*/}
                        {/*</div>*/}

                    </div>

                </div>
            </div>
        );
    }
}

export default Index;
