import {
    TOP_RATED_CONTRACTS
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case TOP_RATED_CONTRACTS:
        return action.payload
      default:
        return state
    }
  }
