import React
  from "react";

const ApproveModal = (props) => {
  return (
    <div class="modal fade modal-ge delete-modal" id="cancel-subscription" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="payment-modal-wrap modal-ge-wrap">
            <div class="modal-ge-content">
              <div class="subscription-modal-icon">
                <i class="icon-subscribtion"></i>
              </div>
              <h1>Are you sure?</h1>
              <p>You are about to cancel your subscription plan. You can still use the subscription benefit until the 1st of the next month. After that you can still use free plan.</p>
              {props.cancelError && <p style={{color: 'red'}}>{props.cancelError}</p>}
              <div class="modal-ge-bottom-buttons">
                <a href="#" class="button button-white" data-dismiss="modal">Keep plan</a>
                <a href="#" class="button button-danger" onClick={(e) => props.requestToCancelSubscription(e)}>Cancel subscription</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ApproveModal;
