import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function fetchInvitedFriends(successResponse) {
  let url = `${WEB_URL}/invite-friends`;

  api.get(url)
    .then((data) => {
      successResponse(data.data)
    })
    .catch((err) => {
    });
}

export async function fetchInvitedLawyers(successResponse) {
  let url = `${WEB_URL}/invite-lawyers`;

  api.get(url)
    .then((data) => {
      successResponse(data.data)
    })
    .catch((err) => {
    });
}

export async function inviteFriend(email, successInviteFriend) {
  let url = `${WEB_URL}/invite-friends`;
  let params = {
    email: email
  }

  api.post(url, qs.stringify(params))
    .then((data) => {
      successInviteFriend()
    })
    .catch((err) => {
    });
}

export async function inviteLawyer(email, successInviteLawyers) {
  let url = `${WEB_URL}/invite-lawyers`;
  let params = {
    email: email
  }

  api.post(url, qs.stringify(params))
    .then((data) => {
      successInviteLawyers()
    })
    .catch((err) => {
    });
}
