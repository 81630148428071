import {
  FETCH_LAWYER_RECOMMENDED
} from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case FETCH_LAWYER_RECOMMENDED:
      return action.payload
    default:
      return state
  }
}
