import React
    from 'react';
import { lawyersRecommended }
  from "../../../actions/lawyersActions";
import { connect }
  from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';
import ReactLoading
  from 'react-loading';
import LawyerInfoWide
  from '../../../components/shared/LawyerInfoWide';
import { Link }
  from "react-router-dom";

class RecommendedLawyers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedLawyersLoading: true,
    };
  }

  displaySliderItems() {
    let { recommendedLawyers } = this.props
    let lawyers = recommendedLawyers && recommendedLawyers.data;
    return (
      lawyers && lawyers.map((i) => {
        return (
          <LawyerInfoWide id={i.id} name={i.name} key={i.id} imageSource={i.avatar} rating={i.rating}/>
        );
      })
    );
  }

  render() {
    let { translate } = this.props
    return(
      <section className="rated-lawyers mg-top-180 pd-rl">
        <div className="container-fluid">
          <div className="main-title">
            <h1>{translate("home.recommended")}</h1>
            <Link to={`/lawyers`}>{translate("home.view_more")}</Link>
          </div>
          <div className="rated-lawyers-wrap">
            {this.displaySliderItems()}
          </div>
          <div className="rated-lawyers-controls">
            <button className="rated-button-prev"><i className="icon-back"></i></button>
            <button className="rated-button-next"><i className="icon-next"></i></button>
          </div>
        </div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            recommendedLawyers: state.lawyersRecommended,
          })
}

export default connect(mapStateToProps, {lawyersRecommended})(RecommendedLawyers);
