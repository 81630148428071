import React
  from "react";

import { Link }
  from "react-router-dom";

import Logo
    from '../../assets/img/logo-white.svg'

import BlackLogo
  from '../../assets/img/logo-black.svg'

import { AuthButtonsSection, CurrentUserDropdown }
  from '../../components/auth'

import { getToken }
  from "../../helpers";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: window.location.pathname,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({page: window.location.pathname})
  }

  displayRightSection() {
    let { translate, modalClick } = this.props
    if(!getToken()) {
      return (
        <AuthButtonsSection
          translate={translate}
          modalClick={modalClick}
        />
      )
    } else {
      return (
        <CurrentUserDropdown
          translate={translate}
          modalClick={modalClick}
          {...this.props}
        />
      )
    }
  }

  render() {
    let currentPage = this.state.page
    let { translate } = this.props
    return (
      <header className={
          currentPage == '/' && getToken() == null
          || currentPage.startsWith('/lawyers/')
          || currentPage.startsWith('/profile')
          || currentPage.startsWith('/contracts/')
          || currentPage.startsWith('/article/')
            ? "header pd-rl"
            : "header header-pg pd-rl"}>
        <div className="container-fluid">
          <div className="header-wrap">
            <div className="header-logo">
              <Link to={`/`}><img src={
              currentPage == '/' && getToken() == null
              || currentPage.startsWith('/lawyers/')
              || currentPage.startsWith('/profile')
              || currentPage.startsWith('/contracts/')
              || currentPage.startsWith('/article/')
                ? Logo
                : BlackLogo} alt="logo"/></Link>
            </div>
            <div className="header-nav">
              <ul>
                <li className={ this.state.page === '/' ? "active" : ''} onClick={()=> this.setState({page: "/"})}>
                  <Link to={`/`}>
                    { getToken() ? translate('header.tabs.home') : translate('header.tabs.explore')}
                  </Link>
                </li>
                <li className={ this.state.page === '/lawyers' ? "active" : ''}  onClick={()=> this.setState({page: "/lawyers"})}>
                  <Link to={`/lawyers`}>
                    {translate('header.tabs.lawyers')}
                  </Link>
                </li>
                <li className={ this.state.page === '/contracts' ? "active" : ''} onClick={()=> this.setState({page: "/contracts"})}>
                  <Link to={`/contracts`}>
                    {translate('header.tabs.contracts')}
                  </Link>
                </li>
                {/*
                <li className={ this.state.page === '/court' ? "active" : ''} onClick={()=> this.setState({page: "/court"})}>
                  <Link to={`/court`}>
                    {translate('header.tabs.court')}
                  </Link>
                </li>
                */}
                <li className={ this.state.page === '/knowledge' ? "active" : ''} onClick={()=> this.setState({page: "/knowledge"})}>
                  <Link to={`/knowledge`}>
                    {translate('header.tabs.knowledge')}
                  </Link>
                </li>
              </ul>
            </div>
            {this.displayRightSection()}
            <button className="main-menu-indicator" id="open-menu">
              <span className="line"></span>
            </button>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
