import React from 'react';

import { Link }
  from "react-router-dom";

import CourtOne
    from '../../../assets/img/court-1.png';

import CourtTwo
    from '../../../assets/img/court-2.png';

import CourtThree
    from '../../../assets/img/court-3.png';

import CourtFour
    from '../../../assets/img/court-4.png';

import DownloadAppSection
    from '../../../components/shared/DownloadAppSection';

class Court extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let { translate } = this.props

    return (
      <div>
      <title>{translate('court.app_title')} | WAL</title>
      <main className="main">

       <section className="court-section court-section-one mg-top-220 pd-rl">
         <div className="container-fluid">
           <div className="row align-items-center court-section-reverse">
             <div className="col-md-12 col-lg-7">
               <div className="court-section-img">
                 <img src={CourtOne} alt="court" className="img-fluid" />
               </div>
             </div>
             <div className="col-md-12 col-lg-5">
               <div className="court-section-content">
                 <h1>{translate('court.court_one_title')}</h1>
                 <p>{translate('court.court_one_desc')}</p>
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="court-section court-section-two mg-top-80 pd-rl">
         <div className="court-section-two-wrap pd-rl">
           <div className="container-fluid">
             <div className="row align-items-center">
               <div className="col-md-12 col-lg-5">
                 <div className="court-section-content court-section-content-white">
                   <h1>{translate('court.court_two_title')}</h1>
                   <p>{translate('court.court_two_desc')}</p>
                   <a href="" onClick={() => this.props.onClick(0)}
                    className="button button-transparent" data-toggle="modal" data-target="#login">Login</a>
                 </div>
               </div>
               <div className="col-md-12 col-lg-7">
                 <div className="court-section-img">
                   <img src={CourtTwo}  alt="court" className="img-fluid" />
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="court-section court-section-three mg-top-120 pd-rl">
         <div className="container-fluid">
           <div className="row align-items-center">
             <div className="col-md-12 col-lg-12">
               <div className="court-section-content center-text">
                 <h1>{translate('court.court_three_title')}</h1>
                 <p>{translate('court.court_three_desc')}</p>
                </div>
             </div>
             <div className="col-md-12 col-lg-12">
               <div className="court-section-img center-img">
                 <img src={CourtThree}  alt="court" className="img-fluid" />
               </div>
             </div>
           </div>
         </div>
       </section>

       <section className="court-section court-section-four mg-top-160 pd-rl">
         <div className="container-fluid">
           <div className="row align-items-center">
             <div className="col-md-12 col-lg-4">
               <div className="court-section-content">
                 <h1>{translate('court.court_four_title')}</h1>
                 <p>{translate('court.court_four_desc')}</p>
                   <a href="" onClick={() => this.props.onClick(1)}
                   className="button button-primary" data-toggle="modal" data-target="#login"><i className="icon-plus"></i>Register</a>
               </div>
             </div>
             <div className="col-md-12 col-lg-8">
               <div className="court-section-img center-img">
                 <img src={CourtFour}  alt="court" className="img-fluid" />
               </div>
             </div>
           </div>
         </div>
       </section>

       <DownloadAppSection
         translate={translate}
       />

      </main>
    </div>
    )
  }
}

export default Court
