import React
  from 'react';

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

import Person
  from '../../../assets/img/person-7.png';

import Velocity
  from '../../../assets/img/logo-black.svg';

import Image
  from "../../../components/shared/Image";

import { getToken }
  from "../../../helpers";

class RightContentSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareValue: "wal.worl/as2049sc.12",

    };
    this.inputShareRef = React.createRef();
  }

  copyToClipboard(e) {
    e.preventDefault();
    this.inputShareRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  onClickForChat(e) {
    e.preventDefault();
    if(getToken()) {
      alert("comming soon")
    } else {
      window.$('#login').modal('toggle')
    }
  }

  render() {
    let { lawyer, company, translate } = this.props

    return(
      <div className="col-md-12 col-lg-12 col-xl-4">
        <div className="profile-aside">
          <div className="profile-create-item">
            <div className="profile-create-right">
              <div className="lawyers-img">
                <Image src={lawyer && lawyer.avatar} alt="lawyer" />
                <div class={`status ${lawyer && lawyer.is_online ? 'active ' : 'inactive'}`}></div>
              </div>
              <div className="profile-create-content">
                <h1>{lawyer && lawyer.name}</h1>
                <p>{translate('contracts.details.lawyer')}</p>
              </div>
            </div>
            <div className="profile-create-left">
              <a href="/" onClick={this.onClickForChat.bind(this)} className="chat"><i className="icon-chat"></i></a>
            </div>
          </div>
          <div className="profile-create-item">
            <div className="profile-create-right">
              <div className="profile-create-img">
                <Image src={company && company.image} alt="company" />
              </div>
              <div className="profile-create-content">
                <h1>{company && company.name}</h1>
                <p>{translate('contracts.details.company')}</p>
              </div>
            </div>
            <div className="profile-create-left">
              <a href="#"><i className="icon-next"></i></a>
            </div>
          </div>
          <div className="profile-aside-copy">
            <div className="copy-link">
              <input type="text" className="wal-form-control" ref={this.inputShareRef} value={`${window.location.href}`} readonly />
              <a href="" className="copy" onClick={this.copyToClipboard.bind(this)}><i className="icon-copy"></i></a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RightContentSide
