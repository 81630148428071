import React
    from 'react';

import { Link }
  from "react-router-dom";
  import Image
    from "../../../../components/shared/Image";
import ReactLoading
  from 'react-loading';

class SearchedItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  truncate(str) {
    return str.length > 45 ? str.substring(0, 40) + "..." : str;
  }

  render() {
    let { title, cardTitle, items, link } = this.props
    return (
      <>
        <div class="search-result-title">
            <h1>{title}</h1>
        </div>
        {items.map((item, index)=>{
          return (
            <a href={`${link}`+item.id} className="search-result-item">
              { cardTitle == "Article"
              ?
                <div class="search-result-icon">
                  <i class="icon-arcticles"></i>
                </div>
              :
                <div class="search-result-img">
                  <Image src={item.avatar} alt="lawyer" />
                  <div class="status active"></div>
                </div>
              }
              <div class="search-result-text">
                <h1>{cardTitle == "Article" ? this.truncate(item.title) : item.name}</h1>
                <span>{cardTitle}</span>
              </div>
           </a>
          )
        })}
      </>
    )
  }
}

export default SearchedItemCard;
