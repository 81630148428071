import { WEB_URL } from "../constants/types";
import { getLanguageAsNumber, getLanguage }
  from '../services/storeLanguage';
import api from "../api";

export async function getCategories(addCategories) {
  let url = `${WEB_URL}/categories?language_id=${getLanguageAsNumber}`;
  api
    .get(url)
    .then((data) => {
      addCategories(data.data);
    })
    .catch((err) => {
      console.log("GET CATEGORIES ERROR", err);
    });
}

export async function getLanguages(addLanguages) {
  let url = `${WEB_URL}/languages?language_id=${getLanguageAsNumber}`;
  api
    .get(url)
    .then((data) => {
      addLanguages(data.data);
    })
    .catch((err) => {
      console.log("GET CATEGORIES ERROR", err);
    });
}

export async function getCountries(addCountries) {
  let url = `${WEB_URL}/countries`;
  api
    .get(url)
    .then((data) => {
      addCountries(data.data);
    })
    .catch((err) => {
      console.log("GET CATEGORIES ERROR", err);
    });
}

export async function getLawyers(addLawyers) {
  let url = `${WEB_URL}/lawyers`;
  api
    .get(url)
    .then((data) => {
      addLawyers(data.data);
    })
    .catch((err) => {
      console.log("GET CATEGORIES ERROR", err);
    });
}

export async function getHashtags(addHashtags) {
  let url = `${WEB_URL}/hashtags?language_id=${getLanguageAsNumber}`;
  api.get(url)
     .then((data) => {
       addHashtags(data.data);
     })
     .catch((err) => {
       console.log("GET CATEGORIES ERROR", err);
     });
}

export async function getSubCategories(addSubCategories) {
  let url = `${WEB_URL}/categories/subcategories?language_id=${getLanguageAsNumber}`;

  api.get(url)
     .then((data) => {
       addSubCategories(data.data);
     })
     .catch((err) => {
       console.log("GET CATEGORIES ERROR", err);
     });
}

export async function getJurisdictions(addJurisdictions) {
  let url = `${WEB_URL}/all-jurisdictions?language_id=${getLanguageAsNumber}`;
  api.get(url)
     .then((data) => {
       addJurisdictions(data.data);
     })
     .catch((err) => {
       console.log("GET CATEGORIES ERROR", err);
     });
}
