import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomPasswordInput, RadioSelectInput, CustomSelect }
  from '../../components/shared/forms';

import { SuccessAlert, ErrorAlert }
  from '../../components/settings/shared';

import { updatePassword }
  from '../../actions/profile';

import ReactLoading
  from 'react-loading';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      errors: null,
      current_password: null,
      new_password: null,
      new_password_confirmation: null,
      currentPasswordInput: "password",
      newPasswordInput: "password",
      newPasswordConfirmationTypeInput: "password",
      successUpdated: false,
      errorUpdated: false
    };
  }

  handleChangeState(e) {
    let { errors } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({[name]: value})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  updatePassword() {
    let { current_password, new_password, new_password_confirmation } = this.state
    let updatePasswordParams = { _method: 'PUT', current_password, new_password, new_password_confirmation }
    this.setState({loading: true})
    this.props.updatePassword(updatePasswordParams, this.stopLoading.bind(this), this.handleErrors.bind(this))
  }

  stopLoading(){
    let _this = this
    this.setState({loading: false, successUpdated: true})

    setTimeout(function(){ _this.setState({successUpdated: false}) }, 3000);
  }

  handleErrors(data) {
    let _this = this
    this.setState({errors: data.errors, loading: false, errorUpdated: true})

    setTimeout(function(){ _this.setState({errorUpdated: false}) }, 3000);
  }

  render() {
    const { currentUser, translate } = this.props
    let { successUpdated, errorUpdated, loading, error, errors, type, current_password, new_password, new_password_confirmation, currentPasswordInput, newPasswordInput, newPasswordConfirmationTypeInput } = this.state
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-change-password">
            <div className="row settings-change-password-wrap">
              <div className="col-md-6 col-lg-6 form-border">
                <div className="settings-change-password-form">
                  <div className="wal-form wal-form-group">
                    <CustomPasswordInput
                      labelName={translate('auth.current_password')}
                      name={'current_password'}
                      placeholder={translate('auth.current_password_placeholder')}
                      value={current_password}
                      handleChange={this.handleChangeState.bind(this)}
                      errors={errors}
                      typeInput={currentPasswordInput}
                      changeInputType={() => this.setState({currentPasswordInput: currentPasswordInput == "text" ? "password" : "text"})}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="settings-change-password-content">
                  <p>{translate('settings.change_password.first_description')}.</p>
                </div>
              </div>
            </div>
            <div className="row settings-change-password-wrap">
              <div className="col-md-6 col-lg-6 form-border">
                <div className="settings-change-password-form">
                  <div className="wal-form wal-form-group">
                    <CustomPasswordInput
                      labelName={translate('auth.new_password')}
                      name={'new_password'}
                      placeholder={translate('auth.new_password_placeholder')}
                      value={new_password}
                      handleChange={this.handleChangeState.bind(this)}
                      errors={errors}
                      typeInput={newPasswordInput}
                      changeInputType={() => this.setState({newPasswordInput: newPasswordInput == "text" ? "password" : "text"})}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="settings-change-password-content">
                  <p>{translate('settings.change_password.second_description')}.</p>

                </div>
              </div>
            </div>
            <div className="row settings-change-password-wrap">
              <div className="col-md-6 col-lg-6 form-border">
                <div className="settings-change-password-form">
                  <div className="wal-form wal-form-group">
                    <CustomPasswordInput
                      labelName={translate('auth.confirm_password')}
                      name={'new_password_confirmation'}
                      placeholder={translate('auth.confirm_password_placeholder')}
                      value={new_password_confirmation}
                      handleChange={this.handleChangeState.bind(this)}
                      errors={errors}
                      typeInput={newPasswordConfirmationTypeInput}
                      changeInputType={() => this.setState({newPasswordConfirmationTypeInput: newPasswordConfirmationTypeInput == "text" ? "password" : "text"})}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="settings-change-password-button">
                  { loading ?
                    <div style={{marginLeft: "46%"}}>
                      <ReactLoading height={40} width={40}  type="spin" color="#bea58b"/>
                    </div>
                    :
                    <button className="button button-primary" onClick={this.updatePassword.bind(this)}>{translate('settings.change_password.change_password_button')}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        { successUpdated &&
          <SuccessAlert
            translate={translate}
            message={translate("shared.password_successfully_update")}
          />
        }
        {
          errorUpdated &&
          <ErrorAlert
            translate={translate}
            message={translate("shared.password_error_update")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, { updatePassword })(ChangePassword);
