import React
  from 'react';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { PaymentDetails, PaymentSuccess } from "./"
import { AddCard, Success } from "../"

class ChatPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "details"
    };
  }

  changeStatus(status) {
    this.setState({status: status})
  }

  render() {
    let { status } = this.state

    return (
      <div className="modal fade modal-ge payment-modal" id="chat-payment-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div class="modal-content">
            { status === "details" && <PaymentDetails {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
            { status === "addCard" && <AddCard {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
            { status === "success" && <PaymentSuccess {...this.props} changeStatus={this.changeStatus.bind(this)} item={this.props.item} /> }
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(ChatPaymentModal);
