import React
    from 'react';

import Person
    from '../../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../../components/auth'

import { addCard, purchaseEntry }
  from '../../../../helpers/payments'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import ReactLoading
  from 'react-loading';

import { getCurrency }
  from '../../../../services/storeCurrency'
import CURRENCIES
  from '../../../../constants/currencies'

class AddCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card_number: "",
      expire: "",
      cvc: "",
      card_owner: "",
      loading: false,
      errors: null
    };
  }

  goNext() {
    window.$('#payment-addCard').modal('toggle');
    setTimeout(function(){
      window.$('#payment-noCard').modal('toggle');
    }, 250);
  }

  handleExpireChange(text) {
    let textTemp = text;
    if (textTemp.length == 2) {
      textTemp += '/';
    } else {
      textTemp = text;
    }
    this.setState({expire: textTemp})
  }

  applyNewCard() {
    let { card_number, expire, cvc, card_owner } = this.state
    this.setState({loading: true})
    addCard({ card_number, expire, cvc, card_owner }, this.successCatchResult.bind(this), this.handleErrors.bind(this))
  }

  successCatchResult(data) {
    this.setState({loading: false})
    this.props.changeStatus("details")
  }

  handleErrors(data) {
    this.setState({errors: data.errors, error: data.message, loading: false})
  }

  applyCreateCardAndPayement() {
    let { card_number, expire, cvc, card_owner } = this.state
    this.setState({loading: true})
    addCard({ card_number, expire, cvc, card_owner }, this.successCatchResultFromPayButton.bind(this), this.handleErrors.bind(this))
  }

  successCatchResultFromPayButton(data) {
    purchaseEntry({cardId: data.data.id, entityType: "contract", entityId: this.props.item.id}, this.successPaymentCatchResult.bind(this), this.handleErrors.bind(this))
  }

  successPaymentCatchResult(data) {
    this.props.changeStatus("success")
  }

  displayApplyButton() {
    let { translate } = this.props
    if(!this.state.loading) {
      return (
        <>
          <button className="button button-white" onClick={() => this.props.changeStatus("details")}>{translate("payment.cancel")}</button>
          <button className="button button-bl" onClick={() => this.applyNewCard()}><i className="icon-plus"></i>{translate("payment.add_card")}</button>
        </>
      )
    } else {
      return (
        <div style={{ height: 50, width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 10}}>
          <ReactLoading type="spin" color="#bea58b" width="10%" height="10%" />
        </div>
      )
    }
  }

  displayPaymentButton() {
    if(!this.state.loading) {
      return (
        <div className="modal-ge-bottom">
          <button className="button button-primary" onClick={this.applyCreateCardAndPayement.bind(this)}><i className="icon-purchase"></i>Pay now</button>
        </div>
      )
    }
  }

  displayHeaderInfo() {
    let { subscription, plan, translate, currencies } = this.props
    let currentCurrency = CURRENCIES[getCurrency]

    return (
      <div class="payment-modal-info">
        <div class="payment-modal-info-wrap">
          <h2>{subscription ? plan.name : "Construction agreement"}</h2>
          <p>{subscription ? translate("payment.subscription") : translate("payment.cancel")}</p>
        </div>
        <div class="payment-modal-info-price">
          {subscription ?
            <span>{currentCurrency.name.toUpperCase()} {plan.price}</span>
            :
            <span>{currencies['download_contract'][currentCurrency.name]}.- {currentCurrency.name.toUpperCase()}</span>
          }
        </div>
      </div>
    )
  }

  render() {
    let { translate } = this.props
    let { card_number, expire, cvc, card_owner, errors, error } = this.state

    return (
      <div className="payment-modal-wrap modal-ge-wrap">
        <div className="modal-ge-top payment-modal-top">
          <h1>{translate("payment.title")}</h1>
        </div>
        <div className="modal-ge-body">
          <div className="payment-modal-title">
            <h3>{translate("payment.order_summery")}</h3>
          </div>
          {this.displayHeaderInfo()}
          <div className="payment-modal-title">
            <h3>{translate("payment.payment_method")}</h3>
          </div>
          {error &&<p style={{color: "red"}}>{error}</p>}
          <div className="payment-form">
            <div className="row align-items-end">
              <div className="col-md-8 col-lg-8">
                <div className={`wal-form wal-form-group payment-form-group ${errors && errors.card_number && 'wal-form-error'}`}>
                  { errors && errors.card_number && <div class="error"><span>{errors.card_number}</span></div> }
                  <input
                    type="text"
                    name="card_number"
                    className="wal-form-control"
                    placeholder={translate("payment.type_card_number")}
                    value={card_number}
                    maxlength="16"
                    onChange={(e) => this.setState({card_number: e.target.value})}
                    />
                  <div className="payment-form-icon">
                    <i className="icon-creditcard-cc"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className={`wal-form wal-form-group payment-form-group ${errors && errors.cvc && 'wal-form-error'}`}>
                  { errors && errors.cvc && <div class="error"><span>{errors.cvc}</span></div> }
                  <input
                    type="text"
                    name="cvc"
                    className="wal-form-control"
                    placeholder="CVV"
                    maxlength="4"
                    value={cvc}
                    onChange={(e) => this.setState({cvc: e.target.value})}
                    />
                  <div className="payment-form-icon">
                    <i className="icon-card-cvv"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-8">
                <div className={`wal-form wal-form-group ${errors && errors.card_owner && 'wal-form-error'}`}>
                  <label>Card holder</label>
                  <input
                    type="text"
                    name="card_owner"
                    className="wal-form-control"
                    placeholder={translate("payment.type_full_name")}
                    value={card_owner}
                    onChange={(e) => this.setState({card_owner: e.target.value})}
                    />
                  { errors && errors.cvc && <div class="error"><span>{errors.card_owner}</span></div> }
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className={`wal-form wal-form-group payment-form-group ${errors && (errors.expiration_month || errors.expiration_year) && 'wal-form-error'}`}>
                  { errors && (errors.expiration_month || errors.expiration_year) && <div class="error"><span>{errors.expiration_month || errors.expiration_year}</span></div> }
                  <input
                    type="text"
                    name="expire"
                    className="wal-form-control"
                    placeholder="MM/YY"
                    onChange={(e) => this.handleExpireChange(e.target.value)}
                    value={expire}
                    maxLength={5} />
                  <div className="payment-form-icon">
                    <i className="icon-calendar"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="payment-form-info">
                  <p>{translate("payment.card_description")}</p>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="payment-form-buttons">
                  {this.displayApplyButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.displayPaymentButton()}
        {!this.props.notClose && <div className="modal-ge-close" data-dismiss="modal"><i className="icon-x"></i></div>}
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            currencies: state.currencies
          })
}

export default connect(mapStateToProps, {})(AddCard);
