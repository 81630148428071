import React, {Component} from 'react';

class ChatMessageInput extends Component {

    constructor(props) {
        super(props);

        this.imageUploadInputRef = React.createRef();

        this.state = {
            body: "",
            type: "text"
        }

    }

    onFocus = () => {
        if (this.props.conversation.unread_count) {
            this.props.readConversationMessages({conversation_id: this.props.conversation.id})
        }
    }

    onEnter = () => {
        if (this.state.body.length > 0) {
            this.props.addMessage({
                conversation_id: this.props.conversation.id,
                body: this.state.body,
                type: this.state.type
            })
            this.setState({
                body: "",
                type: "text"
            })
        }
    }

    onKeyPress = (event) => {
        if (event.key.toLowerCase() == 'enter') {
            this.onEnter();
        }
    }

    onTextChange = (event) => {
        this.setState({
            body: event.target.value
        })

        this.props.fireTyping();

    }

    typing = () => {
    }

    onLocationShare = async (event) => {
        event.preventDefault();

        const {state} = await navigator.permissions.query({name: 'geolocation'})

        if (state === 'granted') {
            navigator.geolocation.getCurrentPosition((position) => {
                const coords = position?.coords;

                if (coords) {
                    this.setState({
                        type: 'map',
                        body: JSON.stringify({lat: coords.latitude, long: coords.longitude})
                    }, this.onEnter)
                }
            });
        } else {
            alert('Geolocation is not enabled. Please enable to use this feature')
        }
    }

    openFileUpload = (event) => {
        event.preventDefault();

        this.imageUploadInputRef.current.click();
    }

    uploadImageMessage = (event) => {
        const file = event.target.files[0];

        const form = new FormData();

        form.append("body", file);
        form.append("type", "image");

        this.props.addImageMessage({
            conversation_id: this.props.conversation.id,
            form: form
        })
    }

    onContractShare = (event) => {
        this.props.openContractModal();
    }

    render() {
        return (
            <div className="messages-box-footer">
                <div className="messages-box-footer-inner">
                    <div className="messages-box-footer-wrap">
                        <div className="messages-box-attachment">
                            <div className="dropdown-attachment dropup">
                                <button
                                    data-toggle="dropdown"
                                    data-display="static"
                                    aria-expanded="true"
                                    className="attachment-options"
                                >
                                    <i className="icon-add-attachment"></i>
                                </button>
                                <ul className="dropdown-menu more-options-menu dropdown-attachment-menu">
                                    <li>
                                        <a href="#" onClick={this.openFileUpload}>
                                            <i className="icon-report-lawyer"></i>Add image
                                        </a>
                                        <input accept="image/*" hidden type="file" ref={this.imageUploadInputRef}
                                               onChange={this.uploadImageMessage}/>
                                    </li>
                                    <li>
                                        <a href="#" onClick={this.onLocationShare}>
                                            <i className="icon-locations"></i>Share location
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={this.onContractShare}>
                                            <i className="icon-contracts"></i>Add contract
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="messages-box-input">
                            <input
                                placeholder="Type a message"
                                type="text"
                                className="wal-form-control"
                                name="body"
                                value={this.state.body}
                                onChange={this.onTextChange}
                                onKeyPress={this.onKeyPress}
                                autoComplete="off"
                                onFocus={this.onFocus}
                            />
                        </div>
                    </div>
                    <div className="messages-box-send">
                        <button className="button button-bl" onClick={this.onEnter}>
                            <i className="icon-send"></i>
                            Send
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatMessageInput;