import React
  from 'react'
import { connect }
  from "react-redux";
import FacebookLogin
  from 'react-facebook-login/dist/facebook-login-render-props';
import { facebookLogin }
  from '../../../actions/auth';
import { FACEBOOK_CLIENT_ID } from '../../../constants/types';

class FacebookAuthButton extends React.Component {
  displayFacebokButton() {
    let { translate, facebookTextButton } = this.props
    return (
      <FacebookLogin
        appId={FACEBOOK_CLIENT_ID}
        callback={this.responseFacebook}
        render={renderProps => (
          <a href={undefined}  onClick={renderProps.onClick}>
            <i className="icon-facebook-logo"></i>{facebookTextButton}
          </a>
        )}
      />
    )
  }

  responseFacebook = (response) => {
    if(response.status !== "unknown") {
      this.props.startLoading()
      this.props.facebookLogin(response.accessToken, () => this.props.stopLoading())
    }
  }

  render() {
    return (
      this.displayFacebokButton()
    )
  }
}

export default connect(null, { facebookLogin })(FacebookAuthButton);
