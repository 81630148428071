import React from "react";

import LawyerCard from "../../components/lawyers/LawyerCard";

import ContractsSearched from "../../components/contracts/ContractsSearched";

const BookmarksSection = (props) => {
  return  <div class="tab-pane" id="bookmarks">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4 class="panel-title nav-list">
        <a
          data-toggle="collapse"
          data-target="#collapse2"
          aria-expanded="false"
          data-parent="#tabs"
        >
          {props.translate('profile.bookmarks')}
        </a>
      </h4>
    </div>
    <div
      id="collapse2"
      class="panel-collapse collapse"
      data-parent="#tabs"
    >
      <div class="panel-body nav-content">
        {props.contractBookmarks.length > 0 || props.lawyerBookmarks.length > 0 ?
        (
          <>
            <div class="profile-article">
              <h1>{props.translate('profile.lawyers')}</h1>
            </div>
            <div class="profile-lawyers">
              {props.lawyerBookmarks.map((item, index) => {
                return <LawyerCard item={item} />;
              })}
              {props.lawyersLastPage > props.lawyersPage &&
              <a onClick={props.lawyerMoreClick} class="more-button button button-gray">
                {props.translate('profile.load_more_bookmark_lawyers')}
              </a>
              }
            </div>
            {props.contractBookmarks.length > 0 && (
              <>
                <div class="profile-article">
                  <h1>{props.translate('profile.contracts')}</h1>
                </div>

                <div class="profile-contracts">
                    <ContractsSearched items={props.contractBookmarks} />
                </div>
                {props.contractsLastPage > props.bookmarkContractsPage &&
                <a onClick={props.moreContractsBookmarksClick} class="more-button button button-gray">
                  {props.translate('profile.load_more_bookmark_contracts')}
                </a>
                }
              </>
            )}
          </>
        ) : (
          <div id="collapse2" class="panel-collapse collapse" data-parent="#tabs">
              <div class="panel-body nav-content">
                  <div class="wal-result-none">
                      <div class="row justify-content-center">
                          <div class="col-md-8 col-lg-6">
                              <div class="wal-result-none-wrap">
                                  <div class="wal-result-none-icon">
                                      <i class="icon-bookmarks"></i>
                                  </div>
                                  <h1>{props.translate('profile.no_bookmarks_yet')}</h1>
                                  <p>{props.translate('profile.bookmark_info')}</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )}
      </div>
    </div>
  </div>
</div>
};

export default BookmarksSection;
