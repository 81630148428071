import {
    GUEST_ARTICLES
  } from '../constants/types';
  
  export default function(state=[], action){
    switch(action.type){
      case GUEST_ARTICLES:
        return action.payload
      default:
        return state
    }
  }
  