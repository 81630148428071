import React from "react";

import Person from "../../assets/img/person-7.png";

const AboutMeSection = (props) => {
  return <div class="tab-pane active" id="about">
    <div class="panel panel-default">
    <div class="panel-heading">
        <h4 class="panel-title nav-list">
        <a
            data-toggle="collapse"
            data-target="#collapse1"
            aria-expanded="true"
            data-parent="#tabs"
        >
          {props.translate('profile.about_me')}
        </a>
        </h4>
    </div>
    <div
        id="collapse1"
        class="panel-collapse collapse show"
        data-parent="#tabs"
    >
        <div class="panel-body nav-content">
        <div class="profile-article">
            <h3>{props.translate('profile.bio')}</h3>
            <p>
            {props.currentUser.description || props.translate('profile.no_bio')}
            </p>
        </div>
        </div>
    </div>
    </div>
    </div>
};

export default AboutMeSection;
