import React
  from 'react'

import { Route }
  from "react-router-dom";

import Home
  from './inside/explore'

import { Contracts, ContractsDetails }
  from './inside/contract'

import { Lawyers, LawyersDetails }
  from './inside/lawyer'

import { Knowledge, SingleArticle }
  from './inside/knowledge'

import { SettingScreen }
  from './inside/settings'

import { Court }
  from './inside/court'

import { CurrentProfile }
  from './inside/profile'

import NotificationScreen
  from './inside/NotificationScreen'

import Header
    from '../components/shared/Header'

import Footer
    from '../components/shared/Footer'

import { mySubscription }
  from '../helpers/subscription';

import LoginModal
  from '../components/shared/modals/LoginModal'

import FirstTimeModal
  from '../components/shared/FirstTimeModal'

import { getTranslate }
 from 'react-localize-redux';

import { fetchCurrencies }
 from "../actions/currencyActions";

import { loadProfile }
  from "../actions/auth";

import { connect }
 from 'react-redux';

import { getToken }
 from "../helpers";

import SearchModal
  from "../components/shared/modals/search/SearchModal";
import ChatPaymentAlert
  from "../components/shared/modals/ChatPaymentAlert";
import Chat
  from "./inside/chat/Chat"

import UpdateSocialUserModal
  from "../components/shared/modals/UpdateSocialUserModal";
import { Modal }
  from '../components/shared/modals/payment';
import ChooseSubscriptionCompany
  from "../components/shared/modals/ChooseSubscriptionCompany";

class InsideApp extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      active:0,
      enter: false
    };
  }

  componentDidMount() {
    let { current_user } = this.props
    if (getToken() && !current_user) {
      this.props.loadProfile();
    }
    this.checkUserData(current_user)
    this.checkCompanyData(current_user)
    this.props.fetchCurrencies()
  }

  componentWillReceiveProps(newProps) {
    let { current_user } = newProps
    this.checkUserData(current_user)
    this.checkCompanyData(current_user)
    if (newProps.location.pathname != this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  checkUserData(current_user) {
    if(current_user) {
      let { registration_source, phone, address, nationality_id } = current_user
      if(registration_source == "google" || registration_source == "facebook") {
        if(!nationality_id || !address) {
          window.$('#login-with-social').modal('toggle');
        }
      }
    }
  }

  async checkCompanyData(current_user) {
    if(current_user && current_user.user_type == "company") {
      await mySubscription(this.fetchMySubscription.bind(this))
    }
  }

  fetchMySubscription(data) {
    if(data.data.filter((sub) => sub.canceled_at == null).length == 0) {
      window.$('#subscription-company').modal('toggle');
    }
  }

  render(){
    let { translate } = this.props
    return (
      <>
        <Header
          modalClick={(toggle)=> this.setState({active:toggle, enter: true})}
          {...this.props}
          translate={translate}
        />
        <Route exact path="/" component={Home} />
        <Route exact path="/profile" component={CurrentProfile} />
        <Route exact path="/contracts" component={Contracts} />
        <Route exact path="/contracts/:id" component={ContractsDetails} />
        <Route path="/court" render={(props) =>
          <Court {...props}
            translate={translate}
            onClick={(toggle)=> this.setState({active:toggle})}
          />}
        />
        <Route exact path="/lawyers" component={Lawyers} />
        <Route exact path="/lawyers/:id" component={LawyersDetails} />
        <Route exact path="/knowledge" component={Knowledge} />
        <Route exact path="/article/:id" component={SingleArticle} />
        <Route exact path="/settings" component={SettingScreen} />
        <Route exact path="/chat/:conversation_id?" component={Chat} />
        <Route exact path="/notifications" component={NotificationScreen} />
        <Footer
          props={this.props}
          translate={translate}
          modalClick={(toggle)=> this.setState({active:toggle})}
        />
        { !getToken() &&
          <LoginModal
            active={this.state.active}
            enter={this.state.enter}
          />
        }

        { getToken() &&
          <>
            <UpdateSocialUserModal
              translate={this.props.translate}
            />
            <ChooseSubscriptionCompany
              translate={translate}
            />
          </>
        }
        <SearchModal />

        {/* <FirstTimeModal /> */}
      </>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            current_user: state.currentUser
          })
}

export default connect(mapStateToProps, { fetchCurrencies, loadProfile })(InsideApp);
