import {
    ALL_CONTRACTS,
    CONTRACTS_PER_PAGE
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case ALL_CONTRACTS:
        return action.payload
      case CONTRACTS_PER_PAGE:
        return action.payload
      default:
        return state
    }
  }
