import React
  from 'react';

import Image
  from "../../components/shared/Image";

import { Link }
  from "react-router-dom";

class ArticleCard extends React.Component {
  truncate(str) {
    return str.length > 80 ? str.substring(0, 85) + "..." : str;
  }

  render() {
    let { item } = this.props
    const strippedString = item.description.replace(/(<([^>]+)>)/gi, "");

    return(
      <div className="knowledge-item-list">
        <Link to={`/article/` + item.id} className="knowledge-item-list-wrap">
          <div className="knowledge-item-list-left">
            <div className="knowledge-item-list-img">
              <Image src={item.image} alt="header" />
            </div>
            <div className="knowledge-item-list-content">
              <div className="knowledge-item-list-title">
                <h1>{item.title}</h1>
              </div>
              <div className="knowledge-item-list-info">
                <span className="author">by {item.lawyer.name}</span>
                <span>{item.created_at.slice(0,10)}</span>
                <span>{item.viewed} views</span>
                <span className="type">{this.truncate(strippedString)}</span>
              </div>
            </div>
          </div>
          <div className="knowledge-item-list-view">
            <i className="icon-eye"></i>
          </div>
        </Link>
      </div>
    )
  }
}

export default ArticleCard
