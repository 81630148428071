import React from 'react';
import MessageActions from "./MessageActions";
import {Gmaps, Marker} from "react-gmaps";
import DeletedMessage from "./DeletedMessage";
import moment from "moment";

const params = {v: '3.exp', key: 'AIzaSyASiEPBzDacos5pKZ4KVaCUvTse8YzhuYA'};

class Map extends React.Component {

    onMapCreated = (map) => {
        map.setOptions({
            disableDefaultUI: true
        });
    }

    goToDirection = async (event) => {
        event.preventDefault();
        const {state} = await navigator.permissions.query({name: 'geolocation'})

        const destination = JSON.parse(this.props.body)

        if (state === 'granted') {
            navigator.geolocation.getCurrentPosition((position) => {
                const coords = position?.coords;

                if (coords) {
                    let link = "https://www.google.com/maps/dir/?api=1";

                    link += "&origin=" + coords.latitude + "," + coords.longitude
                    link += "&destination=" + destination.lat + "," + destination.long

                    window.open(link);
                }
            });
        } else {
            alert('Geolocation is not enabled. Please enable to use this feature')
        }
    }

    render() {

        const coords = JSON.parse(this.props.body)

        return (
            <div className={this.props.self ? "messages-item self" : "messages-item"}>
                <div className="messages-item-wrap">
                    <div className="messages-location">
                        <div className="messages-location-wrap">
                            {
                                this.props.status === 'deleted'
                                    ? <DeletedMessage/>
                                    : <Gmaps
                                        width={'100%'}
                                        height={'100%'}
                                        lat={coords.lat}
                                        lng={coords.long}
                                        zoom={10}
                                        params={params}

                                        onMapCreated={this.onMapCreated}>
                                        <Marker

                                            lat={coords.lat}
                                            lng={coords.long}
                                            draggable={false}
                                        />
                                    </Gmaps>

                            }

                            <div className="messages-location-bottom">
                                {
                                    !this.props.self &&
                                    <a href="#" onClick={this.goToDirection}>Get directions<i className="icon-next"></i></a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="messages-time">
                        <span>{moment(this.props.created_at).fromNow(true)}</span>
                    </div>
                    <MessageActions {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Map
