import React from "react";
import { Tabs, Filters, Total, Calls, Contracts, Chats } from "./history"
import { addDays, subDays } from 'date-fns';
import { getOrderHistory }
  from '../../helpers/historyOrder'
import PaginateItem
  from '../../components/shared/PaginateItem';

const COMPONENTS = [ Total, Calls, Contracts, Chats ]

class OrderHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      currentPage: 0,
      search: "",
      sort: '-created_at',
      startDate: subDays(new Date(), 1200),
      endDate: new Date(),
      totalItemsPaginate: {},
      laywerSearchLoading: false,
      page: 1
    };
  }

  fetchOrderHistory(data) {
    this.setState({totalItems: data, loading: false})
  }

  sortHistory(sortSelected){
    if(sortSelected=='Oldest'){
      this.setState({sort: '-created_at' },
      )
    }else if(sortSelected=='Newest'){
      this.setState({sort: 'created_at' })
    }
  }

  changePage(newPage) {
    let _this = this
    this.setState({currentPage: newPage, loading: true, page: 1})
    getOrderHistory(this.fetchOrderHistory.bind(this), { search: this.props.search })
    setTimeout(() => {
      _this.props.history.replace({ hash: "order_history" })
    }, 200);
  }

  changeDateRangeFilter(startDate, endDate) {
    this.setState({startDate, endDate})
  }

  addObjectItems(value) {
    this.setState({totalItemsPaginate: value})
  }

  displayComponents() {
    let { translate } = this.props
    let { currentPage, search, sort, startDate, endDate, loading, totalItems, page } = this.state
    const TagName = COMPONENTS[currentPage];

    return (
      <TagName
        search={search}
        sort={sort}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        totalItems={totalItems}
        addObjectItems={this.addObjectItems.bind(this)}
        page={page}
        translate={translate}
      />
    )
  }

  requestNewPageData(e, page) {
    e.preventDefault()
    this.setState({page: page})
  }

  render() {
    let { search, sort, totalItemsPaginate, laywerSearchLoading } = this.state
    let { translate } = this.props
    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <Tabs
            changePage={this.changePage.bind(this)}
            currentPage={this.state.currentPage}
            translate={translate}
          />
          <Filters
            search={search}
            changeSearch={(value) => this.setState({search: value})}
            sort={sort}
            sortHistory={(value) => this.sortHistory(value)}
            changeDateRangeFilter={(startDate, endDate) => this.changeDateRangeFilter(startDate, endDate)}
            translate={translate}
          />
          {this.displayComponents()}
        </div>
        <PaginateItem
          item={totalItemsPaginate}
          requestNewPageData={this.requestNewPageData.bind(this)}
          searchLoading={laywerSearchLoading}
        />
      </div>
    )
  }
};

export default OrderHistory;
