import React from "react";

import { connect } from 'react-redux';

import { getTranslate } from 'react-localize-redux';

import { IMAGE_BASE } from "../../../constants/types";

import Image from "../../../components/shared/Image";

import ReactLoading from 'react-loading';

import { singleArticle, articleWhenLogged } from '../../../helpers/singleArticle';

import { getArticleReviews } from '../../../actions/getArticleReviews'

import ArticleNotLogged from "../../../components/article/ArticleNotLogged";

import ArticleLogged from "../../../components/article/ArticleLogged";

import { emptyRedux } from '../../../actions/profile'

import { getToken } from "../../../helpers";

import RenderMetaData from "../../../components/shared/RenderMetaData"

import { overrideMetaData } from "../../../services/changeMetaData"

class SingleArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading:true,
        reviewsLoading:true,
        article:{},
        reviews:{},
        currentPage:1,
        lastReviewsPage:0,
        totalReviews:null
    };
  }

  componentDidMount() {
    this.requestData(this.props)
  }

  componentWillReceiveProps(newProps) {
    if(this.props.current_user != newProps.current_user) {
      this.requestData(newProps)
    }
  }

  async requestData(propsVar)  {
    let id = propsVar.match.params.id

    articleWhenLogged(id, this.displayData.bind(this), this.stopLoading.bind(this))
    propsVar.getArticleReviews( id,
            this.stopReviewsLoading.bind(this),
            this.state.currentPage,
            this.allReviewsNumber.bind(this))
  }

  displayData(data){
    let article = data.data
    this.setState({article: article})

    overrideMetaData({
      title: article.title,
      description: article.description,
      image: `${IMAGE_BASE}${article.image}`
    })
  }

  displayReviews(data){
    this.setState({reviews:data.data})
  }

  allReviewsNumber(data){
    this.setState({totalReviews:data.data.total})
  }

  stopReviewsLoading(data){
    this.setState({reviewsLoading: false, lastReviewsPage:data})
  }

  stopLoading() {
    this.setState({loading: false})
  }

  getMoreReviews(){
    let id = this.props.match.params.id
    this.setState({
      currentPage:this.state.currentPage+1
    },()=>
    this.props.getArticleReviews(id,
        this.stopReviewsLoading.bind(this),
        this.state.currentPage,
        this.allReviewsNumber.bind(this))
    )
  }

  applyPress(){
    this.props.emptyRedux()
    this.setState({reviewsLoading:true})
    let id = this.props.match.params.id
    setTimeout(() => {
      this.props.getArticleReviews(id,
          this.stopReviewsLoading.bind(this),
          this.state.currentPage,
          this.allReviewsNumber.bind(this))
    }, 1000);
  }

  componentWillUnmount(){
    this.props.emptyRedux()
  }

  render() {
    let article = this.state.article
    let { translate } = this.props

    if(this.state.loading){
        return  <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <ReactLoading type="spin" color="#bea58b"/>
      </div>
    }


    if(this.props.current_user){
      return (
        <ArticleLogged
          article={article}
          reviews={this.props.articleReviews}
          currentReviewsPage={this.state.currentPage}
          lastReviewsPage={this.state.lastReviewsPage}
          reviewsLoading={this.state.reviewsLoading}
          moreReviews={()=> this.getMoreReviews()}
          bookmarkItem={()=> this.requestData(this.props)}
          reloadArticleAfterReview={()=> this.applyPress()}
          totalReviewsNumber={this.state.totalReviews}
          goBack={()=> this.props.history.goBack()}
          translate={translate}
        />
      )
    }else{
      return (
        <ArticleNotLogged
          article={article}
          reviews={this.props.articleReviews}
          currentReviewsPage={this.state.currentPage}
          lastReviewsPage={this.state.lastReviewsPage}
          reviewsLoading={this.state.reviewsLoading}
          moreReviews={()=> this.getMoreReviews()}
          bookmarkItem={()=> this.requestData(this.props)}
          reloadArticleAfterReview={()=> this.applyPress()}
          totalReviewsNumber={this.state.totalReviews}
          goBack={()=> this.props.history.goBack()}
          translate={translate}
        />
      )
    }

  };
}

function mapStateToProps(state){
  return ({
              translate: getTranslate(state.locale),
              guestArticles: state.guestArticles,
              current_user: state.currentUser,
              articleReviews: state.articleReviews
          })
}

export default connect(mapStateToProps, {getArticleReviews, emptyRedux})(SingleArticle);
