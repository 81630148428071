
import React from "react";
import { setSubInfo }
  from "../../../helpers";
import { subscriptionPlans }
  from '../../../helpers/subscription';
import ReactLoading
  from 'react-loading';
import CompanyModal
  from '../../../components/shared/modals/payment/company/CompanyModal';
import ReactHtmlParser
  from 'react-html-parser'

class ChooseSubscriptionCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoading: true,
      plans: [],
      priceType: "monthly"
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    await subscriptionPlans(this.fetchPlans.bind(this))
  }

  fetchPlans(data) {
    this.setState({plans: data.data, planLoading: false})
  }

  goToSubscription() {
    setSubInfo("choose")
    window.$('#choose_subscription').modal('toggle');
    window.location.href = "settings#subscription";
  }

  checkFreePlan() {
    setSubInfo("free")
    window.$('#choose_subscription').modal('toggle');
  }

  selectPlan(plan) {
    let { priceType } = this.state
    this.setState({planSelected: { id: plan.id, name: plan.name, price: plan.[`${priceType}_price_calc`]}})
    window.$('#subscription-company').modal('toggle');

    setTimeout(function () {
      window.$('#subscrption-company-modal').modal('toggle');
    }, 100);

  }

  displayPlans()  {
    let { plans } = this.state
    let { translate } = this.props
    return (
      <div class="subscription-plan-wrap">
        { plans.map((plan, index) => {
          return (
            <div class="subscription-plan-item">
              <div class="subscription-plan-head">
                <h1>{plan.lang.name}</h1>
                {index == 0 && <span class="sub-info">{translate("modals.company_subscription.free_for_month")}</span>}
              </div>
              <div class="subscription-plan-body">
                <ul>
                  <li>{ReactHtmlParser(plan.lang.description)}</li>
                </ul>
              </div>
              <div class="subscription-plan-footer">

                <span class="price">${plan.monthly_price_calc}</span><span class="month">/{translate("modals.company_subscription.month")}</span>
                <button class="button button-bl" onClick={() => this.selectPlan(plan)}>{translate("modals.company_subscription.select_this_plan")}</button>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    let { priceType, planSelected } = this.state
    let { translate } = this.props
    return (
      <>
        <div class="modal fade modal-ge subscription-modal subscription-modal-plan" id="subscription-company" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content subscription-modal-wrap">
              <div class="modal-ge-top">
                <h1>{translate("modals.company_subscription.choose_subscription_plan")}</h1>
                <p>{translate("modals.company_subscription.choose_subscription_plan_line")}</p>
              </div>
              <div class="subscription-modal-plan">
                {this.displayPlans()}
                <div class="subscription-modal-info">
                  <p>{translate("modals.company_subscription.choose_subscription_plan_description")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CompanyModal
          subscription
          plan={planSelected}
          interval={priceType}
        />
      </>
    )
  }
};

export default ChooseSubscriptionCompany;
