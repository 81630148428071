export const overrideMetaData = (data) => {
  window.$("meta[property='og:title']").attr("content", data.title);
  window.$("meta[name='twitter:title']").attr("content", data.title);
  window.$("meta[name='title']").attr("content", data.title);
  window.$("meta[property='og:description']").attr("content", data.description);
  window.$("meta[name='twitter:description']").attr("content", data.description);
  window.$("meta[name='description']").attr("content", data.description);
  window.$("meta[property='og:image']").attr("content", data.image);
  window.$("meta[name='twitter:image']").attr("content", data.image);
  window.$("meta[name='image']").attr("content", data.image);
  window.$("meta[property='og:url']").attr("content", window.location.href);
  window.$("meta[name='twitter:url']").attr("content", window.location.href);
}
