import React, {Component} from 'react';
import {imageUrl} from "../../../helpers";
import DefaultAvatar from "../../../assets/img/users.svg";
import DeletedMessage from "../messages/DeletedMessage";
import moment from "moment";

class NotificationItem extends Component {

    getLastMessage = (message) => {
        if (message.status === 'deleted') {
            return <DeletedMessage/>
        }

        if (message.type === 'text') {
            return message.body
        } else {
            return '[' + message.type.charAt(0).toUpperCase() + message.type.slice(1) + ']'
        }
    }

    isMyMessage = (conversation) => {
        if (conversation.last_message) {
            const sender = conversation.last_message?.participant_id
            if (sender === conversation.participant?.id) {
                return 'You:'

            }
        }
        return null
    }

    onConversationClick = (item) => {
        this.props.history.push('/chat/' + item.id)
        this.props.selectConversation(item)
    }

    render() {

        const conversation = this.props.conversation

        const participant = conversation.participants[0];

        return (

            <div onClick={() => this.onConversationClick(conversation)}
                 className={this.props.unread ? "header-messages-item message-unread" : "header-messages-item"}>
                <div className="header-messages-right">
                    <div className="header-messages-img">
                        {
                            participant.user.avatar
                                ? <img src={imageUrl(participant.user.avatar)} alt={participant.user.name}/>
                                : <img src={DefaultAvatar} alt={participant.user.name}/>
                        }
                        <div className={"status " + (participant.user.is_online ? "online" : "offline")}></div>
                    </div>
                    <div className="header-messages-info">
                        <h1>{participant.user?.title?.title} {participant.user.name}</h1>
                        {
                            conversation.last_message &&
                            <span>{this.isMyMessage(conversation)} {this.getLastMessage(conversation.last_message)}</span>
                        }
                    </div>
                </div>

                <div className="header-messages-left">
                    <span className="time">{moment(conversation.last_message?.created_at).fromNow()}</span>
                </div>

            </div>

        );
    }
}

export default NotificationItem;