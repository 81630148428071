import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function bookmarkItem(itemId, itemType, catchResult) {
  let data = {
    "entity_id": itemId,
    "entity_type": itemType
  }

  let url = `${WEB_URL}/bookmarks`;

  api.post(url, qs.stringify(data))
    .then((data) => {
      catchResult(1);
    })
    .catch((err) => {
      catchResult(0);
    });
}

export async function deleteBookmarkItem(itemId, itemType, catchResult) {
  let data = {
    "entity_id": itemId,
    "entity_type": itemType
  }

  let url = `${WEB_URL}/bookmarks/1`;
  api.delete(url, { data } )
    .then((data) => {
      catchResult(0)
    })
    .catch((err) => {
      catchResult(1)
    });
}
