import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ConfirmModal extends Component {
    render() {
        return (
            <div className={"modal fade modal-ge delete-modal" + (this.props.open ?  " show" : "")} style={{display: (this.props.open ? "block" : "none")}} id="block-chat" data-backdrop="static" tabIndex="-1"
                 role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="block-modal-wrap modal-ge-wrap">
                            <div className="modal-ge-content">
                                <div className="modal-ge-icon">
                                    <i className={this.props.icon}></i>
                                </div>
                                <h1>{this.props.title}</h1>
                                <p>{this.props.description}</p>
                                <div className="modal-ge-bottom-buttons">
                                    <a href="#" className="button button-white" onClick={this.props.onCancel}>{this.props.cancelBtnLabel}</a>
                                    <a href="#" className="button button-danger" onClick={this.props.onSuccess}>{this.props.successBtnLabel}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ConfirmModal.defaultProps = {
    successBtnLabel: 'Delete',
    cancelBtnLabel: 'Cancel',
    icon: 'icon-delete'
};

ConfirmModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

    successBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,

    icon: PropTypes.string

};

export default ConfirmModal;