import React from 'react';

import ParticipantInfo from "./user/ParticipantInfo";
import ChatMessageInput from "./inputs/ChatMessageInput";
import Message from "./messages/index"
import Typing from "./box/Typing";

import ReactLoading from 'react-loading';

import InfiniteScroll from 'react-infinite-scroll-component';

class ChatMessageBox extends React.Component {

    moreMessages = () => {
        this.props.getMessages({conversation_id: this.props.conversation.id, page: this.props.messagesPage + 1});
    }

    render() {
        return (
            <div className="col-md-12 col-lg-8">
                <div className="messages-box">
                    {
                        this.props.conversation &&
                        <ParticipantInfo {...this.props}/>
                    }
                    <div className="messages-box-body" id="scrollMessages" style={{
                        display: 'flex',
                        flexDirection: 'column-reverse'
                    }}>
                        <InfiniteScroll
                            dataLength={this.props.messages.length}
                            hasMore={this.props.hasMoreMessages}
                            next={this.moreMessages}
                            scrollableTarget="scrollMessages"
                            style={{overflow: "hidden", display: 'flex', flexDirection: 'column-reverse'}}
                            inverse={true}
                            loader={
                                <div style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 25
                                }}>
                                    <ReactLoading type="spin" color="#bea58b" height={30} width={30}/>
                                </div>
                            }
                        >

                            <div className="messages-box-body-wrap">
                                {
                                    !this.props.messagesLoading && <Message {...this.props} />
                                }

                                {
                                    // this.props.userTyping && <Typing/>
                                }
                            </div>
                        </InfiniteScroll>
                    </div>
                    {
                        this.props.conversation &&
                        <ChatMessageInput {...this.props}/>
                    }
                </div>
            </div>
        );
    }
}

export default ChatMessageBox;