import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomSelect }
  from '../../components/shared/forms';

import { fetchCoutries }
  from '../../actions/selectedItemsActions';

import { updateProfile }
  from '../../actions/profile';

import { ConfirmChangeType }
  from '../../components/shared/modals/setting';

import { SuccessAlert, ErrorAlert }
  from '../../components/settings/shared';

import Image
  from '../../components/shared/Image';

import { deleteAvatar }
  from '../../helpers/profile';

import { capitalize }
  from '../../services/manipulateWithString';

import LawyerForm
  from './profile/LawyerForm';

import CompanyForm
  from './profile/CompanyForm';

import ReactLoading
  from 'react-loading';

import { getTranslate }
  from 'react-localize-redux';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ganderOptions: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
      ],
      avatar: '',
      updateParam: {  name: '', surname: '', email: '', phone: '', gender: '', address: '', type: '', company_name: '', nationality_id: null, branch: '', employee_number: null },
      error: null,
      errors: null,
      successUpdated: false,
      errorUpdated: false
    };
  }

  async componentDidMount() {
    let { name, email, phone, gender, address, avatar, user_type, company_name, branch, employee_number } = this.props.currentUser
    await this.props.fetchCoutries()
    await this.setState({
      avatar,
      updateParam: {
        _method: 'PUT',
        name: name,
        email,
        phone,
        gender,
        address,
        type: user_type,
        company_name,
        branch,
        employee_number
      }
    })
  }

  handleChangeState(e) {
    let { errors, updateParam } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({updateParam: {...updateParam, [name]: value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  handleChangeGender(selectedOption) {
    let { errors } = this.state
    this.setState({gender: selectedOption.value})

    if(errors) {
      var costum_errors = errors
      delete costum_errors["gender"]
      this.setState({errors: costum_errors})
    }
  }

  handleChangeCountry(selectedOption) {
    let { updateParam, errors } = this.state
    this.setState({updateParam: {...updateParam, nationality_id: selectedOption.value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors["nationality_id"]
      this.setState({errors: costum_errors})
    }
  }

  displayImage() {
    const { currentUser } = this.props
    let { avatar } = this.state
    if(avatar) {
      if(avatar === currentUser.avatar) {
        return (
          <Image
            src={currentUser.avatar}
            alt="person"
          />
        )
      } else {
        return (
          <img
            alt="profile"
            src={URL.createObjectURL(avatar)}
          />
        )
      }
    } else {
      return (
        <i class="icon-users"></i>
      )
    }
  }

  changeImage(event) {
    if(event.target.files[0] != undefined) {
      this.setState({
        avatar: event.target.files[0],
      })
    } else {
      this.setState({
        avatar: '',
      })
    }
  }

  changeTypeOfUserRequest(userType) {
    this.setState({typeRequest: userType})
    if(this.state.type != userType) {
      window.$('#change-type').modal('toggle');
    }
  }

  approveRequestChangeType() {
    let { updateParam } = this.state
    window.$('#change-type').modal('toggle');
    this.setState({updateParam: {...updateParam, type: this.state.typeRequest}})
  }

  removeImage() {
    let { updateParam } = this.state
    // this.setState({updateParam: {...updateParam, avatar: null}})
    deleteAvatar(this.deleteImageSuccesfully.bind(this))
  }

  deleteImageSuccesfully() {
    window.location.reload()
  }

  submitEditForm() {
    let { updateParam, avatar } = this.state
    const { currentUser } = this.props
    this.setState({loading: true})
    this.props.updateProfile(updateParam, avatar != currentUser.avatar ? avatar : null, this.stopLoading.bind(this), this.handleErrors.bind(this))
  }

  stopLoading(){
    let _this = this
    this.setState({loading: false, successUpdated: true})

    setTimeout(function(){ _this.setState({successUpdated: false}) }, 3000);
  }

  handleErrors(data) {
    let _this = this
    this.setState({errors: data.errors, loading: false, errorUpdated: true})

    setTimeout(function(){ _this.setState({errorUpdated: false}) }, 3000);
  }

  displayButtons() {
    let { translate } = this.props
    if(this.state.loading) {
      return (
        <div className="settings-edit-profile-buttons">
          <div style={{ marginRight: 80}}>
            <ReactLoading height={40} width={40}  type="spin" color="#bea58b"/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="settings-edit-profile-buttons">
          <button className="button button-white" onClick={() => window.location.reload()}>{translate("settings.edit_profile.cancel")}</button>
          <button className="button button-primary" onClick={this.submitEditForm.bind(this)}>{translate("settings.edit_profile.save")}</button>
        </div>
      )
    }
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, updateParam, avatar, successUpdated, errorUpdated } = this.state
    let { type } = updateParam
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-edit-profile">
            <div className="settings-edit-profile-top">
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-6">
                  <div className="wal-form-profile">
                    <div className="wal-form-profile-img">
                      {this.displayImage()}
                    </div>
                    <div className="wal-form-profile-upload">
                      <button className="button button-white">
                        <input
                          type="file"
                          className="wal-form-profile-file"
                          accept="image/*"
                          onChange={e => this.changeImage(e)}
                          id="f02"
                          name="avatar"
                          accept="image/png, image/jpeg"/>
                        <span>{translate("settings.edit_profile.change_profile_picture")}</span>
                      </button>
                    </div>
                    <div className="wal-form-profile-remove">
                      { avatar && <a href="#" onClick={() => this.removeImage()}>{translate("settings.edit_profile.delete_profile_picture")}</a>}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="wal-form-profile-type">
                    <div className="wal-form-profile-type-wrap">
                      <h3>{translate("auth.profile_type")}</h3>
                      <div onClick={() => type != "user" && this.changeTypeOfUserRequest("user")} className={`wal-form-profile-type-item ${type == "user" ? 'active' : ''}`}>
                        <span>{translate("auth.private")}</span>
                        <i className="icon-tick"></i>
                      </div>
                      <div onClick={() => type != "company" && this.changeTypeOfUserRequest("company")} className={`wal-form-profile-type-item ${type == "company" ? 'active' : ''}`} disabled={type == "company"}>
                        <span>{translate("auth.company")}</span>
                        <i className="icon-tick"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-edit-profile-body">
              <div className="settings-edit-profile-body-wrap">
                <LawyerForm
                  {...this.state}
                  currentUser={currentUser}
                  handleChangeState={this.handleChangeState.bind(this)}
                  handleChangeGender={this.handleChangeGender.bind(this)}
                  handleChangeCountry={this.handleChangeCountry.bind(this)}
                  countries={this.props.countries}
                  translate={this.props.translate}
                />
              </div>
            </div>
          </div>
          {this.displayButtons()}
        </div>
        <ConfirmChangeType
          profileType={type}
          approve={() => this.approveRequestChangeType()}
          translate={this.props.translate}
        />
        { successUpdated &&
          <SuccessAlert
            translate={translate}
            message={translate("shared.profile_successfully_update")}
          />
        }
        {
          errorUpdated &&
          <ErrorAlert
            translate={translate}
            message={translate("shared.profile_error_update")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    countries: state.countries
  };
}

export default connect(mapStateToProps, { updateProfile, fetchCoutries })(EditProfile);
