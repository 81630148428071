export const WEB_URL = 'https://wal.apper.ch/api';
// export const WEB_URL = 'https://dev-api.wal.world/api';
export const TOP_RATED_LAWYERS = 'TOP_RATED_LAWYERS'
export const ALL_CONTRACTS = 'ALL_CONTRACTS'
export const TOP_RATED_CONTRACTS = 'TOP_RATED_CONTRACTS'
export const CONTRACTS_WITH_FILTERS = 'CONTRACTS_WITH_FILTERS'
export const CONTRACT = 'CONTRACT'
export const IMAGE_BASE = 'https://wal.apper.ch/uploads/'
// export const IMAGE_BASE = 'https://dev-api.wal.world/uploads/'
export const LAWYERS_BY_REGION = 'LAWYERS_BY_REGION'
export const LAWYERS_BY_FILTER = 'LAWYERS_BY_FILTER'
export const LAWYERS = 'LAWYERS'
export const LAWYERS_FOR_MAP = 'LAWYERS_FOR_MAP'
export const CURRENT_USER = 'CURRENT_USER'
export const LOG_OUT = 'LOG_OUT'
export const COUNTRY_ITEMS = 'COUNTRY_ITEMS'
export const INTEREST_ITEMS = 'INTEREST_ITEMS'
export const GOOGLE_CLIENT_ID = '207643332324-srtcsdpft2sied6ktpftt32p7nqt4eem.apps.googleusercontent.com'
export const FACEBOOK_CLIENT_ID = '306835493897976'
export const LAWYER_BY_ID = 'LAWYER_BY_ID'
export const FETCH_LAWYER_CONTRACTS = 'FETCH_LAWYER_CONTRACTS'
export const FETCH_LAWYER_ARTICLES = 'FETCH_LAWYER_ARTICLES'
export const FETCH_LAWYER_RECOMMENDED = 'FETCH_LAWYER_RECOMMENDED'
export const ARTICLES = 'ARTICLES'
export const CONTRACTS_PER_PAGE = 'CONTRACTS_PER_PAGE'
export const CURRENT_USER_BOOKMARKS = 'CURRENT_USER_BOOKMARKS'
export const CURRENT_USER_CONTRACTS = 'CURRENT_USER_CONTRACTS'
export const CURRENT_USER_BOOKMARKS_LAWYERS = 'CURRENT_USER_BOOKMARKS_LAWYERS'
export const CURRENT_USER_BOOKMARKS_ARTICLES = 'CURRENT_USER_BOOKMARKS_ARTICLES'
export const CURRENT_USER_BOOKMARKS_CONTRACTS = 'CURRENT_USER_BOOKMARKS_CONTRACTS'
export const EMPTY_STATE = 'EMPTY_STATE'
export const GUEST_ARTICLES = 'GUEST_ARTICLES'
export const SEARCH_RESULT = 'SEARCH_RESULT'
export const GET_ARTICLE_REVIEWS = 'GET_ARTICLE_REVIEWS'
export const ARTICLES_FOR_SLIDER = 'ARTICLES_FOR_SLIDER'
export const ORDER_STATISTIC = 'ORDER_STATISTIC'
export const NOTIFICAIONS_LIST = 'NOTIFICAIONS_LIST'

export const PURCHASED_CONTRACTS = 'PURCHASED_CONTRACTS'
export const PURCHASED_CONTRACTS_LOADING = 'PURCHASED_CONTRACTS_LOADING'
export const CURRENCY = 'CURRENCY'
