import React from "react";

import Image from "../shared/Image";

import { IMAGE_BASE } from "../../constants/types";

import ContractsSearched from "../contracts/ContractsSearched";

import DisplayRating from "../shared/DisplayRating";

import ReactHtmlParser
  from 'react-html-parser'

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

class ArticleNotLogged extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
    };
  }

  onClickForChat(e) {
    e.preventDefault();
    window.$('#login').modal('toggle')
  }

  render() {
    let article = this.props.article
    let { translate } = this.props
    return (
      <main class="main">
        <title>{article.title} | WAL</title>
        <section class="profile pd-rl" style={{ backgroundImage: `url(${IMAGE_BASE + article.image})` }}>
             <div class="container-fluid">
                 <div class="profile-wrap">
                     <div class="profile-top">
                         <div class="row">
                             <div class="col-md-12 col-lg-12">
                                 <div class="profile-top-wrap">
                                     <a onClick={this.props.goBack} style={{color:'#ffffff'}}
                                     class="back-button"><i class="icon-back"></i>{translate('articles.details.back')}</a>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="profile-bottom">
                         <div class="row align-items-end">
                             <div class="col-md-12 col-lg-8">
                                 <div class="profile-info">
                                     <div class="profile-info-wrap">
                                         {article.categories.length > 0 &&
                                         <div class="profile-type articles-type">
                                             <span class="type">{article.categories[0].name}</span>
                                             {article.categories.length > 1 &&
                                             <div class="more-list">
                                                 <span>+{article.categories.slice(1, article.categories.length).length}
                                                 more</span>
                                                 <ul>
                                                     {article.categories.slice(1, article.categories.length).map((item,index)=>{
                                                         return <li>- {item.name}</li>
                                                     })}

                                                 </ul>
                                             </div>
                                             }
                                         </div>
                                         }
                                         <div class="profile-name">
                                             <h1>{article.title}</h1>
                                         </div>
                                         <div class="profile-rating-state">
                                             <div class="profile-rating">
                                                 <div class="rated-stars">
                                                 {[1,2,3,4,5].map((item, index)=>{
                                                  return <i
                                                      className={index+1 > article.rating
                                                          ?  "icon-star"
                                                          : "icon-star-filled"}></i>
                                                  })}
                                                 </div>
                                                 <span>{article.rating}</span>
                                             </div>
                                             <div class="profile-type">
                                                 <span><i class="icon-eye"></i>{article.viewed} {translate('articles.details.views')}</span>
                                             </div>
                                             <div class="profile-type">
                                                 <span><i class="icon-chat"></i>{this.props.totalReviewsNumber} {translate('articles.details.comments')}</span>
                                             </div>
                                         </div>
                                         {article.hashtags &&
                                         <div class="articles-tags">
                                             <ul>
                                              {article.hashtags.map((item,index)=>{
                                                  return <li><a href="#">{item.tag}</a></li>
                                              })}
                                             </ul>
                                         </div>
                                          }
                                     </div>
                                 </div>
                             </div>
                             <div class="col-md-6 col-lg-4">
                                 <div class="profile-buttons">
                                     {/*<a href="#" class="button button-primary" data-toggle="modal"
                                     data-target="#login"><i class="icon-eye"></i>Read the full article</a>*/}
                                     <div class="wal-icons">
                                         <div class="dropdown-options contracts-options-dropdown">
                                             <div data-toggle="dropdown" data-display="static">
                                                 <a href="#" class="wal-icons-button options"><i class="icon-share"></i></a>
                                             </div>
                                             <ul class="dropdown-menu">
                                                <div class="more-options">
                                                <span>{translate('articles.details.share_this_article')}</span>
                                                </div>
                                                <li>
                                                <a href="#"><i class="icon-email"></i>{translate('articles.details.mail')}</a>
                                                </li>
                                                <li>
                                                <LinkedinShareButton url={window.location.href} className="share">
                                                    <a href="#"><i className="icon-linkedin"></i>{translate('articles.details.linkedin')}</a>
                                                </LinkedinShareButton>
                                                </li>
                                                <li>
                                                <TwitterShareButton url={window.location.href} className="share">
                                                    <a href="#"><i className="icon-twitter"></i>{translate('articles.details.twitter')}</a>
                                                </TwitterShareButton>
                                                </li>
                                                <li>
                                                <FacebookShareButton url={window.location.href} className="share">
                                                    <a href="#"><i className="icon-facebook-alt"></i>{translate('articles.details.facebook')}</a>
                                                </FacebookShareButton>
                                                </li>
                                            </ul>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>

         <section class="profile-content mg-top-40 pd-rl">
             <div class="container-fluid">
                 <div class="row">
                     <div class="col-md-12 col-lg-12 col-xl-8">
                         <div class="profile-article articles-content">
                             <h3>{translate('articles.details.description')}</h3>
                             <p>{ReactHtmlParser(article.description)}</p>
                         </div>
                         {article.contracts.length > 0 &&
                         <div class="main-title">
                             <h2>{translate('articles.details.linked_contracts')}</h2>
                         </div>
                          }
                         <div class="profile-contracts articles-contracts">
                         {article.contracts.length > 0 &&
                              <ContractsSearched items={article.contracts} />
                          }
                         </div>
                         <div class="main-title">
                             <h2>{translate('articles.details.reviews')}</h2>
                             <a href="#">{translate('articles.details.view_all')}</a>
                         </div>
                         <div class="profile-reviews">
                             <div class="profile-reviews-item">
                                 <span>{translate('articles.details.quality')}</span>
                                 <DisplayRating
                                      stars={[0,1,2,3,4]}
                                      currentRate={article.review && article.review.quality}
                                  />
                             </div>
                             <div class="profile-reviews-item">
                                 <span>{translate('articles.details.professionality')}</span>
                                 <DisplayRating
                                      stars={[0,1,2,3,4]}
                                      currentRate={article.review && article.review.professionality}
                                  />
                             </div>
                             <div class="profile-reviews-item">
                                 <span>{translate('articles.details.experience')}</span>
                                 <DisplayRating
                                      stars={[0,1,2,3,4]}
                                      currentRate={article.review && article.review.experience}
                                  />
                             </div>
                             <div class="profile-reviews-item">
                                 <span>{translate('articles.details.helpful')}</span>
                                 <DisplayRating
                                      stars={[0,1,2,3,4]}
                                      currentRate={article.review && article.review.helpful}
                                  />
                             </div>
                         </div>
                         <div class="comments">
                             <div class="comments-body">

                                {this.props.reviews.map((item,index)=>{
                                      return <div class="comments-item">
                                      <div class="comments-info">
                                          <div class="comments-img">
                                              <Image src={item.user && item.user.avatar} alt="header" />
                                          </div>
                                          <div class="comments-info-wrap">
                                              <h1>{item.user && item.user.name}</h1>
                                              <div class="comments-info-bottom">
                                                  <div class="profile-rating">
                                                  <DisplayRating
                                                      stars={[0,1,2,3,4]}
                                                      currentRate={item.overall_rating}
                                                  />
                                                  <span>{item.overall_rating}</span>
                                                  </div>
                                                  <span>{item.created_at.slice(0,10)}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="comments-content">
                                          <p>{item.comment || "No comment available."}</p>
                                      </div>
                                  </div>
                                  })}

                                {this.props.currentReviewsPage < this.props.lastReviewsPage &&
                                    <a onClick={this.props.moreReviews}
                                    class="more-button button button-gray">Load more reviews</a>
                                }
                           </div>
                         </div>
                     </div>

                     <div class="col-md-12 col-lg-12 col-xl-4">
                         <div class="profile-aside">
                             <div class="profile-aside-title">
                                 <h3>{translate('articles.details.created_by')}</h3>
                             </div>
                             <div class="profile-create-item">
                                 <div class="profile-create-right">
                                     <div class="lawyers-img">
                                        <Image src={article.lawyer.avatar} alt="header" />
                                         <div class={article.lawyer.is_online ? "status active" : "status inactive"}></div>
                                     </div>
                                     <div class="profile-create-content">
                                         <h1>{article.lawyer.name}</h1>
                                         <p>{translate('articles.details.lawyer')}</p>
                                     </div>
                                 </div>
                                 <div class="profile-create-left">
                                     <a href="/#" onClick={this.onClickForChat.bind(this)} class="chat"><i class="icon-chat"></i></a>
                                 </div>
                             </div>
                             <div class="profile-aside-date">
                                 <h3>{translate('articles.details.published_on')}</h3>
                                 <p>{article.created_at.slice(0,10)}</p>
                             </div>
                             <div class="profile-aside-copy">
                                 <div class="copy-link">
                                     <input type="text"
                                     class="wal-form-control" value="wal.worl/as2049sc.12" readonly />
                                     <a href="#" class="copy"><i class="icon-copy"></i></a>
                                 </div>
                             </div>
                         </div>
                     </div>

                 </div>
             </div>
         </section>
     </main>
  )};
}

export default ArticleNotLogged
