import React
  from 'react';

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

import Background
  from '../../../assets/img/court-bg.jpg';

import DisplayRating
  from '../../../components/shared/DisplayRating'

import { bookmarkItem, deleteBookmarkItem }
  from '../../../helpers/bookmarkItem'

import { getToken }
  from "../../../helpers";

import { WEB_URL }
  from "../../../constants/types";

class HeaderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBookmarked: props.contract.is_bookmarked,
      is_downloadable: props.contract.is_downloadable
    };
  }

  triggerLawyerBookmark(e) {
    e.preventDefault()
    let { id } = this.props.contract
    let { isBookmarked } = this.state
    if(getToken()) {
      isBookmarked ? deleteBookmarkItem(id, "contract", this.catchResult.bind(this)) : bookmarkItem(id, "contract", this.catchResult.bind(this))
    } else {
      window.$('#login').modal('toggle')
    }
  }

  catchResult(data) {
    data == 0 ? this.setState({isBookmarked: false}) : this.setState({isBookmarked: true})
  }

  displayTags() {
    let { contract } = this.props
    return (
      <ul>
        { contract.hashtags.map((tag) => {
          return (
            <li><a href="#">{tag.tag}</a></li>
          )})
        }
      </ul>
    )
  }

  openPayment() {
    if(getToken()) {
      window.$('#payment-modal').modal('toggle');
    } else {
      window.$('#login').modal('toggle');
    }
  }

  requestToDownloadContract(e) {
    let { contract } = this.props
    e.preventDefault();
    window.open(`${WEB_URL}/contracts/${contract.id}/download?token=${getToken()}`);

    setTimeout(function(){
      window.open(`${WEB_URL}/contracts/${contract.id}/download-doc?token=${getToken()}`);
    }, 1000);

  }

  displayBuySection() {
    let { contract, translate } = this.props
    let { is_downloadable } = this.state
    return (
      <div className="profile-buttons">
        { is_downloadable ?
          <a onClick={this.requestToDownloadContract.bind(this)} class="button button-primary" download><i class="icon-download"></i>{translate('contracts.details.download_contract')}</a>
        :
          <a href="#" className="button button-primary" onClick={this.openPayment.bind(this)}><i className="icon-purchase"></i>{translate('contracts.details.buy_contract')}-</a>
        }
        {getToken() &&
          <div class="wal-icons">
            <div class="d ropdown-options contracts-options-dropdown">
              <div data-toggle="dropdown" data-display="static">
                <a href="#" class="wal-icons-button options"><i class="icon-options"></i></a>
              </div>
              <ul class="dropdown-menu">
                <div class="more-options">
                  <span>{translate('contracts.details.more_options')}</span>
                </div>
                { is_downloadable &&
                <>
                  <li style={{opacity: 0.5}}>
                    <a href="#" disabled class="report-lawyer"><i class="icon-cases"></i>{translate('contracts.details.create_case_invite_party')}</a>
                  </li>
                  <li>
                    <a href={`${WEB_URL}/contracts/${contract.id}/download?token=${getToken()}&mode=view`} disabled class="report-lawyer" target="_blank"><i class="icon-eye"></i>{translate('contracts.details.view_contract')}</a>
                  </li>
                </>
                }
                <li style={{opacity: 0.5}}>
                  <a href="#" class="report-lawyer"><i class="icon-email"></i>{translate('contracts.details.share_via_email')}</a>
                </li>
                <li style={{opacity: 0.5}}>
                  <a href="#" class="report-lawyer" data-toggle="modal" data-target="#report"><i class="icon-contracts-report"></i>{translate('contracts.details.report_contract')}</a>
                </li>
              </ul>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    let { contract, translate } = this.props
    let { isBookmarked } = this.state

    return(
      <section
        className="profile pd-rl"
        style={{backgroundImage:`url(${Background})`}}
      >
        <div className="container-fluid">
          <div className="profile-wrap">
            <div className="profile-top">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="profile-top-wrap">
                      <a href="#" onClick={()=> this.props.history.goBack()} className="back-button"><i className="icon-back"></i>{translate('contracts.details.back')}</a>
                      { <a href="#" onClick={this.triggerLawyerBookmark.bind(this)} className="bookmarks">
                        <i class={` ${isBookmarked ? 'icon-bookmarks-filled' : 'icon-bookmarks'}`}></i>
                      </a> }
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-bottom">
                <div className="row align-items-end">
                  <div className="col-md-12 col-lg-8">
                    <div className="profile-info">
                      <div className="profile-info-wrap">
                        <div className="profile-type">
                          <span className="type">{contract.categories.map(x => x.name).join(", ")}</span>
                        </div>
                        <div className="profile-name">
                          <h1>{contract.name}</h1>
                        </div>
                        <div className="profile-rating-state">
                          <div className="profile-rating">
                            <DisplayRating
                              stars={[0,1,2,3,4]}
                              currentRate={contract.rating}
                            />
                          </div>
                          {/*<div className="profile-type">
                            <span><i className="icon-world"></i> 14 Times used</span>
                          </div>*/}
                          <div className="profile-type">
                            <span><i className="icon-world"></i> {contract.bought_count} {translate('contracts.details.times_bought')}</span>
                          </div>
                        </div>
                        <div className="profile-tags">
                          {this.displayTags()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    {this.displayBuySection()}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeaderSection
