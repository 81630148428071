import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput }
  from '../../components/shared/forms';

import { subscriptionPlans, mySubscription, cancelSubscription }
  from '../../helpers/subscription';

import { Modal, AddCard, Details, Success }
  from '../../components/shared/modals/payment';

import { ApproveModal }
  from './subscription/index';

import { SuccessAlert }
  from '../../components/settings/shared';

import ReactLoading
  from 'react-loading';

import moment
  from 'moment';

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planLoading: true,
      error: null,
      errors: null,
      priceType: "monthly",
      mySubscription: [],
      plans: [],
      planSelected: {},
      subscribeName: "Free",
      subscribeId: null,
      monthly_price_calc: "10.00",
      annually_price_calc: "120.00",
      successUpdated: false,
      cancelError: ""
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    await mySubscription(this.fetchMySubscription.bind(this))
    await subscriptionPlans(this.fetchPlans.bind(this))
  }

  fetchMySubscription(data) {
    if(data.data.length > 0) {
      console.log(data.data)
      this.setState({
        mySubscription: data.data,
        subscribeName: data.data[data.data.length -1].subscription_items[0].plan,
        subscribeId: data.data[data.data.length -1].subscription_items[0].id,
      }, () => this.checkSelectedPlan())
    }
  }

  fetchPlans(data) {
    let { freePlan } = this.state
    this.setState({plans: data.data, planLoading: false}, () => this.checkSelectedPlan())
  }

  selectPlan(plan) {
    let { priceType } = this.state
    this.setState({planSelected: { id: plan.id, name: plan.name, price: plan.[`${priceType}_price_calc`]}})
    window.$('#payment-modal').modal('toggle');
  }

  displayUpcomingBills() {
    let { subscribeName, planSelected, priceType, successUpdated } = this.state
    const { currentUser, translate } = this.props

    if(planSelected.ends_at) {
      return (
        <div className="accordion-body-wrap">
          <div className="settings-subscription-bills">
            <div className="settings-subscription-bills-content">
              <h2>{subscribeName} plan</h2>
              <div className="settings-subscription-bills-info">
                <span>{translate("settings.subscription.subscription_plan")}</span>
                <span>{planSelected.ends_at.split(" ")[0]} / {planSelected.ends_at.split(" ")[1]}</span>
              </div>
            </div>
            <div className="settings-subscription-bills-price">
              <span>$ {this.state.[`${priceType}_price_calc`]}-</span>
            </div>
          </div>
          <div className="settings-subscription-info">
            <p>{translate("settings.subscription.bills_description")}</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="accordion-body-wrap">
          <div className="settings-subscription-info">
            <p>{translate("settings.subscription.not_have_bills")}</p>
          </div>
        </div>
      )
    }
  }

  displayCancelButton() {
    const { translate } = this.props
    const { planSelected } = this.state

    if(planSelected) {
      return (
        <div className="settings-subscription-cancel-content" style={{marginBottom: 40}}>
          <p>{translate("settings.subscription.cancel_description")}</p>
          <span>{translate("settings.subscription.cancel_info")}</span>
          <button className="button button-danger" data-toggle="modal" data-target="#cancel-subscription">{translate("settings.subscription.cancel_subscription_button")}</button>
        </div>
      )
    } else {
      return (
        <div className="settings-subscription-cancel-content">
          <p>{translate("settings.subscription.not_have_sybscription")}</p>
        </div>
      )
    }
  }

  requestToCancelSubscription(e) {
    e.preventDefault()
    let { subscribeName, planSelected, priceType } = this.state
    cancelSubscription(planSelected.id, this.successCancel.bind(this), this.errorCancel.bind(this))
  }

  async errorCancel(err) {
    this.setState({cancelError: err.data.message})
  }

  async successCancel() {
    let _this = this
    window.$('#cancel-subscription').modal('toggle');
    this.setState({successUpdated: true})

    setTimeout(function(){
      _this.fetchData()
      _this.setState({successUpdated: false})
    }, 1000);
  }

  checkSelectedPlan() {
    let { plans, priceType, mySubscription } = this.state
    var subscribeName = "Free"

    let checkSub = mySubscription.filter((sub) => sub.canceled_at == null)
    if(checkSub.length > 0) {
      this.setState({planSelected: checkSub[checkSub.length - 1], subscribeName: checkSub[checkSub.length - 1].subscription_items.[0].plan})
    } else {
      this.setState({planSelected: {}, subscribeName: "Free"})
    }
  }

  displaySelectPlanButton(plan) {
    let { priceType } = this.state
    if(plan.name != "Free") {
      return (
        <button className="button button-bl" onClick={() => this.selectPlan(plan)}>Select this plan</button>
      )
    }
  }

  displaySubscriptionPlans() {
    let { plans, priceType, mySubscription, subscribeName } = this.state

    if(this.state.planLoading){
      return(
        <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      plans.map((plan) => {
        return (
          <div className="subscription-plan-item">
            <div className="subscription-plan-head">
              <h1>{plan.name}</h1>
            </div>
            <div className="subscription-plan-body">
              <ul>
                <li>{plan.description}</li>
              </ul>
            </div>
            { subscribeName.toLowerCase() == plan.name.toLowerCase().replace(/\s/g, '-') ?
              <>
                <div className="subscription-plan-footer">
                  <span className="price">$ {plan.[`${priceType}_price_calc`]}</span>
                  <button className="button button-white">This plan is active</button>
                </div>
                <div className="info">
                  <i className="icon-done-check"></i>
                  <span>Currently Active</span>
                </div>
              </>
              :
              <div className="subscription-plan-footer">
                <span className="price">$ {plan.[`${priceType}_price_calc`]}</span>
                {this.displaySelectPlanButton(plan)}
              </div>
            }
          </div>
        )
      })
    )
  }

  changeInterval(e, interval) {
    e.preventDefault()
    let { location } = this.props
    this.setState({priceType: interval}, () => this.checkSelectedPlan())
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, type, priceType, successUpdated, planSelected } = this.state
    let { gender, nationality_id, nationality } = currentUser
    let planSelectedObject = Object.keys(planSelected)

    return (
      <div className="col-md-12 col-lg-12 col-xl-8">
        <div className="settings-content">
          <div className="settings-subscription">
            <div class="settings-subscription-status-info">
              <div class="settings-subscription-status-info-wrap">
                <h2>{translate("settings.subscription.your_subscription")}</h2>
                <div class="settings-subscription-status-item">
                  <h3>{translate("settings.subscription.subscription_status")}</h3>
                  <span class="plan">{planSelectedObject.length === 0 ? translate("settings.subscription.free") : translate("settings.subscription.premium") }</span>
                </div>
                <div class="settings-subscription-status-item">
                  <h3>{translate("settings.subscription.active_since")}:</h3>
                  <span class="date">{planSelectedObject.length != 0 && moment(planSelected.ends_at).format("DD.MM.YYYY / LT")}</span>
                </div>
              </div>
            </div>
            <div className="accordion settings-subscription-accordion" id="subscription-accordion">
              <div className="accordion-item">
                <div className="accordion-head">
                  <div className="accordion-head-wrap" data-toggle="collapse" data-target="#plan" aria-expanded="true">
                    <h2>{translate("settings.subscription.choose_your_plan")}</h2>
                    <i className="icon-next"></i>
                  </div>
                </div>
                <div className="accordion-body collapse show" id="plan" data-parent="#subscription-accordion">
                  <div className="accordion-body-wrap">
                    <div className="choose-plan">
                      <a href="#" onClick={(e) => this.changeInterval(e, "monthly")} className={`${priceType == "monthly" ? "active" : ""}`}>{translate("settings.subscription.monthly")}</a>
                      {/*<a href="#" onClick={(e) => this.changeInterval(e, "annually")} className={`${priceType == "annually" ? "active" : ""}`}>Annually</a>*/}
                    </div>
                    <div className="subscription-plan-wrap">
                      {this.displaySubscriptionPlans()}
                    </div>
                    <div className="settings-subscription-info">
                      <p>{translate("settings.subscription.subscription_info")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-head">
                  <div className="accordion-head-wrap collapsed" data-toggle="collapse" data-target="#bills" aria-expanded="false">
                    <h2>{translate("settings.subscription.upcomming_bills")}</h2>
                    <i className="icon-next"></i>
                  </div>
                </div>
                <div className="accordion-body collapse" id="bills" data-parent="#subscription-accordion">
                  {this.displayUpcomingBills()}
                </div>
              </div>
            </div>
            <div className="settings-subscription-cancel">
              <div className="settings-subscription-cancel-wrap">
                <div className="settings-subscription-cancel-title">
                  <h2>{translate("settings.subscription.cancelation")}</h2>
                </div>
              </div>
              {this.displayCancelButton()}
            </div>
          </div>
        </div>
        <Modal
          subscription
          plan={planSelected}
          interval={priceType}
        />
        <ApproveModal
          cancelError={this.state.cancelError}
          requestToCancelSubscription={this.requestToCancelSubscription.bind(this)}
        />
        { successUpdated &&
          <SuccessAlert
            message={translate("settings.subscription.canceled_successfully")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(Subscription);
