import React
  from 'react'
import Slider
  from 'react-rangeslider'
import { RatingInput, RangeSlider }
  from './index';
import ReactLoading
  from 'react-loading';
import { SelectItem, MultiCategoriesSelect, MultiJurisdictionsSelect }
  from '../forms';

class ContentForm extends React.Component {
  displaySelectedInput() {
    var { translate } = this.props
    return (
      <>
        { this.props.selectedFields.map((field) => {
          Object.assign(field, {"selectText": translate("lawyers.select")});
          return (
            <SelectItem
              field={field}
            />
          )
        })}
      </>
    )
  }

  render() {
    let { translate, rateObject, rangeObject, multiSelect, jurisdictionsMultiSelect } = this.props
    return (
      <div className="col-md-12 col-lg-12 col-xl-4">
        <div className="wal-filter">
          <div className="wal-filter-head">
            <h1>{translate('lawyers.filters')}</h1>
            { this.props.filtersApplied &&
              <button
                onClick={()=> this.props.resetFilterState()}
                className="wal-filter-clear">
                  <i className="icon-error-circle"></i>
                  <span>{translate('lawyers.clear_filters')}</span>
              </button>
            }
          </div>
          <div className="wal-filter-body">
            { multiSelect &&
              <MultiCategoriesSelect
                multiObject={multiSelect}
                translate={translate}
              />
            }
            { jurisdictionsMultiSelect &&
              <MultiJurisdictionsSelect
                multiObject={jurisdictionsMultiSelect}
                translate={translate}
              />
            }
            {this.displaySelectedInput()}
            { rateObject &&
              <RatingInput
                translate={translate}
                rateObject={rateObject}
              />
            }
            { rangeObject &&
              <RangeSlider
                translate={translate}
                rangeObject={rangeObject}
              />
            }
          </div>
          <div className="wal-filter-footer">
            <button
              onClick={()=> this.props.pressApplyFilters()}
              className="button button-bl">{translate('lawyers.apply_filters')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ContentForm
