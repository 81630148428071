import React
  from 'react'
import Slider
  from 'react-rangeslider'

class RatingInput extends React.Component {
  render() {
    let { translate, rateObject } = this.props
    let { changeRating, rating } = rateObject
    console.log(rating)
    return (
      <div className="wal-form wal-form-group">
          <label for="rating">{translate('lawyers.rating')}</label>
          <div className="rated-lawyers-bottom">
              <div className="rated-stars">
                {[1,2,3,4,5].map((item, index)=>{
                   return <i
                    onClick={()=> changeRating(index+1)}
                    className={index+1 > rating
                        ?  "icon-star"
                        : "icon-star-filled"}></i>
                })}
              </div>
              <span>{!rating || rating == 0 ? 0 : rating} {translate('lawyers.stars')}</span>
          </div>
      </div>
    )
  }
}

export default RatingInput
