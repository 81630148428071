import api from "../api";

import { CURRENT_USER, CURRENT_USER_BOOKMARKS_LAWYERS,
  CURRENT_USER_BOOKMARKS_ARTICLES,
  CURRENT_USER_BOOKMARKS_CONTRACTS,
  CURRENT_USER_CONTRACTS, WEB_URL,
  EMPTY_STATE } from "../constants/types";

import { setToken, removeToken }
  from "../helpers";

import qs
  from 'qs';

export function fetchUserContractsBookmarks(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-contracts?per_page=5&page=${currentPage}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_CONTRACTS,
          payload: data.data.data,
        });
        addLastPage(data.data.last_page)
      })
      .catch((err) => {});
  };
}

export function fetchUserArticlesBookmarks(stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-articles`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_ARTICLES,
          payload: data.data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function fetchUserLawyersBookmarks(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/bookmarks-lawyers?per_page=5&page=${currentPage}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: CURRENT_USER_BOOKMARKS_LAWYERS,
          payload: data.data.data,
        });
        addLastPage(data.data.last_page)
      })
      .catch((err) => {});
  };
}

export function fetchUserContracts(addLastPage, currentPage) {
  return async function (dispatch) {
    let url = `${WEB_URL}/contracts-purchased?per_page=5&page=${currentPage}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: CURRENT_USER_CONTRACTS,
          payload: data.data.data,
        });
        addLastPage(data.data.last_page)
      })
      .catch((err) => {});
  };
}

export function updateProfile(updateParams, avatar, stopLoading, handleErrors) {
  return async function(dispatch) {
    let url = `${WEB_URL}/profile`

    var form = new FormData();

    if(avatar) {
      await form.append("avatar", avatar);
    }

    Object.keys(updateParams).map( async (item) => {
      await form.append(item, updateParams[item])
    })

    const config = {
      headers: {
        "enctype": "multipart/form-data",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      mimeType: "multipart/form-data",
      contentType: false
    };

    api.post(url, form, config)
    .then((data) => {
      stopLoading()
    })
    .catch(err => {
      handleErrors(err.data)
    })
  }
}

export function updatePassword(passwordParams, stopLoading, handleErrors) {
  return async function(dispatch) {
    let url = `${WEB_URL}/change_password`

    api.post(url, qs.stringify(passwordParams))
    .then((data) => {
      stopLoading()
    })
    .catch(err => {
      handleErrors(err.data)
    })
  }
}

export function emptyRedux(){
  return async function (dispatch) {
      dispatch({
        type: EMPTY_STATE,
        payload: "",
      });
  }
}
