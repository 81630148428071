import api from "../api";

import {
    ALL_CONTRACTS,
    CONTRACT,
    CONTRACTS_PER_PAGE,
    CONTRACTS_WITH_FILTERS,
    PURCHASED_CONTRACTS,
    PURCHASED_CONTRACTS_LOADING,
    TOP_RATED_CONTRACTS,
    WEB_URL
} from "../constants/types";

import { getToken }
  from "../helpers";

export function fetchContracts(stopLoading) {
    return async function (dispatch) {
        let url = `${WEB_URL}/all-contracts?`;
        api
            .get(url)
            .then((data) => {
                dispatch({
                    type: ALL_CONTRACTS,
                    payload: data.data,
                });
                stopLoading()
            })
            .catch((err) => {
            });
    };
}

export function fetchContract(contractId, stopLoading = null) {
    return async function (dispatch) {
        let url = `${WEB_URL}/contracts/${contractId}`;
        api.get(url)
            .then((data) => {
                dispatch({
                    type: CONTRACT,
                    payload: data.data,
                });
                stopLoading()
            })
            .catch((err) => {
                console.log("error", err)
            });
    };
}

export function fetchTopRatedContracts(stopLoading) {
    return async function (dispatch) {
        let url = `${WEB_URL}/all-contracts?top_rated=1&per_page=6`;
        api.get(url)
            .then((data) => {
                dispatch({
                    type: TOP_RATED_CONTRACTS,
                    payload: data.data,
                });
                stopLoading()
            })
            .catch((err) => {
            });
    };
}

export function fetchContractsPerPage(perPage, stopLoading) {
    return async function (dispatch) {
        let url = `${WEB_URL}/all-contracts?per_page=${perPage}`;
        api.get(url)
            .then((data) => {
                dispatch({
                    type: CONTRACTS_PER_PAGE,
                    payload: data.data,
                });
                stopLoading()
            })
            .catch((err) => {
            });
    };
}

export function fetchContractsByFilter(params = null, stopLoading) {
    return async function (dispatch) {
        let url = `${WEB_URL}/all-contracts?`;

        if (params) {
            if (params.page && params.page != "" && params.page !== null) {
                url = url + `page=${params.page}&`
            }
            if (params.type && params.type != "" && params.type !== null) {
                url = url + `type=${params.type}&`
            }
            if (params.category && params.category != "" && params.category !== null) {
                url = url + `category=${params.category}&`
            }
            if (params.language && params.language != "" && params.language !== null) {
                url = url + `language=${params.language}&`
            }
            if (params.rating && params.rating != "" && params.rating !== null) {
                url = url + `rating=${params.rating}&`
            }
            if (params.sort && params.sort != "" && params.sort !== null) {
                url = url + `sort=${params.sort}&`
            }
            if (params.name && params.name != "") {
                url = url + `name=${params.name}&`
            }
            if (params.categories && params.categories.length > 0) {
                var categoryLink = ""
                params.categories.map((cat, index) => {
                    categoryLink = categoryLink + `categories[${index}]=${cat}&`
                })
                url = url + categoryLink
            }
            if (params.jurisdictions && params.jurisdictions.length > 0) {
                var jurisdictions = ""
                params.jurisdictions.map((cat, index) => {
                    jurisdictions = jurisdictions + `jurisdictions[${index}]=${cat}&`
                })
                url = url + jurisdictions
            }
        }

        api.get(url)
            .then((data) => {
                dispatch({
                    type: CONTRACTS_WITH_FILTERS,
                    payload: data.data,
                });
                stopLoading(url)
            })
            .catch((err) => {
            });
    };
}

export const purchasedContracts = (data = {}) => (dispatch) => {
    dispatch({type: PURCHASED_CONTRACTS_LOADING, payload: true});

    data.per_page = 100;

    api.get('contracts-purchased', {
        params: data
    }).then(result => {
        dispatch({type: PURCHASED_CONTRACTS, payload: result.data.data});
    }).catch(error => {
        console.log(error)
    }).finally(dispatch({type: PURCHASED_CONTRACTS_LOADING, payload: false}))
}
