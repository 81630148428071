import React
    from 'react'

class MultiJurisdictionsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectJurisdiction: [],
      nonResult: true
    };
  }

  changeSearch(e) {
    this.setState({search: e.target.value})
  }

  componentDidMount() {
    let { currentSelected, items } = this.props.multiObject
    let currentSelectedItems = []

    if (currentSelected.length > 0) {
      currentSelected.map((itemId) => {
        currentSelectedItems.push(items.filter(function(value) {return value.id == itemId })[0])
      })
      this.setState({selectJurisdiction: currentSelectedItems})
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.multiObject.currentSelected.length < 1) {
      this.setState({selectJurisdiction: []})
    }
  }

  selectItem(e, item) {
    e.stopPropagation();
    let { selectJurisdiction } = this.state
    let { multiObject } = this.props
    var currentSelected = []

    if(selectJurisdiction.map(function(i) { return i.id; }).indexOf(item.id) !== -1){
      currentSelected = selectJurisdiction.filter(function(value) {return value.id !== item.id})
      this.setState({selectJurisdiction: currentSelected})
    } else {
      // debugger
      currentSelected = [...selectJurisdiction, item]
      this.setState({selectJurisdiction: currentSelected})
    }

    multiObject.onChange(currentSelected)
  }

  selectMainJurisdictionItem(e, item) {
    e.stopPropagation();
    let { selectJurisdiction } = this.state
    let { multiObject } = this.props
    var currentSelected = []
    let { items } = this.props.multiObject
    let subJurisdictions = items.filter((element) => element.parent_id == item.id)

    if(selectJurisdiction.map(function(i) { return i.id; }).indexOf(item.id) !== -1){
      currentSelected = selectJurisdiction.filter(function(value) {return value.id !== item.id})
      this.setState({selectJurisdiction: currentSelected})
    } else {
      let currentSubSelected = subJurisdictions.filter(function(value) {return value.parent_id == item.id})
      currentSelected = [...selectJurisdiction, item]

      this.setState({selectJurisdiction: currentSelected})
    }

    multiObject.onChange(currentSelected)
  }

  displayCategories() {
    let { items } = this.props.multiObject
    let { search, selectJurisdiction } = this.state

    let parentJurisdiction = items.filter((element) => element.parent_id == null)

    return (
      parentJurisdiction.map((jurisdictionItem, index) => {
        if(jurisdictionItem) {
          let subJurisdictions = items.filter((element) => element.parent_id == jurisdictionItem.id)
          subJurisdictions = search != ""
                                    ? subJurisdictions.filter((element) => element.name.toLowerCase().includes(search.toLowerCase()))
                                    : subJurisdictions
          let selectMainItem = selectJurisdiction.map(function(i) { return i.id; }).indexOf(jurisdictionItem.id) !== -1
          return (
            <div class="wal-form-dropdown-item">
              <div class={`wal-form-checkbox wal-form-checkbox-main ${jurisdictionItem.id != 1 && 'has-items'}`}>
                <input type="checkbox"
                  checked={selectMainItem}
                  id={`${jurisdictionItem.id}`}
                  value={`${jurisdictionItem.id}`}
                  class="checkbox"
                />
                <label
                  for="europe"
                  onClick={(e) => this.selectMainJurisdictionItem(e, jurisdictionItem)}>
                  {jurisdictionItem.name}
                </label>
              </div>
              { subJurisdictions.map((subJurisdiction) => {
                let selectCurrentItem = selectJurisdiction.map(function(i) { return i.id; }).indexOf(subJurisdiction.id) !== -1
                return (
                  <div class="wal-form-checkbox" >
                    <input
                      type="checkbox"
                      checked={selectCurrentItem}
                      id={`${subJurisdiction.id}`}
                      value={`${subJurisdiction.id}`}
                      className="checkbox" />
                    <label
                      for={`${subJurisdiction.id}`} id={`${subJurisdiction.id}`}
                      onClick={(e) => this.selectItem(e, subJurisdiction)}>
                      {subJurisdiction.name}
                    </label>
                  </div>
                )
              })}
            </div>
          )
        }
      })
    )
  }

  displaySelectedItem() {
    let { selectJurisdiction } = this.state
    let title = ""
    let { translate } = this.props

    if(selectJurisdiction.length < 1) {
      return (
        translate('lawyers.select_jurisdiction')
      )
    } else {
      selectJurisdiction.forEach((category, index) => {
        title = title + category.name

        if(selectJurisdiction[selectJurisdiction.length - 1] !== selectJurisdiction[index]) {
          title = title + ", "
        }
      })

      return (
        this.truncate(title)
      )
    }
  }

  truncate(str) {
    return str.length > 60 ? str.substring(0, 55) + "..." : str;
  }

  render() {
    let { translate } = this.props
    return (
      <div className="wal-form wal-form-group">
        <label for="select">{translate("lawyers.jurisdiction")}</label>
        <div className="wal-form-dropdown dropdown">
          <button className="wal-form-control dropdown-toggle" type="button" data-toggle="dropdown" data-display="static">{this.displaySelectedItem()}</button>
          <div className="wal-form-dropdown-wrap dropdown-menu">
            <div className="wal-form-dropdown-search">
              <input
                type={translate("lawyers.search")}
                name="Search Jurisdictions"
                placeholder={translate("lawyers.search_jurisdiction")}
                onChange={this.changeSearch.bind(this)}
                value={this.state.search}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none" />
            </div>
            <div className="wal-form-dropdown-list">
              { this.state.search != "" && !this.state.nonResult ?
                <p style={{marginLeft: "20%", marginTop: 20}}>Not found eny category</p>
                :
                this.displayCategories()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiJurisdictionsSelect
