import api from "../api";

import { WEB_URL, SEARCH_RESULT } from "../constants/types";

export function searchOnHome(query, stopLoading) {
  return async function (dispatch) {
    let url = `${WEB_URL}/search?q=${query}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: SEARCH_RESULT,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {
      });
  };
}
