import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ConversationSearchInput extends Component {
    state = {
        search: ""
    }

    onKeyPress = (event) => {
        if (event.key.toLowerCase() == 'enter') {
            this.props.getConversations({
                name: this.state.search
            })
        }
    }

    onChange = (event) => {
        const {name, value} = event.target;

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <div className="messages-sidebar-search">
                <div className="messages-search">
                    <input
                        className="search-input"
                        type="text"
                        name="search"
                        placeholder="Search for user"
                        value={this.state.search}
                        onChange={this.onChange}
                        onKeyPress={this.onKeyPress}
                    />
                    <i className="icon-search"></i>
                </div>
            </div>
        );
    }
}
export default ConversationSearchInput;