import React
    from 'react';
import { Link }
  from "react-router-dom";
  import Image
    from "../../../../components/shared/Image";
import ReactLoading
  from 'react-loading';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        {label: props.translate("search.all"), value: "all"},
        {label: props.translate("search.lawyers"), value: "lawyers"},
        {label: props.translate("search.contracts_templates"), value: "contracts"},
        {label: props.translate("search.articles"), value: "articles"}
      ]
    };
  }

  displayTabs() {
    let { activeTab, changeActiveTab } = this.props
    return (
      this.state.tabs.map((tab) => {
        return (
          <li key={tab.label} class={activeTab == tab.value && 'active'}>
            <a onClick={()=> changeActiveTab(tab.value)}>{tab.label}</a>
          </li>
        )
      })
    )
  }

  render() {
    let { title, cardTitle, items, link } = this.props
    return (
      <div class="search-menu">
        <ul>
          {this.displayTabs()}
        </ul>
      </div>
    )
  }
}

export default Tabs;
