import api from "../api";

import { WEB_URL, COUNTRY_ITEMS, INTEREST_ITEMS } from "../constants/types";

export function fetchCoutries() {
  return async function (dispatch) {
    let url = `${WEB_URL}/countries`;
    api.get(url)
      .then((data) => {
        let countries = []

        data.data.data.map((opt) => {
          countries.push({ value: opt.id, label: opt.name })
        })

        dispatch({
          type: COUNTRY_ITEMS,
          payload: countries,
        });
      })
      .catch((err) => {});
  };
}

export function fetchInterests() {
  return async function (dispatch) {
    let url = `${WEB_URL}/interests`;
    api.get(url)
      .then((data) => {
        let interests = []

        data.data.data.map((opt) => {
          interests.push({ value: opt.id, label: opt.name })
        })

        dispatch({
          type: INTEREST_ITEMS,
          payload: interests,
        });
      })
      .catch((err) => {});
  };
}
