import React, {Component} from 'react';

import DefaultAvatar from '../../../assets/img/users.svg'
import {imageUrl} from "../../../helpers";

import Typing from "../box/Typing";

class ParticipantInfo extends Component {

    onDeleteConversation = (event) => {
        this.props.openConversationDeleteModal({
            conversation_id: this.props.conversation.id
        })
    }

    render() {

        const participant = this.props.conversation.participants[0]

        return (
            <div className="messages-box-header">
                <div className="messages-box-header-wrap">
                    <div className="messages-box-user">
                        <div className="avatar">
                            {
                                participant.user.avatar
                                    ? <img src={imageUrl(participant.user.avatar)} alt={participant.user.name}/>
                                    : <img src={DefaultAvatar} alt="person"/>
                            }
                        </div>
                        <div className="info">
                            <h5>{participant.user?.title?.title} {participant.user.name}</h5>
                            {
                                this.props.userTyping
                                    ? <Typing/>
                                    :
                                    <span className="status">{participant.user.is_online ? "Online" : "Offline"}</span>
                            }
                        </div>
                    </div>
                    <div className="messages-box-action">
                        <div className="messages-search-icon">
                            <i className="icon-search"></i>
                        </div>
                        <div className="messages-options more-options">
                            <div
                                className="more-options-icon"
                                data-toggle="dropdown"
                                data-display="static"
                                aria-expanded="true"
                            >
                                <a href="#">
                                    <i className="icon-options"></i>
                                </a>
                            </div>
                            <ul className="dropdown-menu more-options-menu">
                                <li>
                                    <a href="#">
                                        <i className="icon-notification-off"></i>Mute chat
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="icon-report-lawyer"></i>Report user
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="delete" onClick={this.onDeleteConversation}>
                                        <i className="icon-delete"></i>Delete chat
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="icon-error-circle"></i>Block user
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ParticipantInfo;