import {
    WEB_URL,
    CURRENT_USER,
    LOG_OUT
} from '../constants/types';

import api
  from '../api';

import qs
  from 'qs';

import {setToken, removeToken, setUser}
  from "../helpers";

export function login(email, password, stopLoading, handleErrors) {
  return async function(dispatch) {
    let data = {
      "email": email,
      "password": password
    }

    let url = `${WEB_URL}/login`
    api.post(url, qs.stringify(data))
    .then((data) => {
      setToken(data.data.data.token)
      setUser(data.data.data.user)
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data.user
      });
      stopLoading()
      window.location.reload();
    })
    .catch(err => {
      stopLoading()
      handleErrors(err.data)
    })
  }
}

export function signUp(registerParams, stopLoading, handleErrors) {
  return async function(dispatch) {
    let url = `${WEB_URL}/user/register`
    api.post(url, registerParams)
    .then((data) => {
      debugger
      setToken(data.data.data.token)
      setUser(data.data.data.user)
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data.user
      });
      stopLoading()
      window.location.reload();
    })
    .catch(err => {
      debugger
      stopLoading()
      handleErrors(err.data)
    })
  }
}

export function facebookLogin(fbToken, stopLoading) {
  return async function(dispatch) {
    let data = {
      "access_token": fbToken,
    }

    let url = `${WEB_URL}/login/facebook`
    api.post(url, qs.stringify(data))
    .then((data) => {
      setToken(data.data.data.token)
      setUser(data.data.data.user)
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data.user
      });
      stopLoading()
      window.location.reload();
    })
    .catch(err => {
      stopLoading()
    })
  }
}

export function googleLogin(googleToken, stopLoading) {
  return async function(dispatch) {
    let data = {
      "access_token": googleToken,
    }

    let url = `${WEB_URL}/login/google`
    api.post(url, qs.stringify(data))
    .then((data) => {
      setToken(data.data.data.token)
      setUser(data.data.data.user)
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data.user
      });
      stopLoading()
      window.location.reload();
    })
    .catch(err => {
      stopLoading()
    })
  }
}

export function requestResetPassword(email, stopLoading, handleSuccess, handleErrors) {
  return async function(dispatch) {
    let data = {
      "email": email,
    }

    let url = `${WEB_URL}/password/email`
    api.post(url, qs.stringify(data))
    .then((data) => {
      stopLoading()
      handleSuccess(data)
    })
    .catch(err => {
      stopLoading()
      handleErrors(err.data)
    })
  }
}

export function loadProfile() {
  return async function(dispatch) {
    let url = `${WEB_URL}/profile`
    api.get(url)
    .then((data) => {
      dispatch({
        type: CURRENT_USER,
        payload: data.data.data
      });
    })
  }
}

export function logout() {
  return async function(dispatch) {
    removeToken()
    dispatch({
      type: LOG_OUT
    });
    setTimeout(() => {
      window.location.href = '/'
    }, 500)
  }
}
