import React from "react";
import moment
  from 'moment';

const NotFoundItems = (props) => {
  return (
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5">
        <div class="wal-result-none-wrap messages-empty">
          <div class="wal-result-none-icon">
            <i class={`icon-${props.icon}`}></i>
          </div>
          <h1>{props.title}</h1>
          <p>{props.description}</p>
        </div>
      </div>
    </div>
  )
}

export default NotFoundItems;
