import React
    from 'react'

const CATEGORY_ICONS = ["icon-type-people-families", "icon-type-order-build", "icon-type-damage", "icon-type-contracts", "icon-type-pay",
                        "icon-type-pay", "icon-type-worker", "icon-type-transport", "icon-type-sport", "icon-type-tech", "icon-type-tech",
                        "icon-type-trade", "icon-type-criminal", "icon-type-international", "icon-type-state-administrative", "icon-type-taxes",
                        "icon-match-deal", ]

class MultiCategoriesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedCategory: [],
      nonResult: true
    };
  }

  changeSearch(e) {
    this.setState({search: e.target.value})
  }

  componentDidMount() {
    let { currentSelected, items } = this.props.multiObject
    let currentSelectedItems = []
    if (currentSelected.length > 0) {
      currentSelected.map((itemId) => {
        currentSelectedItems.push(items.filter(function(value) {return value.id == itemId })[0])
      })
      this.setState({selectedCategory: currentSelectedItems})
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.multiObject.currentSelected.length < 1) {
      this.setState({selectedCategory: []})
    }
  }

  selectItem(e, item) {
    e.stopPropagation();
    let { selectedCategory } = this.state
    let { multiObject } = this.props
    var currentSelected = []

    if(selectedCategory.map(function(i) { return i.id; }).indexOf(item.id) !== -1){
      currentSelected = selectedCategory.filter(function(value) {return value.id !== item.id})
      this.setState({selectedCategory: currentSelected})
    } else {
      currentSelected = [...selectedCategory, item]
      this.setState({selectedCategory: currentSelected})
    }

    multiObject.onChange(currentSelected)
  }

  displayCategories() {
    let { items } = this.props.multiObject
    let { search, selectedCategory } = this.state
    return (
      items.map((item, index) => {
        let sub_categries = search != ""
                                   ? item.sub_categories_relation.filter((element) => element.name.toLowerCase().includes(search.toLowerCase()))
                                   : item.sub_categories_relation


        if(sub_categries.length) {
          return (
            <div className="wal-form-dropdown-item">
              <span href={`#${item.id}`} onClick={(e) => e.stopPropagation()}>
                <i className={CATEGORY_ICONS[index]}></i>{item.name}
              </span>
              <div id={`${item.id}`}>
                { sub_categries.map((sub_category) => {
                  let selectItem = selectedCategory.map(function(i) { return i.id; }).indexOf(sub_category.id) !== -1
                  let subInputId = sub_category.name.replace(/\s/g, '-').toLowerCase()
                  return (
                    <div className="wal-form-checkbox" onClick={(e) => this.selectItem(e, sub_category)}>
                      <input
                        type="checkbox"
                        checked={selectItem}
                        id={`${subInputId}`}
                        value={`${subInputId}`}
                        className="checkbox" />
                      <label id={`${subInputId}`}>{sub_category.name}</label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        }
      })
    )
  }

  displaySelectedItem() {
    let { selectedCategory } = this.state
    let title = ""
    let { translate } = this.props

    if(selectedCategory.length < 1) {
      return (
        translate('lawyers.select_categories')
      )
    } else {
      selectedCategory.forEach((category, index) => {
        title = title + category.name

        if(selectedCategory[selectedCategory.length - 1] !== selectedCategory[index]) {
          title = title + ", "
        }
      })

      return (
        this.truncate(title)
      )
    }
  }

  truncate(str) {

    return str.length > 60 ? str.substring(0, 55) + "..." : str;
  }

  render() {
    let { translate } = this.props

    return (
      <div className="wal-form wal-form-group">
        <label for="select">{translate("lawyers.field_of_law")}</label>
        <div className="wal-form-dropdown dropdown">
          <button className="wal-form-control dropdown-toggle" type="button" data-toggle="dropdown" data-display="static">{this.displaySelectedItem()}</button>
          <div className="wal-form-dropdown-wrap dropdown-menu">
            <div className="wal-form-dropdown-search">
              <input
                type={translate("lawyers.search")}
                name="Search Categories"
                placeholder={translate("lawyers.search_categories")}
                onChange={this.changeSearch.bind(this)}
                value={this.state.search}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none" />
            </div>
            <div className="wal-form-dropdown-list">
              { this.state.search != "" && !this.state.nonResult ?
                <p style={{marginLeft: "20%", marginTop: 20}}>Not found eny category</p>
                :
                this.displayCategories()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiCategoriesSelect
