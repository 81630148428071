import {
    CONTRACT
  } from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case CONTRACT:
      return action.payload
    default:
      return state
  }
}
