import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function deleteAvatar(catchResult) {
  let url = `${WEB_URL}/remove_avatar`;

  api.delete(url)
    .then((data) => {
      catchResult(data.data)
    })
    .catch((err) => {
    });
}
