import React from 'react'
import * as types from "../constants/chat";

const initialState = {
    conversations: [],
    messages: [],
    hasMoreMessages: false,
    messagesPage: 1,
    conversation: null,

    conversationLoading: false,
    messagesLoading: false,

    noConversations: false,

    userTyping: false,

    // modals
    messageDeleteModal: false,
    conversationDeleteModal: false,

    deleteData: null,

    contractsModal: false,

    reportModal: false,
    reportData: null,
    reportReasons: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_CONVERSATIONS:
            return {
                ...state,
                conversations: action.payload,
                noConversations: action.payload.length == 0
            }
        case types.MORE_MESSAGES:
            return {
                ...state,
                messages: action.payload.concat(state.messages)
            }
        case types.GET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            }
        case types.ADD_MESSAGE:
            return {
                ...state,
                messages: state.messages.concat([action.payload])
            }
        case types.HAS_MORE_MESSAGES:
            return {
                ...state,
                ...action.payload
            }
        case types.CONVERSATION_LOADING:
            return {
                ...state,
                conversationLoading: action.payload
            }
        case types.MESSAGES_LOADING:
            return {
                ...state,
                messagesLoading: action.payload
            }
        case types.SELECT_CONVERSATION:
            return {
                ...state,
                conversation: action.payload
            }
        case types.WS_ADD_MESSAGE:
            return {
                ...state,
                messages: state.messages.concat([action.payload.message])
            }
        case types.WS_TYPING:
            return {
                ...state,
                userTyping: action.payload
            }
        case types.WS_CONVERSATION_UPDATE:

            if (state.conversation && state.conversation.id === action.data.conversation.id) {
                state.conversation.last_message = action.data.conversation.last_message
                state.conversation.unread_count = action.data.conversation.unread_count
                state.conversation.updated_at = action.data.conversation.updated_at
            }

            return {
                ...state,
                conversation: state.conversation,
                conversations: state.conversations.map(obj => {
                    return obj.id === action.data.conversation.id ? {
                            ...obj,
                            last_message: action.data.conversation.last_message,
                            unread_count: action.data.conversation.unread_count,
                            updated_at: action.data.conversation.updated_at,
                        }
                        : obj
                }).sort(function (a, b) {
                    return new Date(b.updated_at) - new Date(a.updated_at);
                })
            }
        case types.WS_CONVERSATION_DELETE:

            let conversation = state.conversation;
            const conversations = state.conversations.filter(obj => obj.id !== action.data.conversation.id);

            if (conversation.id === action.data.conversation.id) {
                if (conversations.length > 0) {
                    conversation = state.conversations[0]
                } else {
                    conversation = null
                }
            }

            return {
                ...state,
                conversations: conversations,
                conversation: conversation,
                noConversations: conversations.length == 0
            }

        case types.WS_CONVERSATION_CREATED:

            let currentConversation = state.conversation;
            const createdConversations = state.conversations.concat([action.data.conversation]);

            if (currentConversation == null) {
                currentConversation = action.data.conversation
            }

            return {
                ...state,
                conversations: createdConversations,
                // conversation: currentConversation,
                noConversations: createdConversations.length == 0
            }
        case types.WS_DELETE_MESSAGE:
            return {
                ...state,
                messages: state.messages.map(obj => {
                    return obj.id === action.payload.message.id ? {
                        ...obj,
                        status: action.payload.message.status,
                        body: action.payload.message.body,
                        type: action.payload.message.type
                    } : obj;
                }),
                deleteData: {}
            }
        // Modals
        case types.MESSAGE_DELETE_MODAL_OPEN:
            return {
                ...state,
                messageDeleteModal: true,
                deleteData: action.payload
            }
        case types.CONVERSATION_DELETE_MODAL_OPEN:
            return {
                ...state,
                conversationDeleteModal: true,
                deleteData: action.payload
            }
        case types.MESSAGE_DELETE_MODAL_CLOSE:
        case types.CONVERSATION_DELETE_MODAL_CLOSE:
            return {
                ...state,
                messageDeleteModal: false,
                conversationDeleteModal: false,
                deleteData: {}
            }
        case types.CONTRACTS_MODAL_OPEN:
            return {
                ...state,
                contractsModal: true
            }
        case types.CONTRACTS_MODAL_CLOSE:
            return {
                ...state,
                contractsModal: false
            }
        case types.MESSAGE_REPORT_MODAL_OPEN: {
            return {
                ...state,
                reportModal: true,
                reportData: action.payload
            }
        }
        case types.MESSAGE_REPORT_MODAL_CLOSE: {
            return {
                ...state,
                reportModal: false,
                reportData: null
            }
        }
        case types.MESSAGE_REPORT_MODAL_REASONS: {
            return {
                ...state,
                reportReasons: action.payload
            }
        }
        default:
            return state
    }
}
