import React from "react";

import Image
  from '../../components/shared/Image'

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

class PaginateItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  displayIcon(link) {
    return link.label == "&laquo; Previous" ? this.displayBackIcon(link) : this.displayNextIcon(link)
  }

  displayBackIcon(link) {
    let { item } = this.props
    if(link.url && item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, item.current_page - 1)} class="wal-pagination-link">
          <i class='icon-back'></i>
        </a>
      )
    }
  }

  displayNextIcon(link) {
    let { item } = this.props
    if(link.url && item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, item.current_page + 1)} class="wal-pagination-link">
          <i class='icon-next'></i>
        </a>
      )
    }
  }

  displayLabel(link) {
    let { item } = this.props
    if(item.data.length > 0) {
      return (
        <a href="#" onClick={(e) => this.props.requestNewPageData(e, link.label)} class="wal-pagination-link active">{link.label}</a>
      )
    }
  }

  displayPages() {
    let { links } = this.props.item
    return (
      links && links.map((link) => {
        if(links[0].url != null || links[links.length-1].url != null) {
          return (
            <li class={`wal-pagination-item ${link.active ? 'active' : ''}`}>
              { Number.isInteger(link.label) ? this.displayLabel(link) : this.displayIcon(link)}
            </li>
          )
        }
      })
    )
  }

  render() {
    let { item, searchLoading } = this.props
    {/*if(searchLoading) {
      return (
        <div style={{marginLeft: "48%", paddingTop: 30}}>
          <ReactLoading type="spin" color="#bea58b" height={'6%'} width={'6%'} />
        </div>
      )
    */}

    return (
      <div class="wal-pagination-wrap">
        <ul class="wal-pagination">
          {this.props.item && this.displayPages()}
        </ul>
      </div>
    );
  }
}

export default PaginateItem
