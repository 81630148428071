import {PURCHASED_CONTRACTS, PURCHASED_CONTRACTS_LOADING} from '../constants/types';

const initialState = {
    contracts: [],
    loading: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PURCHASED_CONTRACTS:
            return {
                ...state,
                contracts: action.payload
            }
        case PURCHASED_CONTRACTS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}
