import React
    from 'react'

import Select
  from 'react-select'

import { capitalize }
  from '../../../services/manipulateWithString';

const CustomSelect = (props) => {
  let { errors, options, isMulti, labelName, name, handleChange, placeholder, value } = props

  return (
    <div className={`wal-form wal-form-group ${errors && errors[name] && 'wal-form-error'}`}>
      <label>{labelName}</label>
      <Select
        styles={
          {
            control: (provided, state) => ({
              ...provided,
              borderColor: errors && errors[name] ? '#d92344' : 'hsl(0,0%,80%)'
            }),
          }
        }
        options={options}
        isMulti={isMulti}
        onChange={handleChange}
        placeholder={placeholder}
        defaultValue={value}
      />
    { errors && errors[name] &&
        <div className="error">
          <span>{errors[name]}</span>
        </div>
      }
    </div>
  )
}

export default CustomSelect
