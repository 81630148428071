import React
    from 'react';

import Person
    from '../../assets/img/person-1.png'

import { Link }
from "react-router-dom";

import Image
  from '../../components/shared/Image'

import DisplayRating
  from '../../components/shared/DisplayRating'

import { IMAGE_BASE }
  from "../../constants/types";

import { bookmarkItem, deleteBookmarkItem }
  from '../../helpers/bookmarkItem'

import { getToken }
  from "../../helpers";

import { Modal, AddCard, Details, Success }
  from '../../components/shared/modals/payment';

class ContractCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBookmarked: this.props.item.is_bookmarked
    };
  }

  triggerContractBookmark(e) {
    e.preventDefault()
    let { id } = this.props.item
    let { isBookmarked } = this.state
    if(getToken()) {
      isBookmarked ? deleteBookmarkItem(id, "contract", this.catchResult.bind(this)) : bookmarkItem(id, "contract", this.catchResult.bind(this))
    } else {
      window.$('#login').modal('toggle')
    }
  }

  catchResult(data) {
    data == 0 ? this.setState({isBookmarked: false}) : this.setState({isBookmarked: true})
  }

  openPayment(e) {
    e.preventDefault()
    if(getToken()) {
      window.$('#payment-modal').modal('toggle');
    } else {
      window.$('#login').modal('toggle');
    }
  }

  displayContractBuyButton() {
    let { item } = this.props
    let { is_downloadable } = item
    if(is_downloadable) {
      return (
        <a href={`https://wal.apper.ch/api/contracts/${item.id}/download?token=${getToken()}`} class="contracts-purchase" download>
          <i className="icon-download"></i>
        </a>
      )
    } else {
      return (
        <a href="#" onClick={(e) => this.openPayment(e)} className="contracts-purchase">
          <i className="icon-purchase"></i>
        </a>
      )
    }
  }

  render() {
    let { id, name, description, company, created_at, type, hashtags, rating, docs, is_bookmarked, index } = this.props.item
    let { isBookmarked } = this.state

    return (
      <div className="contracts-item">
        <div className="contracts-left">
          <div className="contracts-img">
            <i className={`icon-contracts`}></i>
          </div>
        </div>
        <div className="contracts-center">
          <div className="contracts-content">
            { docs && docs.length > 0 &&
              <a href={`${IMAGE_BASE}${docs[0].path}`} target="_blank" rel="noopener noreferrer">
                <div className="contracts-icon">
                  <i className="icon-open-cases-docs"></i>
                </div>
              </a>
            }
            <Link to={`/contracts/${id}`} className="contracts-item-wrap">
              <div className="contracts-content-wrap">
                <div className="contracts-title">
                  <h1>{name}</h1>
                </div>
                <div className="contracts-info">
                  <DisplayRating
                    stars={[0,1,2,3,4]}
                    currentRate={rating}
                  />
                  <p>{description && description.replace(/(<([^>]+)>)/ig,' ')}</p>
                  <span>{created_at.slice(0, 10)}</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="contracts-right">
          <div className="contracts-bookmark">
              {<a href="#" onClick={this.triggerContractBookmark.bind(this)}>
                <i className={` ${isBookmarked ? 'icon-bookmarks-filled' : 'icon-bookmarks'}`}></i>
              </a>}
          </div>
          {this.displayContractBuyButton()}
        </div>
        <Modal
          item={this.props.item}
        />
      </div>
    )
  }
}

export default ContractCard
