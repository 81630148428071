import React from "react";

import Verified from "../../../assets/img/icons/verify.svg";

import ContractsSearched from "../../../components/contracts/ContractsSearched";

import KnowledgeCard from "../../../components/lawyers/KnowledgeCard";

import { fetchLawyerById, lawyerContractsSearch, lawyerArticlesSearch } from '../../../actions/lawyersActions'

import { connect } from 'react-redux';

import Image from "../../../components/shared/Image";

import ReactLoading from 'react-loading';

import Maps from '../../../components/lawyers/Map'

import { IMAGE_BASE } from "../../../constants/types";

import DisplayRating from '../../../components/shared/DisplayRating';

import ReportLawyerModal from "../../../components/shared/modals/ReportLawyerModal";

import { getToken } from "../../../helpers";

import ReactHtmlParser from 'react-html-parser'

import api from "../../../api";

import CategorySvg
  from '../../../components/shared/CategorySvg'

import {  EmptyResult }
  from '../../../components/shared/filters';

import { getTranslate }
  from 'react-localize-redux';

import ChatPaymentAlert
  from "../../../components/shared/modals/ChatPaymentAlert";

import { PaymentModal }
  from '../../../components/shared/modals/payment/chat';

class LawyersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      loadingContracts:true,
      searchItemsRelaod: false,
      shortCV:true,
      contractInput:'',
      articleInput:'',
      currentTab:'about'
    };
  }

  componentDidMount() {
    this.requestData()
  }

  async requestData()  {
    let id = this.props.match.params.id

    await this.props.lawyerContractsSearch(id, this.contractsLoading.bind(this))
    await this.props.lawyerArticlesSearch(id, this.contractsLoading.bind(this))
    await this.props.fetchLawyerById(id, this.stopLoading.bind(this))
    window.$('#message-chat-alert').modal('toggle');
  }

  contractSearchinput(input){
    let id = this.props.match.params.id
    this.setState({contractInput:input, searchItemsRelaod: true})
    this.props.lawyerContractsSearch(id ,this.contractsLoading.bind(this), input)
  }

  articlesSearchinput(input){
    let id = this.props.match.params.id
    this.setState({articleInput:input, searchItemsRelaod: true})
    this.props.lawyerArticlesSearch(id ,this.contractsLoading.bind(this), input)
  }

  sortContracts(sort){
    let id = this.props.match.params.id
    let input = this.state.contractInput
    this.setState({searchItemsRelaod: true})
    this.props.lawyerContractsSearch(id ,this.contractsLoading.bind(this), input, sort)
  }

  sortArticles(sort){
    let id = this.props.match.params.id
    let input = this.state.articleInput
    this.setState({searchItemsRelaod: true})
    this.props.lawyerArticlesSearch(id ,this.contractsLoading.bind(this), input, sort)
  }

  stopLoading(){
    this.setState({loading:false})
  }

  contractsLoading(){
    this.setState({loadingContracts:false, searchItemsRelaod: false})
  }

  onChatClick = (event) => {
    event.preventDefault();
    this.goToChat()
  }

  goToChat() {
    let currentLawyer = this.props.lawyerById

    const _this = this;

    if(currentLawyer.chat_available) {
      api.post('chat/conversations', {
          participant_id: currentLawyer.id
      }).then(result => {
          _this.props.history.push('/chat/' + result.data.data.id)
      }).catch(err => console.log(err))
    } else {
      window.$('#message-chat-alert').modal('toggle');
    }
  }

  displayCV(cv){
    if(cv) {
      return (
        <p>
         {this.state.shortCV ? cv.slice(0,150) + "..." : cv}
         {cv.length > 150 &&
            <button onClick={()=> this.setState({shortCV: !this.state.shortCV})}
            className="button button-gray"
            style={{width:'10%', marginTop:20}}>
              {this.state.shortCV ? "Read more": "Read less"}
            </button>
          }
        </p>
      )
    } else {
      return (
        <p>
          No CV available.
        </p>
      )
    }
  }

  displayCategories(categories) {
    let collectMainCategory = []
    categories.map((category) => {
      if(category.parent_category_relation) {
        collectMainCategory.push(category.parent_category_relation)
      }
    })
    const mainUniqueCategories = [...new Map(collectMainCategory.map(item => [JSON.stringify(item), item])).values()]
    return (
      mainUniqueCategories.map((category, index) => {
        return (
          <CategorySvg index={category.id}/>
        )
      })
    )
  }

  displayBar() {
    let currentLawyer = this.props.lawyerById
    let { has_bar, bar_verified, bar } = currentLawyer

    if(bar_verified != 0) {
      var addressVerified = []
      bar.map((b) => {
        if(b.approved == 1) {
          addressVerified.push(b.country.name)
        }
      })

      var addressText = ""
      addressVerified.map((address, index) => {
        addressText += `${address}`
        if(index != addressVerified.length - 1) {
          addressText += ", "
        }
      })

      return (
        <div className="profile-state">
          <i className="icon-world"></i>
          <span>{addressText}</span>
        </div>
      )
    }
  }

  displayContractSearch() {
    if(this.state.searchItemsRelaod){
      return(
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      return (
        <ContractsSearched
            items={this.props.lawyersContracts || []} />
      )
    }
  }

  displayKnowladgeSearch() {
    let { translate } = this.props
    let currentLawyer = this.props.lawyerById
    let articles = this.props.lawyersArticles && this.props.lawyersArticles

    if(this.state.searchItemsRelaod){
      return(
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      if(articles && articles.length > 0) {
        articles.map((item,index)=>{
         return (
           <KnowledgeCard
              author={currentLawyer.name}
              title={item.title}
              source={item.image}
              />
          )
        })
       } else {
         return (
          <EmptyResult
            icon={"type-contract"}
            title={translate('lawyers.details.empty_title')}
            description={translate('lawyers.details.empty_description')}
            action={{label: "Go to Knowledge", goTo: "/knowledge"}}
          />
        )
      }
    }
  }

  displayChatButton() {
    return (
      <a className="wal-icons-button message" onClick={this.onChatClick}><i className="icon-chat"></i></a>
    )
  }

  render() {
    let { translate } = this.props
    let currentLawyer = this.props.lawyerById
    let articles = this.props.lawyersArticles && this.props.lawyersArticles

    if(this.state.loading || this.state.loadingContracts){
      return(
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <div>
        <title>{currentLawyer.name} | WAL</title>
        <main className="main">
            <section className="profile pd-rl"
              style={{ backgroundImage: `url(${IMAGE_BASE + currentLawyer.avatar})` }}>
                <div className="container-fluid">
                    <div className="profile-wrap">
                        <div className="profile-top">
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className="profile-top-wrap">
                              <a onClick={()=> this.props.history.goBack()} style={{color:'#ffffff'}} className="back-button"><i className="icon-back"></i>{translate('lawyers.details.back')}</a>
                                {/* <a href="lawyers.html" className="back-button"><i className="icon-back"></i>Back</a> */}
                                { getToken() && <a href="" className="bookmarks"><i class={currentLawyer.is_bookmarked
                                    ? "icon-bookmarks-filled"
                                    : "icon-bookmarks"}></i></a>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="profile-bottom">
                            <div className="row align-items-end">
                                <div className="col-md-12 col-lg-8">
                                    <div className="profile-info">
                                        <div className="profile-image">
                                          <Image src={currentLawyer.avatar} alt="person" />
                                        </div>
                                        <div className="profile-info-wrap">
                                            <div className="profile-info-top">
                                              <div className="profile-status">
                                                <div class={currentLawyer.is_online
                                                        ? "status active"
                                                        : "status inactive"}></div>
                                                      <span>{currentLawyer.is_online ? translate('lawyers.details.online') : translate('lawyers.details.offline')}</span>
                                              </div>
                                              <div className="lawyers-type">
                                                {this.displayCategories(currentLawyer.categories)}
                                              </div>
                                            </div>
                                            <div className="profile-name">
                                              <h1>{currentLawyer.title?.title || currentLawyer.title } {currentLawyer.name}</h1>
                                              {currentLawyer.bar_verified &&
                                                <div className="verify-badge">
                                                  <img src={Verified} alt="verify"/>
                                                </div>
                                              }
                                            </div>
                                            <div className="profile-rating-state">
                                                <div className="profile-rating">
                                                  <div className="rated-stars">
                                                    <DisplayRating
                                                        stars={[0,1,2,3,4]}
                                                        currentRate={currentLawyer.review.overall}
                                                        />
                                                  </div>
                                                  <span>{currentLawyer.review.overall}</span>
                                                </div>
                                                {this.displayBar()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { getToken() &&
                                <div className="col-md-6 col-lg-4">
                                    <div className="profile-buttons">
                                        {/*<a href="" className="button button-primary">{translate('lawyers.details.call_now')}</a>*/}
                                        <div className="wal-icons">

                                            {this.displayChatButton()}

                                             <div className="dropdown-options profile-options-dropdown">
                                                 <div data-toggle="dropdown" data-display="static">
                                                     <a className="wal-icons-button options"><i className="icon-options"></i></a>
                                                 </div>
                                                 <ul className="dropdown-menu">
                                                     <div className="more-options">
                                                         <span>{translate('lawyers.details.more_option')}</span>
                                                     </div>
                                                     <li>
                                                  <a href="" className="report-lawyer" data-toggle="modal"
                                                  data-target="#report" ><i className="icon-report-lawyer"></i>
                                                  {translate('lawyers.details.report_lawyer')}</a></li>
                                                 </ul>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                              }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="profile-content mg-top-40 pd-rl">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-8">
                           <ul className="profile-tab nav nav-tabs">
                              <li onClick={()=> this.setState({currentTab: 'about'})}
                                class="nav-list">
                                  <a class={`nav-link ${this.state.currentTab == 'about' && 'active'}`}
                                    href="#about" data-toggle="tab" role="tab" aria-controls="about">
                                    {translate('lawyers.details.about')}
                                  </a>
                                </li>

                                <li onClick={()=> this.setState({currentTab: 'contracts'})}
                                class="nav-list">
                                  <a class={this.state.currentTab == 'contracts' && "active"}
                                    href="#contracts" class="nav-link" data-toggle="tab" role="tab" aria-controls="contracts">
                                    {translate('lawyers.details.contracts_and_templates')}
                                  </a>
                                </li>
                                <li onClick={()=> this.setState({currentTab: 'knowledge'})}
                                class="nav-list">
                                  <a class={this.state.currentTab == 'knowledge' && "active"}
                                     href="#knowledge" class="nav-link"  data-toggle="tab" role="tab" aria-controls="knowledge" >
                                  {translate('lawyers.details.knowledge')}
                                  </a>
                                </li>
                            </ul>
                            <div className="profile-tab-content tab-content" id="tabs">
                                <div className="tab-pane active" id="about">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                          <h4 className="panel-title nav-list">
                                            <a data-toggle="collapse" data-target="#collapse1"
                                            aria-expanded="true" data-parent="#tabs">{translate('lawyers.details.about')}</a>
                                          </h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse show" data-parent="#tabs">
                                            <div className="panel-body nav-content">
                                                <div className="profile-article">
                                                    <h3>{translate('lawyers.details.field_of_practice')}</h3>
                                                    <ul>
                                                      {currentLawyer.categories.filter((c) => c.parent_id != null).map((item,index)=>{
                                                        return <li key={index}>{item.name}</li>
                                                      })}
                                                    </ul>
                                                    <h3>{translate('lawyers.details.about_lawyer')}</h3>
                                                    <p>{ReactHtmlParser(currentLawyer.description)}</p>
                                                    <h3>{translate('lawyers.details.motto')}</h3>
                                                    <p>{currentLawyer.motto || "No motto available"}</p>
                                                    <h3>CV</h3>
                                                    <p>
                                                      {this.displayCV(currentLawyer.cv)}
                                                    </p>
                                                    <h3>{translate('lawyers.details.membership')}</h3>
                                                    <p>{currentLawyer.memberships.length > 0  ?
                                                        currentLawyer.memberships.map((item,index)=> <p>{item.club}</p>)
                                                        :
                                                        translate('lawyers.details.no_membership')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="tab-pane" id="contracts">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title nav-list">
                                                <a data-toggle="collapse"
                                                data-target="#collapse2" aria-expanded="false" data-parent="#tabs">{translate('lawyers.details.contracts_and_templates')}</a>
                                            </h4>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse" data-parent="#tabs">
                                            <div className="panel-body nav-content">
                                                <div className="wal-content-head">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-12 col-lg-2">
                                                            <div className="wal-content-title">
                                                                <h1><span>{this.props.lawyersContracts && this.props.lawyersContracts.length} total</span></h1>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="wal-form wal-form-group wal-form-search">
                                                                <input type="search"
                                                                  name="Search Contracts"
                                                                  placeholder={translate('lawyers.details.search_contracts_and_templates')}
                                                                  className="wal-form-control"
                                                                  value={this.state.contractInput}
                                                                  onChange={(event)=> this.contractSearchinput(event.target.value)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-lg-5">
                                                            <div className="wal-form wal-form-group wal-form-sort">
                                                                <label for="select">{translate('lawyers.sort_by')}:</label>
                                                                <div className="wal-form-sort-wrap">
                                                                    <select
                                                                    onChange={(event)=> this.sortContracts(event.target.value)}
                                                                    className="wal-form-control">
                                                                        <option value='-created_at'>{translate('lawyers.details.newest_first')}</option>
                                                                        <option value='created_at'>{translate('lawyers.details.oldest_first')}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="profile-contracts">
                                                  {this.displayContractSearch()}
                                                </div>
                                                  {this.props.lawyersContracts && this.props.lawyersContracts.length > 5 &&
                                                    <a href="" className="more-button button button-gray">{translate('lawyers.details.load_more')}</a>
                                                  }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane" id="knowledge">
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h4 className="panel-title nav-list">
                                                <a data-toggle="collapse" data-target="#collapse3" aria-expanded="false" data-parent="#tabs">{translate('lawyers.details.knowledge')}</a>
                                            </h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse" data-parent="#tabs">
                                            <div className="panel-body nav-content">
                                                <div className="profile-knowledge">
                                                    <div className="wal-content-head">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-12 col-lg-2">
                                                              <div className="wal-content-title">
                                                                <h1><span>{articles && articles.length} {translate('lawyers.details.total')}</span></h1>
                                                              </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-5">
                                                              <div className="wal-form wal-form-group wal-form-search">
                                                                <input type="search"
                                                                  name="Search articles"
                                                                  placeholder={translate('lawyers.details.search_for_articles')}
                                                                  className="wal-form-control"
                                                                  value={this.state.articleInput}
                                                                  onChange={(event)=> this.articlesSearchinput(event.target.value)}/>
                                                              </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-5">
                                                              <div className="wal-form wal-form-group wal-form-sort">
                                                                <label for="select">Sort by:</label>
                                                                <div className="wal-form-sort-wrap">
                                                                  <select
                                                                      onChange={(event)=> this.sortArticles(event.target.value)}
                                                                      className="wal-form-control">
                                                                      <option value='-created_at'>{translate('lawyers.details.newest_first')}</option>
                                                                      <option value='created_at'>{translate('lawyers.details.oldest_first')}</option>
                                                                  </select>
                                                                </div>
                                                              </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                      {this.displayKnowladgeSearch()}
                                                      {articles && articles.length > 5  &&
                                                        <div className="col-md-12 col-lg-12 col-xl-12">
                                                          <a href="" className="more-button button button-gray">{translate('lawyers.details.load_more')}</a>
                                                        </div>
                                                      }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-4">
                          <div className="profile-aside">
                            <div className="profile-create-item">
                              <div className="profile-create-right">
                                <div className="profile-create-img">
                                  <img src="img/logo-black.svg" alt="company" />
                                </div>
                                <div className="profile-create-content">
                                  <h1>{currentLawyer.company && currentLawyer.company.name || translate('lawyers.details.no_company')}</h1>
                                  <p>{translate('lawyers.details.company')}</p>
                                </div>
                              </div>
                              <div className="profile-create-left">
                                <a href=""><i className="icon-next"></i></a>
                              </div>
                            </div>
                            <div className="profile-aside-content">
                              <div className="profile-aside-content-item">
                                <h3>{translate('lawyers.details.email')}</h3>
                                <p>{currentLawyer.email}</p>
                                <a href={"mailto:" + currentLawyer.email} className="next"><i className="icon-next"></i></a>
                              </div>
                              <h3>{translate('lawyers.details.language')}</h3>
                              { currentLawyer.languages.map((item)=> <p>{item.name}</p>) }
                              <h3>{translate('lawyers.details.location')}</h3>
                              <p>{currentLawyer.address}</p>
                            </div>
                            <div className="profile-aside-map">
                              <Maps
                                lat={currentLawyer.latitude}
                                long={currentLawyer.longitude}
                                lawyers={[currentLawyer]}
                              />
                            </div>
                            {currentLawyer.latitude && currentLawyer.longitude &&
                              <a href={"https://www.google.com/maps/dir//"+currentLawyer.latitude+","+currentLawyer.longitude }
                                target="_blank" className="button button-white"><i className="icon-pin"></i>{translate('lawyers.details.get_direction')}</a>
                            }
                          </div>
                        </div>
                    </div>
                </div>
            </section>
            <ReportLawyerModal lawyerId={currentLawyer.id}/>
        </main>
        <ChatPaymentAlert
          translate={translate}
        />
        <PaymentModal
          item={currentLawyer}
          translate={translate}
          history={this.props.history}
        />
    </div>
  )};
}

function mapStateToProps(state){
    return ({
        lawyerById: state.lawyerById.data,
        lawyersContracts: state.lawyersContracts.data,
        lawyersArticles: state.lawyersArticles.data,
        translate: getTranslate(state.locale)
    })
}

export default connect(mapStateToProps, {fetchLawyerById, lawyerContractsSearch, lawyerArticlesSearch})(LawyersDetails);
