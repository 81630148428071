import { GET_ARTICLE_REVIEWS, EMPTY_STATE }
  from '../constants/types.js';

export default function(state=[], action){
  switch(action.type){
    case GET_ARTICLE_REVIEWS:
      return [...state, ...action.payload]
    case EMPTY_STATE:
      return []
    default:
      return state
  }
}
