import React from "react";

import { connect } from 'react-redux';

import Image from "../shared/Image";

import { IMAGE_BASE } from "../../constants/types";

import DisplayRating from "../shared/DisplayRating";

import {reviewArticle} from '../../helpers/articleReview'

import { bookmarkItem, deleteBookmarkItem } from '../../helpers/bookmarkItem'

import ReactLoading from 'react-loading';

import { getToken } from "../../helpers";

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";

import ReactHtmlParser
  from 'react-html-parser'

const TYPE_OF_REVIEWS = [{title: "Quality", value: "quality"},
                         {title: "Professionality", value: "professionality"},
                         {title: "Experience", value: "experience"},
                         {title: "Helpful", value: "helpful"}]

class ArticleLogged extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quality:1,
      professionality:1,
      experience:1,
      helpful:1,
      comment:'',
      currentPage:1
    };

    this.inputShareRef = React.createRef();
  }

  applyReview(){
    if(this.state.comment == ''){
      alert("Review cannot be blank!")
    } else {
      this.setState({comment:'', quality:1, professionality:1, experience:1, helpful:1})
      let id = this.props.article.id
      reviewArticle(this.state, id)
      this.props.reloadArticleAfterReview()
    }
  }

  bookmarkArticle(){
    let {is_bookmarked, id} = this.props.article
    is_bookmarked ? deleteBookmarkItem(id, "article", this.catchResult.bind(this))
                  : bookmarkItem(id, "article", this.catchResult.bind(this))

  }

  catchResult(){
    this.props.bookmarkItem()
  }

  copyToClipboard(e) {
    e.preventDefault();
    this.inputShareRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  DisplayRatingTypes() {
    return (
      TYPE_OF_REVIEWS.map((review) => {
        let { title, value } = review
        return (
          <div class="profile-reviews-item">
            <span>{review.title}</span>
            <DisplayRating
              stars={[1,2,3,4,5]}
              changeMode
              stateType={this.state.[`${value}`]}
              changeStateType={(index) => this.setState({[`${value}`]: index})}
            />
          </div>
        )
      })
    )
  }

  displayCommentButton() {
    let { translate } = this.props
    if(this.props.reviewsLoading) {
      return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading height={40} width={40} type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      return (
        <button onClick={()=> this.applyReview()}
        class="button button-primary"><i class="icon-send"></i>{translate('articles.details.send_review')}</button>
      )
    }
  }

  displayComments() {
    let { translate } = this.props
    if(this.props.reviews.length == 0) {
      return (
        <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p>{translate('articles.details.not_found_review')}</p>
        </div>
      )
    }

    if(this.props.reviewsLoading) {
      return (
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    } else {
      return (
        <div class="comments">
            <div class="comments-body">
              {this.props.reviews.map((item,index)=>{
                return (
                  <div class="comments-item">
                    <div class="comments-info">
                      <div class="comments-img">
                        <Image src={item.user && item.user.avatar} alt="header" />
                      </div>
                      <div class="comments-info-wrap">
                        <h1>{item.user && item.user.name}</h1>
                        <div class="comments-info-bottom">
                          <div class="profile-rating">
                            <DisplayRating
                              stars={[0,1,2,3,4]}
                              currentRate={item.overall_rating}
                            />
                            <span>{item.overall_rating}</span>
                          </div>
                          <span>{item.created_at.slice(0,10)}</span>
                        </div>
                      </div>
                    </div>
                    <div class="comments-content">
                      <p>{item.comment || "No comment available."}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            {this.props.currentReviewsPage < this.props.lastReviewsPage &&
              <a onClick={this.props.moreReviews}
              class="more-button button button-gray">Load more reviews</a>
            }
        </div>
      )
    }
  }

  render() {
    let article = this.props.article
    let { translate } = this.props
    return (
      <main class="main">
        <title>{article.title} | WAL</title>
        <section class="profile pd-rl"
            style={{ backgroundImage: `url(${IMAGE_BASE + article.image})` }}
            >
          <div class="container-fluid">
              <div class="profile-wrap">
                <div class="profile-top">
                  <div class="row">
                    <div class="col-md-12 col-lg-12">
                      <div class="profile-top-wrap">
                        <a onClick={this.props.goBack} style={{color:'#ffffff'}}
                            class="back-button">
                            <i class="icon-back"></i>{translate('articles.details.back')}</a>
                        { <a onClick={()=> this.bookmarkArticle()} class="bookmarks">
                            <i class={article.is_bookmarked ? "icon-bookmarks-filled" : "icon-bookmarks"}></i></a> }
                      </div>
                    </div>
                  </div>
                </div>
                <div class="profile-bottom">
                  <div class="row align-items-end">
                    <div class="col-md-12 col-lg-8">
                       <div class="profile-info">
                         <div class="profile-info-wrap">
                           {article.categories.length > 0 &&
                             <div class="profile-type articles-type">
                               <span class="type">{article.categories[0].name}</span>
                                {article.categories.length > 1 &&
                                  <div class="more-list">
                                     <span>+{article.categories.slice(1, article.categories.length).length}
                                     more</span>
                                     <ul>
                                       {article.categories.slice(1, article.categories.length).map((item,index)=>{
                                          return <li>- {item.name}</li>
                                       })}
                                     </ul>
                                   </div>
                                 }
                             </div>
                           }
                           <div class="profile-name">
                             <h1>{article.title}</h1>
                           </div>
                           <div class="profile-rating-state">
                             <div class="profile-rating">
                               <DisplayRating
                                 stars={[0,1,2,3,4]}
                                 currentRate={article.rating}
                               />
                               <span>{article.rating}</span>
                             </div>
                             <div class="profile-type">
                               <span><i class="icon-eye"></i>{article.viewed} {translate('articles.details.views')}</span>
                             </div>
                             <div class="profile-type">
                               <span><i class="icon-chat"></i>{this.props.totalReviewsNumber} {translate('articles.details.comments')}</span>
                             </div>
                           </div>
                           {article.hashtags &&
                             <div class="articles-tags">
                               <ul>
                                {article.hashtags.map((item,index)=>{
                                    return <li><a href="#">{item.tag}</a></li>
                                })}
                               </ul>
                             </div>
                           }
                       </div>
                     </div>
                   </div>
                   <div class="col-md-6 col-lg-4">
                    <div class="profile-buttons">
                      <div class="wal-icons">
                        <div class="dropdown-options contracts-options-dropdown">
                          <div data-toggle="dropdown" data-display="static">
                            <a href="#" class="wal-icons-button options"><i class="icon-share"></i></a>
                          </div>
                          <ul class="dropdown-menu">
                            <div class="more-options">
                              <span>{translate('articles.details.share_this_article')}</span>
                            </div>
                            <li>
                              <a href="#"><i class="icon-email"></i>{translate('articles.details.mail')}</a>
                            </li>
                            <li>
                              <LinkedinShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-linkedin"></i>{translate('articles.details.linkedin')}</a>
                              </LinkedinShareButton>
                            </li>
                            <li>
                              <TwitterShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-twitter"></i>{translate('articles.details.twitter')}</a>
                              </TwitterShareButton>
                            </li>
                            <li>
                              <FacebookShareButton url={window.location.href} className="share">
                                <a href="#"><i className="icon-facebook-alt"></i>{translate('articles.details.facebook')}</a>
                              </FacebookShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="profile-content mg-top-40 pd-rl">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-8">
                <div class="profile-article articles-content">
                  <p>{ReactHtmlParser(article.description)}</p>
                </div>
                <div class="main-title">
                  <h2>{translate('articles.details.rate_it')}</h2>
                </div>
                <div class="profile-reviews-wrap">
                  <div class="profile-reviews-top">
                    <div class="profile-reviews">
                      {this.DisplayRatingTypes()}
                    </div>
                    <div class="profile-reviews-input">
                      <div class="comments-img">
                      <Image src={this.props.current_user.avatar} alt="header" />
                      </div>
                      <div class="comments-input">
                        <textarea name="comment"
                        value={this.state.comment}
                        onChange={(value)=> this.setState({comment:value.target.value})}
                        class="wal-form-control"
                        placeholder={translate('articles.details.write_a_review')}></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="comments-send">
                    {this.displayCommentButton()}
                  </div>
                </div>
                <div class="main-title">
                  <h2>{translate('articles.details.reviews')}</h2>
                </div>
                {this.displayComments()}
              </div>
              <div class="col-md-12 col-lg-12 col-xl-4">
                <div class="profile-aside">
                 <div class="profile-aside-title">
                   <h3>{translate('articles.details.created_by')}</h3>
                 </div>
                 <div class="profile-create-item">
                   <div class="profile-create-right">
                     <div class="lawyers-img">
                      <Image src={article.lawyer.avatar} alt="header" />
                      <div class={article.lawyer.is_online ? "status active" : "status inactive"}></div>
                     </div>
                     <div class="profile-create-content">
                       <h1>{article.lawyer.name}</h1>
                       <p>{translate('articles.details.lawyer')}</p>
                     </div>
                   </div>
                   <div class="profile-create-left">
                     <a href="#" class="chat"><i class="icon-chat"></i></a>
                   </div>
                 </div>
                   <div class="profile-aside-date">
                     <h3>{translate('articles.details.published_on')}</h3>
                     <p>{article.created_at.slice(0,10)}</p>
                   </div>
                   <div class="profile-aside-copy">
                   <div class="copy-link">
                     <input type="text" class="wal-form-control" ref={this.inputShareRef} value={`${window.location.href}`} readonly />
                     <a href="#" class="copy" onClick={this.copyToClipboard.bind(this)}><i class="icon-copy"></i></a>
                   </div>
                 </div>
               </div>
              </div>
            </div>
          </div>
        </section>
      </main>
  )};
}

function mapStateToProps(state){
    return ({
                current_user: state.currentUser,
            })
    }

export default connect(mapStateToProps, {})(ArticleLogged);
