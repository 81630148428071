import React
    from 'react';

import Person
    from '../../assets/img/person-1.png'

import Image
    from '../../components/shared/Image'

import { Link }
  from "react-router-dom";

import { getToken }
  from "../../helpers";

export default class LawyerInRegion extends React.Component {

  render() {
    return(
        <Link to={'/lawyers/'+this.props.id} className="lawyers-item lawyers-item-region">
        <div className="lawyers-img">
          <Image
            src={this.props.imageSource}
            alt="person"
          />
          <div className="status active"></div>
        </div>
        <div className="lawyers-content">
          <div className="lawyers-title">
            <h1>{this.props.name}</h1>
          </div>
          <div className="lawyers-info lawyers-info-region">
            <div className="rated-stars">
              <i className="icon-star-filled"></i>
              <i className="icon-star-filled"></i>
              <i className="icon-star-filled"></i>
              <i className="icon-star-filled"></i>
              <i className="icon-star-filled"></i>
            </div>
            <div className="lawyers-info-region-bottom">
              <span className="distance">14 km</span>
              <span className="type">Privates sowie öffentliches Arbeitsrecht</span>
            </div>
          </div>
        </div>
        <div className="lawyers-bookmarks">
          { getToken() && <a href=""><i className="icon-bookmarks-filled"></i></a> }
        </div>
    </Link>
    )
  }
}
