import React
    from 'react'

const CustomTextInput = (props) => {
  let { errors, name, labelName, value, handleChange, placeholder, disabled, type} = props
  return (
    <div className={`wal-form wal-form-group ${errors && errors[name] && 'wal-form-error'}`}>
      <label>{labelName}</label>
      <input
        type={type}
        name={name}
        className="wal-form-control"
        value={value}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        disabled={disabled ? true : false}
      />
      { errors && errors[name] &&
        <div className="error">
          <span>{errors[name]}</span>
        </div>
      }
    </div>
  )
}

export default CustomTextInput
