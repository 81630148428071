import {
  ARTICLES_FOR_SLIDER
} from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case ARTICLES_FOR_SLIDER:
      return action.payload
    default:
      return state
  }
}
