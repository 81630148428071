import { WEB_URL } from "../constants/types";

import api from "../api";

import moment from "moment";

export async function getOrderHistory(addOrderHistory, params = null) {
  let url = `${WEB_URL}/orders-history?per_page=10&`;

  if(params) {
    if(params.page && params.page != "" && params.page !== null) { url = url + `page=${params.page}&` }
    if(params.type && params.type != "" && params.type !== null) { url = url + `type=${params.type}&` }
    if(params.search && params.search != "" && params.search !== null) { url = url + `search=${params.search}&` }
    if(params.startDate && params.startDate != "" && params.startDate !== null) { url = url + `start_date=${moment(params.startDate).format("YYYY-MM-DD")}&` }
    if(params.endDate && params.endDate != "" && params.endDate !== null) { url = url + `end_date=${moment(params.endDate).format("YYYY-MM-DD")}&` }
    if(params.sort && params.sort != "" && params.sort !== null) {
      url = url + `sort=${params.sort}&`
    } else {
      url = url + `sort=-created_at&`
    }
  }

  api.get(url)
    .then((data) => {
      addOrderHistory(data.data);
    })
    .catch((err) => {
      console.log("GET ORDER HISTORY ERROR", err);
    });
}
