import React
    from 'react'

class RadioSelectInput extends React.Component {
  displayOptions() {
    let { mainObject, typeError, selectType, selectName, noIcon } = this.props
    return (
      this.props.options.map((opt) => {
        return (
          <div className="col-md-6 col-lg-6">
            <div className="wal-form-type">
              <input type="radio"
                     className="radio"
                     name={selectName}
                     value={opt.value}
                     checked={mainObject== opt.value ? true : false }
                  />
                <label for="private" style={{borderColor: typeError ? "#d92344" : ""}} onClick={() => selectType(opt.value)}>
                {!noIcon && <i className={opt.logo}></i>}{opt.label}
              </label>
            </div>
          </div>
        )
      })
    )
  }

  render() {
    let { textError, radioTitle, typeError, translate, requestParams, errors, selectType } = this.props
    return (
      <div className="col-md-12 col-lg-12">
        <div className="wal-form wal-form-group">
          <label for="type">{radioTitle}</label>
          <div className="row">
            {this.displayOptions()}
          </div>
          { typeError &&
            <div style={{marginTop: 10, marginLeft: "32%"}}>
              <span style={{color: "#d92344", fontSize: 12}}>{textError}</span>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default RadioSelectInput
