import React from "react";
import { addDays, subDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import $ from 'jquery';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      daterange: [
        {
        startDate: subDays(new Date(), 500),
        endDate: new Date(),
        key: 'selection',
        color: '#191D26'
        }
      ]
    };
  }

  changeDateRange(item) {
    let { startDate, endDate } = item.selection
    let endDateState = this.state.daterange[0].endDate
    this.setState({daterange: [item.selection]})

    if(startDate != endDate) {
      this.hideDaterangeModal()
      this.props.changeDateRangeFilter(item.selection.startDate, item.selection.endDate)
    }
  }

  hideDaterangeModal() {
    $("#dropdown-daterange").removeClass('show');
    $("#dropdown-menu-daterange").removeClass('show');
  }

  render() {
    let { daterange } = this.state
    let { translate } = this.props
    return (
      <div className="wal-content-head">
        <div className="wal-content-head-wrap">
          <div className="row align-items-center">
            <div className="col-md-4 col-lg-4">
              <div className="wal-form wal-form-group wal-form-search">
                <input
                  type="search"
                  name="Search lawyer"
                  placeholder={translate("settings.order_history.filters.search_lawyer_placeholder")}
                  className="wal-form-control"
                  value={this.props.search}
                  onChange={(e) => this.props.changeSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="wal-form wal-form-group">
                <div className="wal-form-date wal-form-date-dropdown dropdown" id="dropdown-daterange">
                  <button className="wal-form-control wal-form-date-input" type="button" data-toggle="dropdown" data-display="static">
                    {moment(daterange[0].startDate).format("DD.MM.YYYY")} - {moment(daterange[0].endDate).format("DD.MM.YYYY")}
                    <i className="icon-calendar"></i>
                  </button>
                  <div className="wal-form-date-menu dropdown-menu" id="dropdown-menu-daterange">
                    <div className="calendar">
                      <DateRangePicker
                        onChange={item => this.changeDateRange(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={this.state.daterange}
                        direction="horizontal"
                      />
                      {/*<div className="calendar-buttons">
                        <button className="button button-white" id="button-cancel">Cancel</button>
                        <button className="button button-primary">Apply</button>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="wal-form wal-form-group wal-form-sort">
                <label for="select">{translate("settings.order_history.filters.sort_by")}:</label>
                <div className="wal-form-sort-wrap">
                  <select onChange={(event)=> this.props.sortHistory(event.target.value)} className="wal-form-control">
                    <option value="Oldest">{translate("settings.order_history.filters.oldest")}</option>
                    <option value="Newest">{translate("settings.order_history.filters.newest")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Filters;
