import React from "react";
import FlagCh from '../../../assets/img/flag-ch.svg'
import FlagEu from '../../../assets/img/flag-eu.svg'
import FlagUS from '../../../assets/img/flag-us.svg'
import { storeCurrency, getCurrency }
  from '../../../services/storeCurrency'
import { getTranslate }
  from 'react-localize-redux';
import { connect }
  from 'react-redux';

class ChangeCurrencyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: getCurrency
    };
  }

  changeCurrency(val) {
    this.setState({currency: val})
  }

  saveCurrency() {
    let { currency } = this.state
    storeCurrency(currency)
    window.location.reload()
  }

  render() {
    let { translate } = this.props
    let { currency } = this.state
    return (
      <div class="modal fade modal-ge currency-modal" id="change-currency" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="currency-modal-wrap modal-ge-wrap">
              <div class="modal-ge-top payment-modal-top">
                <h1>{translate("shared.select_currency")}</h1>
              </div>
              <div class="modal-ge-body currency-modal-body">
                <div class={`currency-modal-item ${currency == "ch" && 'active'}`} onClick={() => this.changeCurrency("ch")}>
                  <div class="currency-modal-img">
                    <img src={FlagCh} />
                  </div>
                  <div class="currency-modal-content">
                    <h1>Switzerland</h1>
                    <span>CHF- FR</span>
                  </div>
                </div>
                <div class={`currency-modal-item ${currency == "eu" && 'active'}`} onClick={() => this.changeCurrency("eu")}>
                  <div class="currency-modal-img">
                    <img src={FlagEu} />
                  </div>
                  <div class="currency-modal-content">
                    <h1>Europe</h1>
                    <span>€ - Euro</span>
                  </div>
                </div>
                <div class={`currency-modal-item ${currency == "us" && 'active'}`} onClick={() => this.changeCurrency("us")}>
                  <div class="currency-modal-img">
                    <img src={FlagUS} />
                  </div>
                  <div class="currency-modal-content">
                    <h1>United States Dollar</h1>
                    <span>$ - USD</span>
                  </div>
                </div>
              </div>
              <div class="modal-ge-bottom">
                <button class="button button-primary" onClick={this.saveCurrency.bind(this)}>{translate("shared.done")}</button>
              </div>
              <div class="modal-ge-close" data-dismiss="modal"><i class="icon-x"></i></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};


function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
          })
}

export default connect(mapStateToProps, { })(ChangeCurrencyModal);
