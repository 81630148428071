import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function reviewArticle(state, id) {
  let data = {
      "quality": state.quality,
      "comment": state.comment,
      "professionality": state.professionality,
      "experience": state.experience,
      "helpful": state.helpful
  }

  let url = `${WEB_URL}/articles/${id}/reviews?sort=-created_at`;
  api.post(url, qs.stringify(data))
    .then((data) => {
    })
    .catch((err) => {
    });
}
