import React, {Component} from 'react';

class EmptyConversation extends Component {
    render() {
        return (
            <div className="messages-sidebar-body">
                <div className="wal-result-none-wrap messages-empty">
                    <div className="wal-result-none-icon">
                        <i className="icon-chat"></i>
                    </div>
                    <h1>No messages</h1>
                    <p>
                        Sorry you haven’t chat with anyone yet, when you do,
                        messages will be shown here.
                    </p>
                </div>
            </div>
        );
    }
}

export default EmptyConversation;