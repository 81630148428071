import { COUNTRY_ITEMS }
  from '../constants/types.js';

export default function(state=[], action){
  switch(action.type){
    case COUNTRY_ITEMS:
      return [ ...state, ...action.payload ]
    default:
      return state
  }
}
