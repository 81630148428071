import React, {Component} from 'react';

class Typing extends Component {
    render() {
        return (
            <span className="status">
                Typing
                <span className="animate-typing">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                </span>
            </span>
        );
    }
}

export default Typing;