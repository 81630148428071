import React
  from 'react';
import { CustomTextInput }
  from '../../components/shared/forms';
import { connect }
  from 'react-redux';
import { FacebookAuthButton, GoogleAuthButton }
  from '../../components/auth';

class SocialMediaButtons extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        requestLogin: false
      };
  }

  render() {
    let { translate, facebookTextButton, googleTextButton } = this.props
    
    return(
      <div className="login-other">
        <FacebookAuthButton
          facebookTextButton={facebookTextButton}
          startLoading={this.props.startLoading}
          stopLoading={this.props.stopLoading}
        />
        <GoogleAuthButton
          googleTextButton={googleTextButton}
          startLoading={this.props.startLoading}
          stopLoading={this.props.stopLoading}
        />
      </div>
    )
  }
}

export default connect(null, { })(SocialMediaButtons);
