import React
  from 'react';

import TopRatedContracts
  from '../../../components/contracts/TopRatedContracts';

import ContractsSearched
  from '../../../components/contracts/ContractsSearched';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { fetchContracts, fetchContractsByFilter, fetchTopRatedContracts }
  from "../../../actions/contractsActions";

import { ContentForm }
  from '../../../components/shared/filters';

import { getCategories, getLawyers, getLanguages, getSubCategories, getJurisdictions }
  from '../../../helpers/getFilterData'

import { SelectItem }
  from '../../../components/shared/forms';

import PaginateItem
  from '../../../components/shared/PaginateItem';

const FILTERS = { selectedType: "", selectedCategory: "", selectedCategories: [], selectedJurisdictions: [], selectedLanguage: "", rating: null }

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCategories:[],
      filterJurisdictions:[],
      filterSubCategories:[],
      filterTypes:[
        {id: "contract", name: "contract"},
        {id: "template", name: "template"},
        {id: "other", name: "other"}
      ],
      filterSorts: [
        {id: "created_at", name: "Ascending"},
        {id: "-created_at", name: "Descending"}
      ],
      searchQuery: "",
      selectedSort: "",
      filterLanguages: [],
      filtersApplied: false,
      searchLoading:true,
      searchTopRatedLoading:true,
      pathParams: props.history.location.search,
      page: 1,
      action: "link",
      ...FILTERS
    };
  }

  async componentDidMount() {
    this.requestData()

    if(this.state.pathParams !== "") {
      let onlyParams = this.state.pathParams.split('?')[1]
      let currentItem = ""
      let selectedType = ""
      let selectedCategory = ""
      let selectedLanguage = ""
      let searchQuery = ""
      let selectedSort = ""
      let rating = null
      let filtersApplied = false
      let page = 1
      let selectedCategories = []
      let selectedJurisdictions = []

      await onlyParams.split("&").forEach(function (kv) {
        currentItem = kv.split("=", 2);
        if(currentItem[0] === "page") { page = currentItem[1] }
        if(currentItem[0].includes("categories")) { selectedCategories.push(parseInt(currentItem[1])); filtersApplied = true }
        if(currentItem[0].includes("jurisdictions")) { selectedJurisdictions.push(parseInt(currentItem[1])); filtersApplied = true }
        if(currentItem[0] === "type") { selectedType = currentItem[1]; filtersApplied = true }
        if(currentItem[0] === "language") { selectedLanguage = currentItem[1]; filtersApplied = true }
        if(currentItem[0] === "rating") { rating = currentItem[1]; filtersApplied = true }
        if(currentItem[0] === "sort") { selectedSort = currentItem[1] }
        if(currentItem[0] === "name") { searchQuery = currentItem[1].replace(/%20/g, " ") }
      });
      this.setState({page, selectedType, selectedCategory, searchQuery, selectedLanguage, filtersApplied, rating, selectedSort, selectedCategories, selectedJurisdictions})
      this.requestWithFilters({page: page, type: selectedType, category: selectedCategory, tag: selectedLanguage, name: searchQuery, rating, sort: selectedSort, categories: selectedCategories, jurisdictions: selectedJurisdictions})
    } else {
      await this.props.fetchContractsByFilter({page: this.state.page}, this.contractSearchLoadingFunction.bind(this))
    }
  }

  async requestData() {
    await this.props.fetchTopRatedContracts(this.stopTopRatingLoading.bind(this))
    getCategories(this.fillCategories.bind(this))
    getLanguages(this.fillLanguages.bind(this))
    getSubCategories(this.fillSubCategories.bind(this))
    getJurisdictions(this.fillJurisdictions.bind(this))
  }

  stopTopRatingLoading() {
    setTimeout(() => {
      this.setState({searchTopRatedLoading:false})
    }, 200);
  }

  fillLawyers(data){
    this.setState({
        filterLawyers:data.data,
      })
  }

  fillCategories(data) {
    this.setState({
      filterCategories:data.data,
    })
  }

  fillLanguages(data) {
    this.setState({
      filterLanguages:data.data,
    })
  }

  fillSubCategories(data) {
    this.setState({
      filterSubCategories: data.data,
    })
  }

  fillJurisdictions(data) {
    this.setState({
      filterJurisdictions: data.data,
    })
  }

  resetFilterState(){
    this.resetRequestFilters()
  }

  resetRequestFilters() {
    let { page, selectedType, selectedCategory, selectedLanguage, selectedSort, rating, searchQuery, selectedCategories, selectedJurisdictions } = this.state
    this.setState({action: "nav", page, searchLoading: true, ...FILTERS})
    this.requestWithFilters({ type: "", category: "", language: "", name: this.state.searchQuery, sort: this.state.selectedSort, page: page, categories: [], jurisdictions: []})
    this.setState({filtersApplied: false})
  }

  searchInputFinishedWriting(input){
    let { page, selectedType, selectedCategory, selectedLanguage, selectedSort, rating, searchQuery, selectedCategories, selectedJurisdictions } = this.state
    this.setState({action: "nav", searchQuery: input, searchLoading:true})
    this.requestWithFilters({ page: page, type: selectedType, category: selectedCategory, language: selectedLanguage, rating, name: input, sort: selectedSort, categories: selectedCategories, jurisdictions: selectedJurisdictions })
  }

  pressApplyFilters(){
    let { page, selectedType, selectedCategory, selectedLanguage, rating, selectedSort, searchQuery, selectedCategories, selectedJurisdictions } = this.state
    this.setState({action: "nav", searchLoading:true, filtersApplied: true})
    this.requestWithFilters({ page: page, type: selectedType, category: selectedCategory, language: selectedLanguage, rating, name: searchQuery, sort: selectedSort, categories: selectedCategories, jurisdictions: selectedJurisdictions })
  }

  requestWithFilters(filterParams) {
    this.props.fetchContractsByFilter (
      filterParams,
      this.contractSearchLoadingFunction.bind(this)
    )
  }

  contractSearchLoadingFunction(url){
    let seperateParams = url.split('?')[1]

    if(this.state.action != "link") {
      this.props.history.push(`/contracts?${seperateParams}`)
    }
    setTimeout(() => {
      this.setState({searchLoading:false})
    }, 200);
  }

  changeStateValues(event) {
    const { name, value } = event.currentTarget;
    this.setState({[name]: value});
  }

  changeSortBy(event) {
    let { page, selectedType, selectedCategory, selectedLanguage, rating, selectedSort, searchQuery, selectedCategories, selectedJurisdictions } = this.state
    const { name, value } = event.currentTarget;
    this.setState({action: "nav", selectedSort: value, searchLoading: true})
    this.requestWithFilters({ page: page, type: selectedType, category: selectedCategory, language: selectedLanguage, name: searchQuery, sort: value, categories: selectedCategories, jurisdictions: selectedJurisdictions })
  }

  requestNewPageData(e, page) {
    e.preventDefault()
    let { selectedType, selectedCategory, selectedLanguage, rating, selectedSort, searchQuery, selectedCategories, selectedJurisdictions } = this.state
    this.setState({action: "nav", searchLoading: true, page: page})
    let filterParams = { page: page, type: selectedType, category: selectedCategory, language: selectedLanguage, name: searchQuery, sort: selectedSort, categories: selectedCategories, jurisdictions: selectedJurisdictions }
    this.props.fetchContractsByFilter(
      filterParams,
      this.contractSearchLoadingFunction.bind(this)
    )
  }

  changeStateMultiSelectedCategories(selectedItems) {
    let selectedIds = selectedItems.map(function(item) { return item.id })
    this.setState({action: "nav", selectedCategories: selectedIds})
  }

  changeStateMultiSelectedJurisdiction(selectedItems) {
    let selectedIds = selectedItems.map(function(item) { return item.id })
    this.setState({action: "nav", selectedJurisdictions: selectedIds})
  }

  render() {
    let { translate, filterContracts, topRatedContracts } = this.props
    let { data } = filterContracts

    return(
      <div>
        <title>{translate('contracts.app_title')} | WAL</title>
        <main className="main">
          <TopRatedContracts
            searchTopRatedLoading={this.state.searchTopRatedLoading}
            items={topRatedContracts.data}
            cover={"mg-top-180"}
          />
          <section className="wal-content mg-top-112 pd-rl">
            <div className="container-fluid">
              <div className="wal-content-head">
                <div className="row align-items-center">
                    <div className="col-md-12 col-lg-4">
                        <div className="wal-content-title">
                            <h1>{translate('contracts.templates_and_contracts')} <span>({data && data.length})</span></h1>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="wal-form wal-form-group wal-form-search">
                        <input
                          type="search"
                          name="Search contract"
                          placeholder={translate("contracts.search_contracts")}
                          className="wal-form-control"
                          value={this.state.searchQuery}
                          onChange={(event)=> this.searchInputFinishedWriting(event.target.value)}
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="none"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-4">
                      <SelectItem
                        field={{styles: "wal-form-sort", label: translate('contracts.sort_by'), name: "selectedSort", value: this.state.selectedSort, onChange: this.changeSortBy.bind(this), options: this.state.filterSorts}}
                      />
                    </div>
                </div>
            </div>
            <div className="wal-content-body">
                <div className="row">
                  <ContentForm
                    selectedFields={[
                      {label: translate('contracts.type'), name: "selectedType", value: this.state.selectedType, onChange: this.changeStateValues.bind(this), options: this.state.filterTypes},
                      // {label: translate('lawyers.categories'), name: "selectedCategory", value: this.state.selectedCategory, onChange: this.changeStateValues.bind(this), options: this.state.filterCategories},
                      {label: translate('lawyers.languages'), name: "selectedLanguage", value: this.state.selectedLanguage, onChange: this.changeStateValues.bind(this), options: this.state.filterLanguages}
                    ]}
                    rateObject={{rating: this.state.rating, changeRating: (value) => this.setState({rating: value})}}
                    translate={translate}
                    pressApplyFilters={this.pressApplyFilters.bind(this)}
                    resetFilterState={this.resetFilterState.bind(this)}
                    filtersApplied={this.state.filtersApplied}
                    multiSelect={this.state.filterSubCategories.length > 0 ? {items: this.state.filterSubCategories, onChange: this.changeStateMultiSelectedCategories.bind(this), currentSelected: this.state.selectedCategories} : null}
                    jurisdictionsMultiSelect={this.state.filterJurisdictions.length > 0 ? {items: this.state.filterJurisdictions, onChange: this.changeStateMultiSelectedJurisdiction.bind(this), currentSelected: this.state.selectedJurisdictions} : null}
                  />

                  <div className="col-md-12 col-lg-12 col-xl-8">
                    <div className="wal-results">
                      <ContractsSearched
                        translate={translate}
                        items={data || []}
                        searchLoading={this.state.searchLoading}
                      />
                    </div>
                    <PaginateItem
                      item={this.props.filterContracts}
                      requestNewPageData={this.requestNewPageData.bind(this)}
                      searchLoading={this.state.searchLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            allContracts: state.allContracts,
            filterContracts: state.contractsWithFilters,
            topRatedContracts: state.topRatedContracts
          })
}

export default connect(mapStateToProps, { fetchContracts, fetchContractsByFilter, fetchTopRatedContracts })(Contracts);
