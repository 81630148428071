import React
  from 'react';

import { requestResetPassword }
  from '../../actions/auth';

import { CustomTextInput }
  from '../../components/shared/forms';

import { connect }
  from 'react-redux';

import { SocialMediaButtons }
  from './';

import ReactLoading
  from 'react-loading';

class ForgotPasswodForm extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        email: "",
        success: null,
        errors: null,
        requestLogin: false
      };
  }

  resetRequest() {
    let { email } = this.state
    this.setState({requestLogin: true})
    this.props.requestResetPassword(email, this.stopLoading.bind(this), this.handleSuccess.bind(this), this.handleErrors.bind(this))
  }

  handleSuccess(data) {
    this.setState({success: data.data.message})
  }

  stopLoading(){
    this.setState({requestLogin: false})
  }

  handleChangeState(e) {
    let { errors } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({[name]: value})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  handleErrors(data) {
    if(data.errors) {
      this.setState({errors: data.errors, success: null})
    }
  }

  displayRequestButton() {
    let { requestLogin } = this.state
    let { translate } = this.props
    if(requestLogin) {
      return (
        <div style={{marginLeft: "43%"}}>
          <ReactLoading type="spin" color="#bea58b" height={'20%'} width={'20%'} />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="button button-primary"
          value={translate("settings.report_issue.send")}
          onClick={() => this.resetRequest()}
        />
      )
    }
  }

  render() {
    let { translate } = this.props
    let { success, errors } = this.state
    return(
      <div className={this.props.active == 2 ? "tab-pane fade show active" : "tab-pane fade"} id="m-login" role="tabpanel">
        <div className="login-form">
          <div style={{marginBottom: 50}}>
            <h1 style={{marginBottom: 20}}>{translate('auth.forgot_password_title')}</h1>
            <p>{translate('auth.forgot_password_desc')}</p>
          </div>
          { success && <div className="wal-form wal-form-group wal-form-error">
            <div className="error">
              <span style={{fontSize: 16, color: 'green'}}>{success}</span>
            </div>
          </div>}
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <CustomTextInput
                labelName={translate('auth.email_address')}
                name={'email'}
                value={this.state.email}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="login-button">
                {this.displayRequestButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({})
}

export default connect(mapStateToProps, { requestResetPassword })(ForgotPasswodForm);
