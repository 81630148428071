import React from 'react'

import {logout} from "../../actions/auth";

import {getConversations,selectConversation} from '../../actions/chat'

import {connect} from "react-redux";

import Image from "../../components/shared/Image";


import NotificationComponent from '../../components/profile/NotificationComponent'

import ChatNotification from '../chat/notification'

class CurrentUserDropdown extends React.Component {

    componentDidMount() {
      this.props.getConversations();
    }

    render() {
        let { translate, current_user} = this.props
        return (
          <>
          <div class="header-right">
            <div class="header-search click-search">
                <a><i class="icon-search"></i></a>
            </div>

            <ChatNotification {...this.props} />

            <div class="header-notifications">
              <div data-toggle="dropdown" data-display="static">
                <a>
                  <i class="icon-notification"></i>
                  <div class="notifications-number"></div>
                </a>
              </div>
              <div class="dropdown-menu header-notifications-menu">
                <div class="header-notifications-top">
                  <h3>{translate('header.notifications')}</h3>
                  <a href="/notifications">{translate('articles.details.view_all')}</a>
                </div>
                <div class="header-notifications-body">
                  <NotificationComponent translate={translate} />
                </div>
              </div>
            </div>
            <div class="header-profile">
              <div class="dropdown-profile header-profile-wrap">
                <div data-toggle="dropdown" class="header-profile-info" data-display="static">
                  <div class="header-profile-img">
                    <Image src={current_user && current_user.avatar} alt="header"/>
                  </div>
                  <div class="header-profile-name">
                    <h1>{current_user ? current_user.name : null}</h1>
                  </div>
                </div>
                <ul class="dropdown-menu">
                  <li><a href="/profile"><i class="icon-users"></i>{translate('header.profile')}</a></li>
                  <li><a href="/notifications"><i class="icon-notification"></i>{translate('header.notifications')}</a></li>
                  <li><a href="/settings"><i class="icon-settings"></i>{translate('header.settings')}</a></li>
                  <li><a href="#" onClick={() => this.props.logout()} class="log-out"><i class="icon-log-out"></i>{translate('header.sign_out')}</a></li>
                </ul>
              </div>
            </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
    return {
        current_user: state.currentUser,
        conversations: state.chat.conversations
    };
}

export default connect(mapStateToProps, {
    logout, getConversations, selectConversation
})(CurrentUserDropdown);
