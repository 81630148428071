import React from 'react';
import MessageActions from "./MessageActions";
import moment from "moment";


class Image extends React.Component {
    render() {
        return (
            <div class={this.props.self ? "messages-item self" : "messages-item"}>
                <div class="messages-item-wrap">
                    <div class="messages-image">
                        <img src={`data:image/png;base64,${this.props.body}`} alt="media"/>
                    </div>
                    <div className="messages-time">
                        <span>{moment(this.props.created_at).fromNow(true)}</span>
                    </div>
                    <MessageActions {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Image
