import api from "../api";

import {WEB_URL, ORDER_STATISTIC } from "../constants/types";

export function getHistoryOrderStatistic() {
  return async function (dispatch) {
    let url = `${WEB_URL}/orders-history-statistics`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: ORDER_STATISTIC,
          payload: data.data.data,
        });
      })
      .catch((err) => {});
  };
}
