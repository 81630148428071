import React
  from "react";

export const ErrorAlert = (props) => {
  return (
    <div class="wal-alert wal-alert-error">
      <div class="wal-alert-wrap">
        <div class="wal-alert-left">
          <div class="wal-alert-icon">
            <i class="icon-error-circle"></i>
          </div>
          <div class="wal-alert-message">
            <p>{props.translate("shared.error_label")}</p>
            <span>{props.message}</span>
          </div>
        </div>
        <div class="wal-alert-right">
          <div class="wal-alert-close">
            {/*<i class="icon-half-x"></i>*/}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ErrorAlert;
