import {
    LAWYER_BY_ID
  } from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case LAWYER_BY_ID:
      return action.payload
    default:
      return state
  }
}
