import React from "react";

import { Link } from "react-router-dom";

import HomePageUser from "./HomePageUser";

import LandingPage from "./LandingPage";

import { getToken }from "../../../helpers";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let { translate } = this.props;
    let contracts = this.props.allContracts && this.props.allContracts;
    return (
        getToken()
        ?
        <HomePageUser />
        :
        <LandingPage />
    );
  }
}


export default HomePage;
