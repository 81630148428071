import React from "react";

import ReactLoading
  from 'react-loading';

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      callsItems: [],
    };
  }

  displayButton() {
    let { loading, submitDisabled } = this.state
    let { email, translate } = this.props
    if(!loading) {
      return (
        <button
          class="button button-primary"
          disabled={email.trim().length < 1}
          onClick={this.props.onSubmit.bind(this)}>
          {translate("settings.invite.send_invitation")}
        </button>
      )
    } else {
      return (
        <div style={{ height: 60, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b" width={"10%"} />
        </div>
      )
    }
  }

  render() {
    return (
      <div class="col-md-6 col-lg-6 form-border">
        <div class="settings-invite-form">
          <div class="wal-form wal-form-group">
            <label>E-mail address</label>
            <input
              type="email"
              name="email"
              value={this.props.email}
              onChange={this.props.changeEmail.bind(this)}
              class="wal-form-control"
              placeholder={this.props.typeTranslate} />
          </div>
          <div class="wal-form wal-form-group">
            {this.displayButton()}
          </div>
        </div>
      </div>
    )
  }
}

export default EmailForm;
