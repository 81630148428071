import React from "react";

import Person from "../../assets/img/person-1.png";

import Image from '../../components/shared/Image'

export default class ContractsSmall extends React.Component {
  render() {
    return (
      <div className="col-md-12 col-lg-12 col-xl-6">
        <div className="contracts-item">
          <a href="#" className="contracts-item-wrap">
            <div className="contracts-left">
              <div className="contracts-img">
                <Image
                  src={this.props.imageSource}
                  alt="person"
                />
              </div>
            </div>
            <div className="contracts-center">
              <div className="contracts-content">
                <div className="contracts-icon">
                  <i className="icon-open-cases-docs"></i>
                </div>
                <div className="contracts-content-wrap">
                  <div className="contracts-title">
                    <h1>{this.props.title}</h1>
                  </div>
                  <div className="contracts-info">
                    <p>{this.props.type}</p>
                    <span>{this.props.createdAt}</span>
                  </div>
                </div>
              </div>
              <div className="contracts-value">
                <i className="icon-price-tag"></i>
                <span>{this.props.price}</span>
              </div>
            </div>
          </a>
          <div className="contracts-right">
            <a href="" className="contracts-purchase">
              <i className="icon-purchase"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
