import api from "../api";

import {
  WEB_URL,
  TOP_RATED_LAWYERS,
  LAWYERS_BY_REGION,
  LAWYERS_BY_FILTER,
  LAWYERS_FOR_MAP,
  LAWYER_BY_ID,
  FETCH_LAWYER_CONTRACTS,
  FETCH_LAWYER_ARTICLES,
  LAWYERS,
  FETCH_LAWYER_RECOMMENDED
} from "../constants/types";

import { getToken }
  from "../helpers";

export function fetchLawyers() {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-lawyers?`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: LAWYERS,
          payload: data.data,
        });
      })
      .catch((err) => {});
  };
}

export function fetchTopRatedLawyers(stopLoading=null) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-lawyers?top_rated=1`;

    api
      .get(url)
      .then((data) => {
        dispatch({
          type: TOP_RATED_LAWYERS,
          payload: data.data,
        });
        stopLoading();
      })
      .catch((err) => {});
  };
}

export function fetchLawyersByRegion(lat, long) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-lawyers?latitude=${lat}&longitude=${long}`;

    api
      .get(url)
      .then((data) => {
        dispatch({
          type: LAWYERS_BY_REGION,
          payload: data.data,
        });
      })
      .catch((err) => {});
  };
}

export function fetchLawyersByFilter(params = null, stopLoading=null) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-lawyers?`;

    if(params) {
      if(params.page && params.page != "" && params.page !== null) { url = url + `page=${params.page}&` }
      if(params.rating && params.rating != "") { url = url + `rating=${params.rating}&` }
      if(params.bar_verified && params.bar_verified != "") { url = url + `bar_verified=${params.bar_verified}&` }
      if(params.category && params.category != "" && params.category !== null) { url = url + `category=${params.category}&` }
      if(params.language && params.language != "" && params.language !== null) { url = url + `language=${params.language}&` }
      if(params.radius && params.radius != "" && params.radius != 30) { url = url + `radius=${params.radius}&` }
      if(params.name && params.name != "") { url = url + `name=${params.name}&`}
      if(params.sort && params.sort != "") {url = url + `sort=${params.sort}`}
      if(params.categories && params.categories.length > 0) {
        var categoryLink = ""
        params.categories.map((cat, index) => {
          categoryLink = categoryLink + `categories[${index}]=${cat}&`
        })
        url = url + categoryLink
      }
      if (params.jurisdictions && params.jurisdictions.length > 0) {
          var jurisdictions = ""
          params.jurisdictions.map((cat, index) => {
              jurisdictions = jurisdictions + `jurisdictions[${index}]=${cat}&`
          })
          url = url + jurisdictions
      }
    }

    api.get(url)
      .then((data) => {
        dispatch({
          type: LAWYERS_BY_FILTER,
          payload: data.data,
        });
        stopLoading(url)
      })
      .catch((err) => {console.log("err", err)});
  };
}

export function fetchLawyersForMap(lat, long, stopLoading=null) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-lawyers?latitude=${lat}&longitude=${long}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: LAWYERS_FOR_MAP,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function fetchLawyerById(id, stopLoading=null) {
  return async function (dispatch) {
    let url = `${WEB_URL}/lawyers/${id}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: LAWYER_BY_ID,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function lawyerContractsSearch(id, stopLoading, name, sort) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-contracts?name=${name || ""}&sort=${sort || 'created_at'}&lawyer=${id}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: FETCH_LAWYER_CONTRACTS,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function lawyersRecommended(stopLoading=null) {
  return async function (dispatch) {
    let url = `${WEB_URL}/lawyers/recommended`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: FETCH_LAWYER_RECOMMENDED,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}

export function lawyerArticlesSearch(id, stopLoading, name, sort) {
  return async function (dispatch) {
    let url = `${WEB_URL}/all-articles?title=${name || ""}&sort=${sort || 'created_at'}&lawyer=${id}`;
    api
      .get(url)
      .then((data) => {
        dispatch({
          type: FETCH_LAWYER_ARTICLES,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}
