import React from "react";

import Image
  from '../../components/shared/Image'

import { Link }
  from "react-router-dom";

class CourtsCases extends React.Component {
  render() {
    return (
      <section className="courts mg-top-80 pd-rl">
        <div className="container-fluid">
          <div className="main-title">
            <h1>Court</h1>
            <a href="court.html">view more</a>
          </div>
          <div className="row">
            <div className="col-md-8 col-lg-8 col-xl-10">
              <ul className="wal-group-tabs nav nav-tabs">
                <li className="nav-item">
                  <a className="nav-link active" href="#open-cases" data-toggle="tab" role="tab">Open cases</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#close-cases" data-toggle="tab" role="tab">Close cases</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-2">
              <div className="courts-group-add">
                <a href="" className="button button-primary"><i className="icon-plus"></i>Add new case</a>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="open-cases" role="tabpanel">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <h1>Test</h1>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="close-cases" role="tabpanel">
              <div className="row">
                <div className="col-mg-6 col-lg-6">
                  <h1>Test</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CourtsCases
