import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function reportItem(params, successResponse) {
  var form = new FormData();

  await form.append("title", "Report bug");
  await form.append("description", params.description);
  await form.append("entity_id", 1);
  await form.append("entity_type", "bug");

  if(params.avatar) {
    await form.append("file", params.avatar);
  } else {
    await form.append("file", null);
  }

  const config = {
    headers: {
      "enctype": "multipart/form-data",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    mimeType: "multipart/form-data",
    contentType: false
  };

  let url = `${WEB_URL}/reports`;

  api.post(url, form, config)
    .then((data) => {
      successResponse()
    })
    .catch((err) => {
    });
}
