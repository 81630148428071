import React, {Component} from 'react';

import Contract from "./Contract";
import Image from "./Image";
import Text from "./Text";
import Map from "./Map";

import moment from "moment";
import _ from 'lodash'

class Index extends Component {

    dateToFromNowDaily = (item) => {

        const fromNow = moment(item.created_at).fromNow();

        return moment(item.created_at).calendar(null, {
            // when the date is closer, specify custom values
            lastWeek: '[Last] dddd',
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            // when the date is further away, use from-now functionality
            sameElse: function () {
                return "[" + fromNow + "]";
            }
        });
    }

    getMessages = (messages) => {
        return messages.map((message, index) => {

            const self = message.participant_id == this.props.conversation.participant.id;

            switch (message.type) {
                case 'contract':
                    return <Contract key={message.id} {...message} self={self} {...this.props} />
                case 'image':
                    return <Image key={message.id} {...message} self={self} {...this.props} />
                case 'map':
                    return <Map key={message.id} {...message} self={self} {...this.props} />
                case "text":
                default:
                    return <Text key={message.id} {...message} self={self} {...this.props} />
            }
        });
    }

    render() {

        const messages = this.props.messages;

        const result = _.groupBy(messages, this.dateToFromNowDaily)

        let groupedMessages = [];

        for (let key in result) {
            groupedMessages.push({
                name: key,
                messages: result[key]
            })
        }

        return groupedMessages.map((group, index) => {
            return (
                <React.Fragment key={index}>
                    <div className="messages-line">
                        <div className="messages-line-wrap">
                            <span>{group.name}</span>
                        </div>
                    </div>
                    {
                        this.getMessages(group.messages)
                    }
                </React.Fragment>
            )
        })
    }
}

export default Index;
