import React from "react";

const NoResultsSearch = (props) => {
  return (
    <div class="wal-result-none-wrap">
    <div class="wal-result-none-icon">
        <i class="icon-search"></i>
    </div>
    <h1>{props.translate("articles.no_results_found")}</h1>
    <p>{props.translate("articles.empty_description")}</p>
    </div>
  );
};

export default NoResultsSearch;
