import React
  from 'react'
import ReactLoading
  from 'react-loading';

const DisplayRating = (props) => {
  let { stars, currentRate, changeMode, changeStateType, stateType } = props
  if(changeMode) {
    return (
      <div className="rated-stars">
        {stars.map((item, index)=>{
          return <i
              key={index}
              onClick={() => changeStateType(index+1)}
              className={index + 1 > stateType
                  ?  "icon-star"
                  : "icon-star-filled"}></i>
        })}
      </div>
    )
  } else {
    return (
      <div className="rated-stars">
        {stars.map((item, index)=>{
          return (
            <i key={index} className={index + 1 > Math.round(currentRate) ?  "icon-star" : "icon-star-filled"}></i>
          )
        })}
      </div>
    )
  }
}

export default DisplayRating
