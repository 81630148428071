import { CURRENT_USER_BOOKMARKS_ARTICLES }
  from '../constants/types.js';

export default function(state=[], action){
  switch(action.type){
    case CURRENT_USER_BOOKMARKS_ARTICLES:
      return action.payload
    default:
      return state
  }
}
