import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput }
  from '../../components/shared/forms';

import { MemberList, EmailForm }
  from '../../components/settings/invited';

import { fetchInvitedLawyers, inviteLawyer }
  from '../../helpers/invitedHelper';

import { SuccessAlert }
  from '../../components/settings/shared';

class InviteLawyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      errors: null,
      email: "",
      invitedLawyers: [],
      successUpdated: false
    };
  }

  componentDidMount() {
    fetchInvitedLawyers(this.fetchLawyers.bind(this))
  }

  fetchLawyers(data) {
    this.setState({loading: false, invitedLawyers: data.data})
  }

  onSubmitRequest() {
    let { email } = this.state
    this.setState({loading: true})
    inviteLawyer(email, this.successInviteLawyer.bind(this))
  }

  successInviteLawyer() {
    this.setState({email: "", successUpdated: true})
    fetchInvitedLawyers(this.fetchLawyers.bind(this))
    let _this = this
    setTimeout(function(){ _this.setState({successUpdated: false}) }, 3000);
  }

  render() {
    const { currentUser, translate } = this.props
    let { invitedLawyers, loading, successUpdated } = this.state

    return (
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="settings-content">
          <div class="settings-invite">
            <div class="accordion" id="invite-accordion">
              <div class="accordion-item">
                <div class="accordion-head">
                  <div class="accordion-head-wrap">
                    <h2>{translate("settings.invite.lawyer_title")}</h2>
                  </div>
                </div>
                <div class="accordion-body">
                  <div class="accordion-body-wrap">
                    <div class="row settings-invite-wrap">
                      <EmailForm
                        email={this.state.email}
                        changeEmail={(e) => this.setState({email: e.target.value})}
                        onSubmit={this.onSubmitRequest.bind(this)}
                        translate={translate}
                        typeTranslate={translate("settings.invite.type_lawyer")}
                      />
                      <div class="col-md-6 col-lg-6">
                        <div class="settings-invite-content">
                          <p>{translate("settings.invite.lawyer_description")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MemberList
                items={invitedLawyers}
                loading={loading}
                translate={translate}
                notFoundMessage={translate("settings.invite.not_found_lawyers_invited")}
              />
            </div>
          </div>
        </div>
        { successUpdated &&
          <SuccessAlert
            message={translate("settings.invite.lawyer_added_successfully")}
          />
        }
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(InviteLawyer);
