
import {
    LAWYERS_FOR_MAP
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case LAWYERS_FOR_MAP:
        return action.payload
      default:
        return state
    }
  }
