import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput }
  from '../../components/shared/forms';

class Imprit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      errors: null
    };
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, type } = this.state
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div class="col-md-12 col-lg-12 col-xl-8">
        <div class="settings-content">
          <div class="settings-page">
            <div class="settings-page-wrap">
              <h4>{translate("settings.imprit.title")}</h4>
              <ul>
                <li><span>Legal Tech GmbH</span></li>
                <li>Haldenstrasse 5</li>
                <li>6340 Baar</li>
                <li><a href="mailto:info@wal.world">info@wal.world</a></li>
              </ul>
              <h3>{translate("settings.imprit.disclaimer_title")}</h3>
              <p>{translate("settings.imprit.disclaimer_description")}</p>
              <h3>{translate("settings.imprit.datenschutz_title")}</h3>
              <p>{translate("settings.imprit.datenschutz_description")} </p>
              <h3>{translate("settings.imprit.google_analytics_title")}</h3>
              <p>{translate("settings.imprit.google_analytics_description")}
                <a href="https://www.google.com/analytics/terms/de.html">"https://www.google.com/analytics/terms/de.html"
                </a>
              </p>
              <h4>Technologie und Design</h4>
              <ul>
                <li><span>REFIVE GmbH</span></li>
                <li>Reichstrasse 15</li>
                <li>5412 Gebenstorf</li>
                <li>Tel: 056 223 55 50</li>
                <li>Mail: info@refive.ch</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {  })(Imprit);
