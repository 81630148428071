import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ReportMessageModal extends Component {

    state = {
        report_reason_id: "",
        description: ""
    }

    onReportReasonChange = (event) => {
        event.preventDefault();

        this.setState({
            report_reason_id: event.target.value
        })
    }

    onDescriptionChange = (event) => {
        event.preventDefault();

        this.setState({
            description: event.target.value
        })
    }

    onReport = (event) => {
        event.preventDefault();

        this.props.onSuccess(this.state)

        this.setState({
            report_reason_id: "",
            description: ""
        })
    }

    onCancel = (event) => {
        event.preventDefault()

        this.setState({
            report_reason_id: "",
            description: ""
        })

        this.props.onCancel()
    }

    render() {
        return (
            <div className={"modal fade modal-ge" + (this.props.open ? " show" : "")}
                 style={{display: (this.props.open ? "block" : "none")}}
                 id="report" data-backdrop="static"
                 tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-ge-wrap">
                            <div className="modal-ge-top">
                                <h1>{this.props.title}</h1>
                                <p>{this.props.description}</p>
                            </div>
                            <form onSubmit={this.onReport}>
                                <div className="modal-ge-body">
                                    <div className="wal-form wal-form-group">
                                        <label htmlFor="select">Reason</label>
                                        <div className="wal-form-sort-wrap">
                                            <select required onChange={this.onReportReasonChange}
                                                    className="wal-form-control"
                                                    value={this.state.report_reason_id}>
                                                <option></option>
                                                {
                                                    this.props.reasons.map(reason => <option key={reason.id}
                                                                                             value={reason.id}>{reason.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="wal-form wal-form-group">
                                        <label htmlFor="select">Tell us more (optional)</label>
                                        <textarea
                                            className="wal-form-control"
                                            name="more"
                                            onChange={this.onDescriptionChange}
                                            value={this.state.description}
                                            placeholder="Tell us more about your reason to report this lawyer"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="modal-ge-bottom modal-ge-bottom-buttons">
                                    <button style={{marginRight: 25}} onClick={this.onCancel}
                                            className="button button-white">Cancel
                                    </button>
                                    <button type='submit' className="button button-bl">Report</button>
                                </div>
                                <div className="modal-ge-close" data-dismiss="modal">
                                    <i onClick={this.onCancel} className="icon-x"></i>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ReportMessageModal.defaultProps = {
    reasons: []
}

ReportMessageModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    reasons: PropTypes.array.isRequired
};

export default ReportMessageModal;