import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function numberOfLawyers(catchResult) {
  let url = `${WEB_URL}/total-number-of-lawyers`;

  api.get(url)
    .then((data) => {
      // console.log(data.data)
      catchResult(data.data);
    })
    .catch((err) => {
    });
}
