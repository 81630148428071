import React, {Component} from 'react';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';

import ChatConversations from "../../../components/chat/ChatConversations";
import ChatMessageBox from "../../../components/chat/ChatMessageBox";
import * as chatActions from "../../../actions/chat";
import {purchasedContracts} from "../../../actions/contractsActions"

import ConfirmModal from "../../../components/chat/modals/ConfirmModal";
import ContractsModal from "../../../components/chat/modals/ContractsModal";
import ReportMessageModal from "../../../components/chat/modals/ReportMessageModal";

class Chat extends Component {
    state = {
        conversation: "",
    }

    componentDidMount() {
        const {conversation_id} = this.props.match.params;

        this.props.getConversations({conversation_id});

        this.props.getReportReasons();
    }

    onDeleteMessage = (event) => {
        event.preventDefault();

        this.props.deleteMessage(this.props.deleteData);
    }

    onConversationDelete = (event) => {
        event.preventDefault();

        this.props.deleteConversation(this.props.deleteData)
    }

    onReportMessage = (data) => {
        this.props.sendReport({
            ...this.props.reportData,
            ...data
        })
    }

    render() {
        return (
            <div>
                <title>Chat | Wal</title>
                <main className="main">
                    <section className="messages pd-rl">
                        <div className="messages-wrap">
                            <div className="container-fluid">
                                <div className="row">

                                    <ChatConversations {...this.props} />
                                    {
                                        this.props.conversation !== null &&
                                        <ChatMessageBox {...this.props} />
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <ConfirmModal
                    open={this.props.messageDeleteModal}
                    onCancel={this.props.closeMessageDeleteModal}
                    onSuccess={this.onDeleteMessage}

                    title={"Delete message?"}
                    description={"Are you sure you want to delete message? Lawyer can see that you deleted something."}
                />

                <ConfirmModal
                    open={this.props.conversationDeleteModal}
                    onCancel={this.props.closeConversationDeleteModal}
                    onSuccess={this.onConversationDelete}

                    title={"Delete chat?"}
                    description={"Are you sure you want to delete chat? Lawyer can see that you deleted something."}
                />

                <ContractsModal
                    open={this.props.contractsModal}
                    {...this.props}
                />

                <ReportMessageModal
                    open={this.props.reportModal}

                    onCancel={this.props.closeReportModal}
                    onSuccess={this.onReportMessage}

                    title={"Report Message"}
                    description={"Please fill form on why you are reporting this message, and our team will look into it and update you via notification."}
                    reasons={this.props.reportReasons}
                />

            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        translate: getTranslate(state.locale),
        ...state.chat,
        contracts: state.purchasedContracts
    })
}

export default connect(mapStateToProps, {...chatActions, purchasedContracts})(Chat);
