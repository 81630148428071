import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomSelect }
  from '../../../components/shared/forms';

import { fetchCoutries }
  from '../../../actions/selectedItemsActions';

import { ConfirmChangeType }
  from '../../../components/shared/modals/setting';

import Image
  from '../../../components/shared/Image';

import { capitalize }
  from '../../../services/manipulateWithString';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = {
      loading: true
    };
  }

  render() {
    const { currentUser } = this.props
    let { error, errors, type } = this.props
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div className="row">
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Company name</label>
                 <input type="text" name="companyname" className="wal-form-control" value="" placeholder="Type company name" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Branch</label>
                 <input type="text" name="branch" className="wal-form-control" value="" placeholder="Type company branch" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>First person first name</label>
                 <input type="text" name="firstname" className="wal-form-control" value="" placeholder="First person first name" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>First person last name</label>
                 <input type="text" name="lastname" className="wal-form-control" value="" placeholder="First person last name" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Company E-mail address</label>
                 <input type="email" name="email" className="wal-form-control" value="" placeholder="Type company e-mail address" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Number of employees</label>
                 <div className="wal-form-sort-wrap">
                   <select className="wal-form-control">
                       <option value="" disabled selected hidden>Select number of employees</option>
                       <option value="austria">1-10</option>
                       <option value="germany">10-50</option>
                    </select>
                 </div>
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Company address</label>
                 <input type="text" name="address" className="wal-form-control" value="" placeholder="Type company office address" />
             </div>
          </div>
          <div className="col-md-6 col-lg-6">
             <div className="wal-form wal-form-group">
                 <label>Company phone number</label>
                 <input type="text" name="phone" className="wal-form-control" value="" placeholder="+41 000 000 00 00" />
             </div>
          </div>
      </div>
    )
  }
};

export default CompanyForm;
