import React
    from 'react'

const CustomPasswordInput = (props) => {
  let { errors, name, typeInput, value, handleChange, placeholder, changeInputType, labelName, withInfo, translate } = props
  return (
    <div className={`wal-form wal-form-group ${errors && errors[name] && 'wal-form-error'}`}>
      <label>{labelName}</label>
      <div className="wal-form-password">
        <input
          type={typeInput}
          name={name}
          className="wal-form-control"
          value={value}
          onChange={handleChange}
          placeholder={placeholder ? placeholder : ""}
        />
        <div className="wal-form-info">
          <span onClick={changeInputType.bind(this)} toggle="#password-field" className={`toggle-password ${typeInput == 'text' ? 'pass-eye' : 'pass-eye-off'}`}></span>
          { withInfo && <span class="wal-form-warning"><i class="icon-warning-info"></i><div class="info-tooltip"><div class="info-tooltip-inner">{translate("auth.password_input_info")}</div></div></span>}
        </div>
      </div>
      { errors && errors[name] &&
        <div className="error">
          <span>{errors[name]}</span>
        </div>
      }
    </div>
  )
}

export default CustomPasswordInput
