import React from 'react';
import './App.css';

import {Provider} from 'react-redux';

import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";

import Home from './pages/index'

import store from './store';
import {addTranslation, initialize, addTranslationForLanguage} from 'react-localize-redux';
import en from './translations/en.json';
import de from './translations/de.json';
import tr from './translations/tr.json';
import es from './translations/es.json';

import {getUser, isAuth} from "./helpers";

import ReactEchoRedux from 'react-echo-redux'

import {socketConfig} from "./config/ws";

class App extends React.Component {
  UNSAFE_componentWillMount() {
    if (store) {
      const languages = ['en', 'de', 'tr', 'es'];
      let defaultLanguage = localStorage.getItem('language')
      store.dispatch(initialize(languages, {defaultLanguage: (defaultLanguage || 'en')}));
      store.dispatch(addTranslationForLanguage(this.returnLanguage(), defaultLanguage || 'en'));

      if (isAuth()) {
        const user = getUser();

        if (user.id) {
          ReactEchoRedux.init(store, socketConfig)

          ReactEchoRedux.private("chat.conversations." + user.id, {
            '.chat.conversations-update': 'WS_CONVERSATION_UPDATE',
            '.chat.conversations-deleted': 'WS_CONVERSATION_DELETE',
            '.chat.conversations-started': 'WS_CONVERSATION_CREATED'
          })
        }
      }
    }
  }

  returnLanguage() {
    var currentLanguage = en
    let defaultLanguage = localStorage.getItem('language')
    if(defaultLanguage == 'de') {
      currentLanguage = de
    } else if (defaultLanguage == 'tr') {
      currentLanguage = tr
    } else if (defaultLanguage == 'es') {
      currentLanguage = es
    }

    return currentLanguage
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Switch>
              <Route path="/" component={Home} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
