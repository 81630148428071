import React from "react";

import { connect } from "react-redux";

import Image from "../../../components/shared/Image";

import ReactLoading from 'react-loading';

import {  LeftSideLinks,
          EditProfile,
          OrderHistory,
          ChangePassword,
          Payments,
          Subscription,
          Imprit,
          ReportIssue,
          InviteFriend,
          InviteLawyer,
          TermsAndConditions,
          PrivacyPolicy
        } from "../../../components/settings";

import { getTranslate } from "react-localize-redux";

const COMPONENTS = [ EditProfile, ChangePassword, Payments, OrderHistory, Subscription,
                     Imprit, ReportIssue, InviteFriend, InviteLawyer, TermsAndConditions, PrivacyPolicy ]

const HASHES = ["#edit_profile", "#edit_password", "#payment", "#order_history", "#subscription", "#imprit", "#report_issue", "#invite_friend", "#invite_lawyer", "#terms_and_conditions", "#privacy_policy"]

class SettingScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      currentPage:0
    };
  }

  componentDidMount() {
    var location = this.props.location
    var page = 0
    if(location) {
      if(location.hash) {
        page = HASHES.indexOf(location.hash)
      }
    }
    this.newPageDisplay(page)
  }

  changePage(e, newPage) {
    e.preventDefault()
    this.newPageDisplay(newPage)
  }

  newPageDisplay(newPage) {
    this.setState({currentPage: newPage})
    this.props.history.replace({ hash: HASHES[newPage].split("#")[1] })
  }

  displayPages() {
    let { currentPage } = this.state
    let { translate } = this.props
    const TagName = COMPONENTS[currentPage];

    return (
      <TagName
        currentUser={this.props.current_user}
        newPageDisplay={this.newPageDisplay.bind(this)}
        history={this.props.history}
        location={this.props.location}
        translate={translate}
      />
    )
  }

  render() {
    let currentUser = this.props.current_user;
    let { translate } = this.props

    if(this.state.loading || !currentUser){
      return  (
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <main className="main">
        <title>{translate('header.settings')} | WAL</title>
        <section className="settings pd-rl">
          <div className="container-fluid">
            <div className="settings-top">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="settings-top-title">
                    <h1>{translate("settings.setting_title")}</h1>
                  </div>
                  <div className="settings-sidebar-icon">
                    <div className="settings-sidebar-icon-wrap">
                      <i className="icon-menu-alt"></i>
                      <span>{translate("settings.setting_menu")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <LeftSideLinks
                changePage={(e, newPage) => this.changePage(e, newPage)}
                currentPage={this.state.currentPage}
                translate={translate}
                currentUser={currentUser}
              />
              { this.displayPages() }
            </div>
          </div>
        </section>
        <div className="settings-sidebar-overlay"></div>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    current_user: state.currentUser,
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps, {})(SettingScreen);
