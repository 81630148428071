import api from "../api";

import { WEB_URL, NOTIFICAIONS_LIST } from "../constants/types";

export function fetchNotificaions(stopLoading, title) {
  return async function (dispatch) {
    let url = `${WEB_URL}/push_notifications?title=${title}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: NOTIFICAIONS_LIST,
          payload: data.data,
        });
        stopLoading()
      })
      .catch((err) => {});
  };
}
