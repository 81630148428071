import React
  from 'react';
import { CustomTextInput, RadioSelectInput, CustomSelect }
  from '../forms';
import { fetchCoutries, fetchInterests }
  from '../../../actions/selectedItemsActions';
import { connect }
  from 'react-redux';
import ReactLoading
  from 'react-loading';
import { capitalize }
  from '../../../services/manipulateWithString';
import { updateProfile }
  from '../../../actions/profile';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class UpdateSocialUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ganderOptions: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
      ],
      updateParam: {
        first_name: null,
        last_name: null,
        type: null,
        phone: '',
        address: '',
        nationality_id: null,
        gender: '',
        company_name: '',
        branch: '',
        employee_number: null
      },
      error: null,
      errors: null,
      requestLogin: false,
      passwordTypeInput: "password",
      passwordConfirmationTypeInput: "password"
    };
  }

  async componentDidMount() {
    await this.props.fetchCoutries()
    await this.props.fetchInterests()
  }

  async componentWillReceiveProps(newProps) {
    if(newProps.current_user) {
    let { name, email, phone, gender, address, user_type, company_name, branch, employee_number, nationality_id, nationality } = newProps.current_user
      await this.setState({
        updateParam: {
          _method: 'PUT',
          name: name,
          email,
          gender,
          type: user_type,
          company_name: company_name,
          branch: branch,
          employee_number: employee_number,
          nationality_id,
          nationality
        }
      })
    }
  }

  stopLoading(){
    this.setState({requestLogin: false})
    window.$('#login-with-social').modal('toggle');
    window.location.reload();
  }

  handleChangeState(e) {
    let { errors, updateParam } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({updateParam: {...updateParam, [name]: value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  selectType(type) {
    let {updateParam } = this.state;
    this.setState({updateParam: {...updateParam, type: type}})
  }

  handleChangeGender(selectedOption) {
    let { errors } = this.state
    this.setState({gender: selectedOption.value})

    if(errors) {
      var costum_errors = errors
      delete costum_errors["gender"]
      this.setState({errors: costum_errors})
    }
  }

  displayRequestButton() {
    let { requestLogin } = this.state
    let { translate } = this.props
    if(requestLogin) {
      return (
        <div style={{marginLeft: "43%"}}>
          <ReactLoading type="spin" color="#bea58b" height={'20%'} width={'20%'} />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="button button-primary"
          value="Finish"
          onClick={() => this.completeRegistration()}
        />
      )
    }
  }

  handleChangeCountry(selectedOption) {
    let { updateParam, errors } = this.state
    this.setState({updateParam: {...updateParam, nationality_id: selectedOption.value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors["nationality_id"]
      this.setState({errors: costum_errors})
    }
  }

  handleChangeInterests(selectedOptions) {
    let { updateParam, errors } = this.state
    let interestSelected = []

    if(selectedOptions) {
      selectedOptions.map((opt) => {
        interestSelected.push(opt.value)
      })
      this.setState({updateParam: {...updateParam, interests: interestSelected}})
    } else {
      this.setState({updateParam: {...updateParam, interests: []}})
    }

    if(errors) {
      var costum_errors = errors
      delete costum_errors["interests"]
      this.setState({errors: costum_errors})
    }
  }

  completeRegistration() {
    let { updateParam, avatar } = this.state
    this.setState({loading: true})
    this.props.updateProfile(updateParam, null, this.stopLoading.bind(this), this.handleErrors.bind(this))
  }

  handleErrors(data) {
    this.setState({errors: data.errors})
  }

  render() {
    const { current_user, translate } = this.props

    let { errors, updateParam } = this.state
    let { gender, nationality_id, nationality, company_name, type, email, phone, address, name, branch, employee_number } = this.state.updateParam
    let type_error = this.state.errors && this.state.errors.type && !this.state.requestParams.type

    return (
      <div class="modal fade modal-ge" id="login-with-social" data-backdrop="static" tabindex="-1" data-keyboard="false" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content login-wrap">
            <div class="modal-ge-top">
              <h1>{translate('home.update_social_account.title')}</h1>
              <p>{translate('home.update_social_account.description')}</p>
            </div>
            <div className="login-form">
              <div className="row">
                <RadioSelectInput
                  options={[{value: "user", label: translate('auth.private'), logo: "icon-users"},
                            {value: "company", label: translate('auth.company'), logo: "icon-companies"}]}
                  translate={translate}
                  typeError={type_error}
                  updateParam={updateParam}
                  selectType={this.selectType.bind(this)}
                  errors={errors}
                  mainObject={updateParam.type}
                  radioTitle={translate('auth.profile_type')}
                  textError={this.state.errors && this.state.errors.type}
                  selectName={"type"}
                />
                { updateParam.type === "company" &&
                  <>
                    <div className="col-md-12 col-lg-12">
                      <CustomTextInput
                        labelName={translate('auth.company_name')}
                        name={'company_name'}
                        value={company_name ? company_name : ""}
                        handleChange={this.handleChangeState.bind(this)}
                        errors={errors}
                        placeholder={translate('auth.company_placeholder')}
                      />
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <CustomTextInput
                            labelName={translate('auth.branch')}
                            name={'branch'}
                            value={branch}
                            handleChange={this.handleChangeState.bind(this)}
                            errors={errors}
                            placeholder={translate('auth.branch_placeholder')}
                          />
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <CustomTextInput
                            labelName={translate('auth.employee_number')}
                            name={'employee_number'}
                            type={"number"}
                            value={employee_number}
                            handleChange={this.handleChangeState.bind(this)}
                            errors={errors}
                            placeholder={translate('auth.employee_number_placeholder')}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.full_name')}
                    name={'name'}
                    value={name}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.address')}
                    name={'address'}
                    value={address || ''}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.address_placeholder')}
                  />
                </div>
                { gender &&
                  <div className="col-md-12 col-lg-12">
                    <CustomSelect
                      options={this.state.ganderOptions}
                      labelName={translate('auth.gender')}
                      name={'gender'}
                      errors={errors}
                      handleChange={this.handleChangeGender.bind(this)}
                      placeholder={"Select gender"}
                      value={{ value: gender, label: capitalize(gender) }}
                    />
                  </div>
                }
                <div className="col-md-12 col-lg-12">
                  <CustomSelect
                    options={this.props.countries}
                    labelName={translate('auth.countries')}
                    name={'nationality_id'}
                    errors={errors}
                    handleChange={this.handleChangeCountry.bind(this)}
                    placeholder={translate('auth.select_country')}
                    value={nationality ? {value: nationality.id, label: nationality.name} : null}
                  />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className={`wal-form wal-form-group ${errors && errors[name] && 'wal-form-error'}`}>
                        <label>{translate('auth.phone')}</label>
                        <PhoneInput
                          country={'ch'}
                          value={this.state.phone}
                          handleChange={this.handleChangeCountry.bind(this)}
                          onChange={phone => this.setState({updateParam: {...updateParam, phone: phone}})}
                          inputStyle={{width: '100%'}}
                          value={phone}
                          inputProps={{
                            name: 'phone'
                          }}
                        />
                        { errors && errors[name] &&
                          <div className="error">
                            <span>{errors[name]}</span>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="login-button">
                    {this.displayRequestButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
    countries: state.countries,
    interests: state.interests,
    current_user: state.currentUser
  })
}

export default connect(mapStateToProps, { updateProfile, fetchCoutries, fetchInterests })(UpdateSocialUserModal);
