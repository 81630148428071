import React
    from 'react';

import VisaWhite
  from '../../../../../assets/img/visa-white.svg'

import MasterCardWhite
  from '../../../../../assets/img/master-card-white.svg'

import { fetchContract }
  from "../../../../../actions/contractsActions";

import { getCards, purchaseEntry }
  from '../../../../../helpers/payments'

import { addSubscription }
  from '../../../../../helpers/subscription'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import ReactLoading
  from 'react-loading';

import { fetchCurrencies }
 from "../../../../../actions/currencyActions";

import { getCurrency }
 from '../../../../../services/storeCurrency'

import CURRENCIES
 from '../../../../../constants/currencies'

class PaymentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cards: [],
      currentCardSelected: null,
      payLoading: false
    };
  }

  componentDidMount() {
    this.props.fetchCurrencies()
    getCards(this.catchResult.bind(this))
  }

  catchResult(data) {
    this.setState({cards: data.data, loading: false})
  }

  goNext() {
    window.$('#payment-details').modal('toggle');
    setTimeout(function(){
      window.$('#payment-success').modal('toggle');
    }, 250);
  }

  goToCreateNewCard() {
    window.$('#payment-details').modal('toggle');
    setTimeout(function(){
      window.$('#payment-addCard').modal('toggle');
    }, 250);
  }

  onChangeRadioValue(id) {
    this.setState({currentCardSelected: id})
  }

  displayCards() {
    let { cards, loading } = this.state

    if(loading){
      return(
        <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b" height={'10%'} width={'10%'} />
        </div>
      )
    }

    return (
      cards.map((card) => {
        let { card_number, card_owner, exp_month, exp_year, id, type } = card
        return (
          <div class="payment-radio" onChange={() => this.onChangeRadioValue(id)}>
            <input type="radio" id={`${id}-chat`} value={id} class="radio" name="chat-payment-item" />
            <label for={`${id}-chat`}>
              <div class="payment-radio-icon">
                <img src={type = "Visa" ? VisaWhite : MasterCardWhite} />
              </div>
              <div class="payment-radio-content">
                <h3>{card_owner}</h3>
                <span class="card-number">**** **** **** {card_number}</span>
              </div>
            </label>
          </div>
        )
      })
    )
  }

  purchaseAction() {
    let { currentCardSelected } = this.state

    if(!currentCardSelected) {
      this.setState({error: "Not selected any card"})
    } else {
      this.setState({error: null, payLoading: true})
      purchaseEntry({cardId: currentCardSelected, entityType: "chat", entityId: this.props.item.id}, this.successCatchResult.bind(this), this.handleErrors.bind(this))
    }
  }

  successCatchResult(data) {
    this.props.changeStatus("success")
  }

  handleErrors(data) {
    this.setState({error: data.errors, loading: false})
  }

  displayPayButton() {
    let { translate } = this.props
    let { payLoading } = this.state
    if(payLoading){
      return(
        <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b" height={'10%'} width={'10%'} />
        </div>
      )
    }

    return (
      <button class="button button-primary" onClick={() => this.purchaseAction()}>
        <i class="icon-purchase"></i>{translate("payment.pay_now")}
      </button>
    )
  }

  displayHeaderInfo() {
    let { translate, item, currencies } = this.props
    let currentCurrency = CURRENCIES[getCurrency]

    if(currencies['contact_lawyer']) {
      return (
        <div class="payment-modal-info">
          <div class="payment-modal-info-wrap">
            <h2>Message with {item.name}</h2>
            <p>{translate("payment.chatting_a_lawyer")}</p>
          </div>
          <div class="payment-modal-info-price">
            <span>{currencies['contact_lawyer'][currentCurrency.name]}.- {currentCurrency.name.toUpperCase()}</span>
          </div>
        </div>
      )
    }
  }

  render() {
    let { translate } = this.props
    let { error } = this.state
    return (
      <div class="payment-modal-wrap modal-ge-wrap">
        <div class="modal-ge-top payment-modal-top">
          <h1>{translate("payment.title")}</h1>
        </div>
        <div class="modal-ge-body">
          <div class="payment-modal-title">
            <h3>{translate("payment.order_summery")}</h3>
          </div>
          {this.displayHeaderInfo()}
          <div class="payment-modal-title">
            <h3>{translate("payment.payment_method")}</h3>
          </div>
          {error && <p style={{marginBottom: 10, color: 'red'}}>{error}</p>}
          {this.displayCards()}
          <div class="payment-modal-add">
            <button class="button button-white" onClick={() => this.props.changeStatus("addCard")}><i class="icon-plus"></i>{translate("payment.add_new_payment_method")}</button>
          </div>
        </div>
        <div class="modal-ge-bottom">
          {this.displayPayButton()}
        </div>
        <div class="modal-ge-close" data-dismiss="modal"><i class="icon-x"></i></div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            current_user: state.currentUser,
            currencies: state.currencies
          })
}

export default connect(mapStateToProps, { fetchCurrencies, fetchContract })(PaymentDetails);
