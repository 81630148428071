import React
    from 'react';

import Person
    from '../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../components/auth'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      enter: false
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({active: newProps.active, enter: newProps.enter})
  }

  displayTabs() {
    let { translate } = this.props
    let { active } = this.state
    if(active == 0 || active == 1) {
      return (
        <div className="nav nav-tabs" role="tablist">
          <a className={this.state.active == 0 && "active"}
          onClick={()=> this.setState({active: 0})}
              data-toggle="tab" href="#m-login" role="tab" aria-selected="true">{translate('auth.login')}</a>
          <a className={this.state.active == 1 && "active"}
              onClick={()=> this.setState({active: 1})}
              data-toggle="tab" href="#m-register" role="tab" aria-selected="false">{translate('auth.register')}</a>
        </div>
      )
    } else if (active == 2) {
      return (
        <div className="nav nav-tabs" role="tablist">
          <a  onClick={()=> this.setState({active: 0})}>
            <i className="icon-back"></i>{translate('auth.forgot_password_back')}
          </a>
        </div>
      )
    }
  }

  closeModal() {
    this.setState({enter: false})
    window.$('#login').modal('toggle');
  }

  render() {
    let { translate } = this.props
    let { active, enter } = this.state
    return (
      <div className="modal fade login-modal show" data-backdrop="static" id="login" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="login-wrap">
              {this.displayTabs()}
              <div className="tab-content" id="nav-tabContent">
                { active == 1 &&
                  <RegisterForm
                    active={active}
                    translate={translate}
                  />
                }
                { active == 0 &&
                  <LoginForm
                    enter={enter}
                    active={active}
                    translate={translate}
                    changeActivePage={() => this.setState({active: 2})}
                  />
                }
                { active == 2 &&
                  <ForgotPasswodForm
                    active={active}
                    translate={translate}
                    changeActivePage={() => this.setState({active: 0})}
                  />
                }
              </div>
              <div className="login-close" onClick={this.closeModal.bind(this)}><i className="icon-x"></i></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(LoginModal);
