import React, {Component} from 'react';

class MessageActions extends Component {

    state = {
        copied: false
    }

    onCopy = (event) => {
        event.preventDefault()
        let textField = document.createElement('textarea')
        textField.innerText = this.props.body
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()


        const _this = this
        _this.setState({
            copied: true
        })
        setTimeout(() => {
            _this.setState({
                copied: false
            })
        }, 2000)
    }

    onDeleteMessage = (event) => {
        event.preventDefault();

        this.props.openMessageDeleteModal({
            conversation_id: this.props.conversation_id,
            message_id: this.props.id
        });
    }

    onReportClick = (type) => {
        this.props.openReportModal({
            entity_id: this.props.id,
            entity_type: 'chat_message',
            title: 'Chat Message Report'
        });
    }

    render() {

        if (this.props.status === 'deleted') {
            return null;
        }

        return (
            <div className="messages-item-action more-options">
                <div className="more-options-icon" data-toggle="dropdown" data-display="static" aria-expanded="true">
                    <a href="#"><i className="icon-options"></i></a>
                </div>
                <ul className="dropdown-menu more-options-menu">
                    {
                        this.props.type === 'text' &&
                        <li>
                            <a onClick={this.onCopy} href="#"><i
                                className="icon-copy"></i>{this.state.copied ? "Copied" : "Copy"}</a>
                        </li>
                    }

                    {
                        this.props.type === 'image' &&
                        <li>
                            <a onClick={() => this.onReportClick('image')} href="#"><i className="icon-image-report"></i>Report image</a>
                        </li>
                    }

                    {
                        this.props.type === 'contract' &&
                        <li>
                            <a onClick={() => this.onReportClick('file')} href="#"><i className="icon-contracts-report"></i>Report file</a>
                        </li>
                    }

                    {
                        this.props.self &&
                        <li>
                            <a href="#" className="delete delete-option" onClick={this.onDeleteMessage}><i
                                className="icon-delete"></i>Delete</a>
                        </li>
                    }
                </ul>
            </div>
        );
    }
}

export default MessageActions;