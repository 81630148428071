import React
  from "react";
import { Link }
  from "react-router-dom";
import Logo
  from '../../assets/img/logo-white.svg'
import { storeLanguage, getLanguage }
  from '../../services/storeLanguage';
import { getCurrency }
  from '../../services/storeCurrency'
import { changeLanguage }
  from '../../actions/languageAction'
import { getToken }
  from "../../helpers";
import { connect }
  from 'react-redux';
import { logout }
  from "../../actions/auth";
import ChangeCurrencyModal
  from "../../components/shared/modals/ChangeCurrencyModal";
import CURRENCIES
  from '../../constants/currencies'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  changeLanguage(lang) {
    storeLanguage(lang)
    this.props.changeLanguage(this.state.languageValue)
  }

  displayAuthLink() {
    let { translate } = this.props
    if(getToken()) {
      return (
        <div className="footer-register">
          <a onClick={()=> this.props.logout()}>{translate('header.sign_out')}</a>
        </div>
      )
    } else {
      return (
        <div className="footer-register">
          <p>{translate('footer.register_as')}:</p>
          <a href=""
            onClick={()=> this.props.modalClick(1)}
            data-toggle="modal" data-target="#login">{translate('footer.enduser')}</a>
          {/*<a href="">{translate('footer.lawyer')}</a>*/}
        </div>
      )
    }
  }

  render() {
    let { translate } = this.props
    return (
      <footer className="footer mg-top-145 pd-rl">
        <div className="container-fluid">
            <div className="footer-top">
                <div className="row">
                    <div className="col-md-12 col-lg-3 col-xl-4">
                        <div className="footer-info">
                            <img src={Logo} alt="logo" />
                            <p>{translate('footer.title')}</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-3">
                      <div className="footer-links">
                        <span>{translate('footer.links.title')}</span>
                        <ul>
                          <li><a href="https://www.wal.world/imprint/" target="_blank">{translate('footer.links.imprint')}</a></li>
                          <li><a href="https://www.wal.world/privacy-policy/" target="_blank">{translate('footer.links.privacy_policy')}</a></li>
                          <li><a href="https://www.wal.world/faqs/" target="_blank">{translate('footer.links.faq')}</a></li>
                          <li><a href="https://www.wal.world/terms-and-conditions/" target="_blank">{translate('footer.links.terms_and_conditions')}</a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-3">
                      <div className="footer-links">
                        <span>{translate('footer.navigation.title')}</span>
                        <ul>
                          <li>
                            <Link to={`/`}>
                              {translate('header.tabs.explore')}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/lawyers`}>
                              {translate('header.tabs.lawyers')}
                            </Link>
                          </li>
                          <li>
                            <Link to={`/contracts`}>
                              {translate('header.tabs.contracts')}
                            </Link>
                          </li>
                          <li>
                             <a>{translate('header.tabs.knowledge')}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-2">
                      <div className="footer-links">
                        <span>{translate('footer.downloads.title')}</span>
                        <ul className="footer-downloads">
                          <a href="#" className="button button-transparent button-transparent-border"><i className="icon-appstore"></i>{translate('footer.downloads.app_store')}</a>
                          <a href="#" className="button button-transparent button-transparent-border"><i className="icon-playstore"></i>{translate('footer.downloads.play_store')}</a>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
              <div class="row">
                <div class="col-md-12 col-lg-3 col-xl-3 footer-order">
                  <div class="footer-copyright">
                    <p>{translate('footer.copyright')}</p>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 col-xl-4">
                  {this.displayAuthLink()}
              </div>
                <div class="col-md-12 col-lg-5 col-xl-5">
                  <div class="footer-bottom-right">
                    <div className="footer-language">
                      <ul>
                        <li className={getLanguage == "en" ? `active` : ''}>
                          <a href="" onClick={() => this.changeLanguage("en")}>En</a>
                        </li>
                        <li className={getLanguage == "de" ? `active` : ''}>
                          <a href="" onClick={() => this.changeLanguage("de")}>De</a>
                        </li>
                        <li className={getLanguage == "tr" ? `active` : ''}>
                          <a href="" onClick={() => this.changeLanguage("tr")}>Tr</a>
                        </li>
                        <li className={getLanguage == "es" ? `active` : ''}>
                          <a href="" onClick={() => this.changeLanguage("es")}>Es</a>
                        </li>
                      </ul>
                    </div>
                    <div class="footer-currency">
                      <a href="#" data-toggle="modal" data-target="#change-currency">
                        <img src={CURRENCIES[getCurrency].image}/>
                        <span>{CURRENCIES[getCurrency].name}</span>
                        <div class="dot"></div>
                      </a>
                    </div>
                    <div class="footer-website">
                      <p><a href="#">Website by apper</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <ChangeCurrencyModal/>
      </footer>
    )
  }
}

export default connect(null, { logout, changeLanguage })(Footer);
