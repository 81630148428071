import React
  from 'react';

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

import Background
  from '../../../assets/img/court-bg.jpg';

import ContractComment
  from './ContractComment';

import Person
  from '../../../assets/img/person-7.png';

import Velocity
  from '../../../assets/img/logo-black.svg';

import RightContentSide
  from './RightContentSide'

import ContractContentComment
  from './ContractContentComment'

import DisplayRating
  from '../../../components/shared/DisplayRating'

import ReactHtmlParser
  from 'react-html-parser'

class ContentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      professionality: props.contract.review.professionality,
      quality: props.contract.review.quality,
      usability: props.contract.review.usability,
      understandability: props.contract.review.understandability
    };
  }

  render() {
    let { contract, translate } = this.props

    return(
      <section className="profile-content mg-top-40 pd-rl">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-8">
              <div className="profile-article">
                <h3>{translate('contracts.details.description')}</h3>
                <p>{ReactHtmlParser(contract.description)}</p>
              </div>
              <div className="main-title">
                <h2>{translate('contracts.details.reviews')}</h2>
                <a href="">view all</a>
              </div>
              <div className="profile-reviews">
                <div className="profile-reviews-item">
                  <span>{translate('contracts.details.professionality')}</span>
                  <DisplayRating
                    stars={[0,1,2,3,4]}
                    stateType={this.state.professionality}
                    changeStateType={(index) => this.setState({professionality: index})}
                    changeMode
                  />
                </div>
                <div className="profile-reviews-item">
                  <span>{translate('contracts.details.quality')}</span>
                  <DisplayRating
                    stars={[0,1,2,3,4]}
                    stateType={this.state.quality}
                    changeStateType={(index) => this.setState({quality: index})}
                    changeMode
                  />
                </div>
                <div className="profile-reviews-item">
                  <span>{translate('contracts.details.usability')}</span>
                  <DisplayRating
                    stars={[0,1,2,3,4]}
                    stateType={this.state.usability}
                    changeStateType={(index) => this.setState({usability: index})}
                    changeMode
                  />
                </div>
                <div className="profile-reviews-item">
                  <span>{translate('contracts.details.understandability')}</span>
                  <DisplayRating
                    stars={[0,1,2,3,4]}
                    stateType={this.state.understandability}
                    changeStateType={(index) => this.setState({understandability: index})}
                    changeMode
                  />
                </div>
              </div>
              <ContractContentComment
                contractId={contract.id}
                translate={translate}
                reviewState={this.state}
              />
            </div>
            <RightContentSide
              lawyer={contract.lawyer}
              company={contract.company}
              translate={translate}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default ContentSection
