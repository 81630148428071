import React from 'react';

import Velocity
  from '../../../assets/img/logo-black.svg';

import Person
  from '../../../assets/img/person-7.png';

import Background
  from '../../../assets/img/court-bg.jpg';

import { HeaderSection, ContentSection }
  from '../../../components/contracts/showPage';

import { Modal }
  from '../../../components/shared/modals/payment';

import PdfReaderModal
  from '../../../components/shared/modals/PdfReaderModal';

import { fetchContract }
  from "../../../actions/contractsActions";

import { connect }
  from 'react-redux';

import { getTranslate }
  from 'react-localize-redux';

import ReactLoading
  from 'react-loading';

class ContractsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.props.fetchContract(this.props.match.params.id, this.stopLoading.bind(this))
  }

  stopLoading() {
    this.setState({loading: false})
  }

  render() {
    let { data } = this.props.contract

    if(this.state.loading){
      return(
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }
    return (
      <div>
        <title>Contracts | WAL</title>
        <main className="main">
          <HeaderSection
            history={this.props.history}
            contract={data}
            translate={this.props.translate}
          />
          <ContentSection
            contract={data}
            translate={this.props.translate}
          />
        </main>
        <Modal
          item={data}
        />
        <PdfReaderModal
          contract={data}
        />
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            contract: state.contract,
          })
}

export default connect(mapStateToProps, { fetchContract })(ContractsDetails);
