import React from 'react'
import { Category1, Category2, Category3, Category4, Category5, Category6, Category7, Category8, Category9, Category10, Category11, Category12, Category13, Category58 }
  from '../../assets/img/icons/categories'

const SvgImage = (props) => {
  const svgs = { "1": Category1, "2": Category2, "3": Category3 ,"4": Category4, "5": Category5, "6": Category6, "7": Category7, "8": Category8, "9": Category9,
                 "10": Category10, "11": Category11, "12": Category12, "13": Category13, "58": Category58 }
  return(
      <span><img src={svgs[props.index]} width="20px" alt="person"/></span>
  )
}

export default SvgImage
