import React from "react";
import { logout } from "../../actions/auth";
import {connect} from "react-redux";

class LeftSideLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      tabs: [ {index: 0, title: props.translate("settings.left_menu.edit_profile"), icon: "icon-users"},
              {index: 1, title: props.translate("settings.left_menu.change_password"), icon: "icon-lock"},
              {index: 2, title: props.translate("settings.left_menu.payments"), icon: "icon-creditcard-cc"},
              {index: 3, title: props.translate("settings.left_menu.order_history"), icon: "icon-order-history"}],
     subscriptionTab: {index: 4, title: props.translate("settings.left_menu.subscription"), icon: "icon-subscribtion"},
     tabsWithoutIcon: [
              {index: 5, title: props.translate("settings.left_menu.imprint") },
              {index: 6, title: props.translate("settings.left_menu.report_issue") },
              {index: 7, title: props.translate("settings.left_menu.invite_friend") },
              {index: 8, title: props.translate("settings.left_menu.invite_lawyer") },
              {index: 9, title: props.translate("settings.left_menu.terms_and_conditions") },
              {index: 10, title: props.translate("settings.left_menu.privacy_policy") },
            ]
    };
  }

  displayTabs() {
    let { tabs, subscriptionTab } = this.state
    let { changePage, currentPage, currentUser } = this.props

    let totalTabs = currentUser.user_type == "company" ? [...tabs, subscriptionTab] : tabs

    return (
      totalTabs.map((tab) => {
        return (
          <li className={`${currentPage == tab.index ? 'active' : ''}`}>
            <a href="#" onClick={(e) => changePage(e, tab.index)} className="settings-menu-item">
              <div>
                <i className={`${tab.icon}`}></i>
                <span>{tab.title}</span>
              </div>
              <i className="icon-next"></i>
            </a>
          </li>
        )
      })
    )
  }

  displayTabsTithoutIcons() {
    let { tabsWithoutIcon } = this.state
    let { changePage, currentPage } = this.props

    return (
      tabsWithoutIcon.map((tabWithoutIcon) => {
        return (
          <li className={`${currentPage == tabWithoutIcon.index ? 'active' : ''}`}>
            <a href="#" onClick={(e) => changePage(e, tabWithoutIcon.index)}>
              <span>{tabWithoutIcon.title}</span>
              <i className="icon-next"></i>
            </a>
          </li>
        )
      })
    )
  }

  render() {
    let { translate } = this.props

    return (
      <div className="col-md-12 col-lg-12 col-xl-4">
        <div className="settings-sidebar">
          <div className="settings-menu">
            <ul>
              {this.displayTabs()}
            </ul>
            <ul>
              {this.displayTabsTithoutIcons()}
            </ul>
          </div>
          <div className="settings-logout">
            <button className="button button-danger" onClick={() => this.props.logout()}><i className="icon-log-out"></i>{translate("settings.left_menu.logout")}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, {
    logout
})(LeftSideLinks);
