import {applyMiddleware, compose, createStore} from 'redux';

import reducers from '../reducers';

import reduxThunk from 'redux-thunk'

// const createStoreWithMiddleware = compose(applyMiddleware(reduxThunk))(createStore);
// const store = createStoreWithMiddleware(reducers);

const enhancers = [
  applyMiddleware(reduxThunk),
];

const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false }) : compose;

const store = createStore(
    reducers,
    composeEnhancers(...enhancers),
);

export default store;
