import React, {Component} from 'react';

import ConversationSearchInput from "./inputs/ConversationSearchInput";
import ConversationRow from "./conversation/ConversationRow";

import ReactLoading from 'react-loading';
import EmptyConversation from "./conversation/EmptyConversation";

class ChatConversations extends Component {

    render() {

        return (
            <div className="col-md-12 col-lg-4">
                <div className="messages-sidebar">
                    <div className="messages-sidebar-header">

                        <ConversationSearchInput {...this.props}/>

                        <div className="messages-blocked">
                            <div className="messages-blocked-title">
                                <i className="icon-error-circle"></i>
                                <span>Blocked chats</span>
                            </div>
                            <a href="messages-blocked.html">
                                <i className="icon-next"></i>
                            </a>
                        </div>
                    </div>
                    <div className="messages-sidebar-body">
                        {
                            this.props.conversationLoading &&
                            <div style={{
                                display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40
                            }}>
                                <ReactLoading type="spin" color="#bea58b"/>
                            </div>
                        }
                        <ul className="messages-list">
                            {
                                !this.props.conversationLoading &&
                                this.props.conversations.map(item =>
                                    <ConversationRow key={item.id} item={item} {...this.props} />
                                )
                            }

                            {
                                this.props.noConversations && <EmptyConversation/>
                            }

                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatConversations;