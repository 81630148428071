import React
    from 'react';

import Person
    from '../../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../../components/auth'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux';

import { getToken }
  from "../../../../helpers";

import { WEB_URL }
  from "../../../../constants/types";

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  reloadPage() {
    let { subscription } = this.props
    window.location.reload()
  }

  async requestToDownloadContract(e) {
    let { item } = this.props
    e.preventDefault();
    window.open(`${WEB_URL}/contracts/${item.id}/download?token=${getToken()}`);

    setTimeout(function(){
      window.open(`${WEB_URL}/contracts/${item.id}/download-doc?token=${getToken()}`);
    }, 1000);
  }

  render() {
    let { item, subscription, translate } = this.props

    return (
      <div class="payment-modal-wrap modal-ge-wrap">
        <div class="modal-ge-content">
          <div class="modal-ge-icon">
            <i class="icon-done-check"></i>
          </div>
          <h1>{translate("payment.success")}</h1>
          { !subscription ?
            <>
              <p>{translate("payment.bought_contract")}<br />{translate("payment.wish_to_proceed")}</p>
              <a onClick={this.requestToDownloadContract.bind(this)} download class="button button-bl"><i class="icon-download"></i>{translate("payment.download_and_use_contract")}</a>
            </>
            :
            <p>{translate("payment.subscribe_success")} {this.props.plan && this.props.plan.name}</p>
          }
        </div>
        <div className="modal-ge-close" data-dismiss="modal" onClick={() => this.reloadPage()}><i className="icon-x"></i></div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale)
          })
}

export default connect(mapStateToProps, {})(Success);
