import {
  CURRENCY
} from '../constants/types';

export default function(state=[], action){
  switch(action.type){
    case CURRENCY:
      return action.payload
    default:
      return state
  }
}
