import React
  from 'react';

import { login }
  from '../../actions/auth';

import { CustomTextInput, CustomPasswordInput }
  from '../../components/shared/forms';

import { connect }
  from 'react-redux';

import { SocialMediaButtons }
  from './';

import ReactLoading
  from 'react-loading';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        email: "",
        password: "",
        error: null,
        errors: null,
        requestLogin: false,
        passwordTypeInput: "password",
      };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.enterFunction.bind(this), false);
  }

  componentWillReceiveProps(newProps) {
    this.setState({active: newProps.active})
  }

  enterFunction(event) {
    if(event.code === "Enter" && this.props.enter) {
      this.loginRequest()
    }
  }

  loginRequest() {
    let { email, password } = this.state
    this.setState({requestLogin: true})
    this.props.login(email, password, this.stopLoading.bind(this), this.handleErrors.bind(this))
  }

  stopLoading(){
    this.setState({requestLogin: false})
  }

  handleChangeState(e) {
    let { errors } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({[name]: value})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  checkErrors(name) {
    let { errors } = this.state
  }

  handleErrors(data) {
    if(data.errors) {
      this.setState({errors: data.errors, error: null})
    } else if(data.error) {
      this.setState({error: data.error, errors: null})
    }
  }

  displayRequestButton() {
    let { translate } = this.props
    let { requestLogin } = this.state
    if(requestLogin) {
      return (
        <div style={{marginLeft: "43%"}}>
          <ReactLoading type="spin" color="#bea58b" height={'20%'} width={'20%'} />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="button button-primary"
          value={translate('auth.login')}
          onClick={() => this.loginRequest()}
        />
      )
    }
  }

  render() {
    let { translate } = this.props
    let { error, errors } = this.state
    return(
      <div className={this.props.active == 0 ? "tab-pane fade show active" : "tab-pane fade"} id="m-login" role="tabpanel">
        <SocialMediaButtons
          translate={translate}
          facebookTextButton={translate('auth.login_with_fb')}
          googleTextButton={translate('auth.login_with_google')}
          startLoading={() => this.setState({requestLogin: true})}
          stopLoading={() => this.stopLoading()}
        />
        <div className="login-form">
          { error && <div className="wal-form wal-form-group wal-form-error">
            <div className="error">
              <span>{error}</span>
            </div>
          </div>}
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <CustomTextInput
                labelName={translate('auth.email_address')}
                name={'email'}
                value={this.state.email}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomPasswordInput
                labelName={translate('auth.password')}
                name={'password'}
                value={this.state.password}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
                typeInput={this.state.passwordTypeInput}
                changeInputType={() => this.setState({passwordTypeInput: this.state.passwordTypeInput == "text" ? "password" : "text"})}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="wal-form login-remember">
                <div className="wal-form-checkbox">
                  <input type="checkbox" id="remember" value="Remember me" className="checkbox"/>
                  <label for="remember">{translate('auth.remember_me')}</label>
                </div>
                <div className="login-forgot">
                  <a href="#" onClick={() => this.props.changeActivePage()}>{translate('auth.forgot_password')}</a>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="login-button">
                {this.displayRequestButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({})
}

export default connect(mapStateToProps, { login })(LoginForm);
