import React from "react";

import ContractsSearched from "../../components/contracts/ContractsSearched";

const AboutMeSection = (props) => {
  return <div class="tab-pane" id="contracts">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4 class="panel-title nav-list">
        <a
          data-toggle="collapse"
          data-target="#collapse3"
          aria-expanded="false"
          data-parent="#tabs"
        >
          {props.translate('profile.contracts')}
        </a>
      </h4>
    </div>
    <div
      id="collapse3"
      class="panel-collapse collapse"
      data-parent="#tabs"
    >
      <div class="panel-body nav-content">
        {props.contracts ? (
          <>
            <ContractsSearched
              items={props.contracts}
            />
            {props.userContractsLastPage > props.contractsPage &&
              <a onClick={props.userMoreContracts} class="more-button button button-gray">
                {props.translate('profile.load_more_contracts')}
              </a>
            }
          </>
        ) : (
          <div class="profile-none-content">
            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6">
                <div class="profile-none-content-wrap">
                  <div class="profile-none-content-icon">
                    <i class="icon-contracts"></i>
                  </div>
                  <h1>{props.translate('profile.have_no_contracts_purchased')}</h1>
                  <p>
                    {props.translate('profile.purchased_info')}
                  </p>
                  <a
                    href="contracts.html"
                    class="button button-white"
                  >
                    {props.translate('profile.go_to_contract_and_templates')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  </div>
};

export default AboutMeSection;
