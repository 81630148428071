import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomSelect }
  from '../../../components/shared/forms';

import { addCard }
  from '../../../helpers/payments'

import ReactLoading
  from 'react-loading';

import { getTranslate }
  from "react-localize-redux";

class CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      card_number: "",
      expire: "",
      cvc: "",
      card_owner: "",
      errors: null
    };
  }

  handleExpireChange(text) {
    let textTemp = text;
    if (textTemp.length == 2) {
      textTemp += '/';
    } else {
      textTemp = text;
    }
    this.setState({expire: textTemp})
  }

  applyNewCard() {
    let { card_number, expire, cvc, card_owner } = this.state
    this.setState({loading: true})
    addCard({ card_number, expire, cvc, card_owner }, this.successCatchResult.bind(this), this.handleErrors.bind(this))
  }

  successCatchResult(data) {
    this.setState({loading: false})
    this.props.triggerFormMode("success")
  }

  handleErrors(data) {
    this.setState({errors: data.errors, loading: false})
  }

  displayApplyButton() {
    const { triggerFormMode, translate } = this.props
    if(!this.state.loading) {
      return (
        <>
          <button className="button button-white" onClick={() => triggerFormMode("new")}>
            {translate("payment.cancel")}
          </button>
          <button className="button button-bl" onClick={() => this.applyNewCard()}>
            <i className="icon-plus"></i>{translate("payment.add_card")}
          </button>
        </>
      )
    } else {
      return (
        <div style={{ height: 50, width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 10}}>
          <ReactLoading type="spin" color="#bea58b" width="10%" height="10%" />
        </div>
      )
    }
  }

  render() {
    const { currentUser, triggerFormMode } = this.props
    let { card_number, expire, cvc, card_owner, errors } = this.state
    let { translate } = this.props

    return (
      <div className="settings-payments-form">
        <div className="settings-payments-form-wrap">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="settings-payments-form-input">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className={`wal-form wal-form-group payment-form-group ${errors && errors.card_number && 'wal-form-error'}`}>
                      { errors && errors.card_number && <div class="error"><span>{errors.card_number}</span></div> }
                      <input
                        type="text"
                        name="card_number"
                        className="wal-form-control"
                        placeholder={translate("settings.payments.type_card_number")}
                        value={card_number}
                        maxlength="16"
                        onChange={(e) => this.setState({card_number: e.target.value})}
                        />
                      <div className="payment-form-icon">
                        <i className="icon-creditcard-cc"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <div className={`wal-form wal-form-group ${errors && errors.card_owner && 'wal-form-error'}`}>
                      <label>{translate("settings.payments.card_holder")}</label>
                      <input
                        type="text"
                        name="card_owner"
                        className="wal-form-control"
                        placeholder={translate("settings.payments.type_full_name")}
                        value={card_owner}
                        onChange={(e) => this.setState({card_owner: e.target.value})}
                        />
                      { errors && errors.cvc && <div class="error"><span>{errors.card_owner}</span></div> }
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className={`wal-form wal-form-group payment-form-group ${errors && (errors.expiration_month || errors.expiration_year) && 'wal-form-error'}`}>
                      { errors && (errors.expiration_month || errors.expiration_year) && <div class="error"><span>{errors.expiration_month || errors.expiration_year}</span></div> }
                      <input
                        type="text"
                        name="expire"
                        className="wal-form-control"
                        placeholder="MM/YY"
                        onChange={(e) => this.handleExpireChange(e.target.value)}
                        value={expire}
                        maxLength={5} />
                      <div className="payment-form-icon">
                        <i className="icon-calendar"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className={`wal-form wal-form-group payment-form-group ${errors && errors.cvc && 'wal-form-error'}`}>
                      { errors && errors.cvc && <div class="error"><span>{errors.cvc}</span></div> }
                      <input
                        type="text"
                        name="cvc"
                        className="wal-form-control"
                        placeholder="CVV"
                        maxlength="4"
                        value={cvc}
                        onChange={(e) => this.setState({cvc: e.target.value})}
                        />
                      <div className="payment-form-icon">
                        <i className="icon-card-cvv"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="credit-card">
                <div className="credit-card-head">
                  <i className="icon-visa-cc"></i>
                  <div className="credit-card-cvv">
                    <span>CVV</span>
                    <p>***</p>
                  </div>
                </div>
                <div className="credit-card-body">
                  <div className="credit-card-body-item">
                    <span>{translate("settings.payments.account_number")}</span>
                    <p>**** **** **** 2472</p>
                  </div>
                  <div className="credit-card-body-item">
                    <span>{translate("settings.payments.card_holder")}</span>
                    <p>Edward Lindgren</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6">
              <div className="credit-card-info">
                <p>{translate("settings.payments.adding_card_description")}</p>
              </div>
              <div className="credit-card-buttons">
                {this.displayApplyButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps, {})(CardForm);
