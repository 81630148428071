import React
    from 'react';

import { searchOnHome }
  from '../../../../actions/searchOnHome';

import { connect }
  from 'react-redux';

import Image
  from "../../../../components/shared/Image";

import { Link }
  from "react-router-dom";

import ReactLoading
  from 'react-loading';

import NoResultsSearch
  from '../../NoResultsSearch';

import { SearchedItemCard, Tabs }
  from './index';

import { getTranslate }
  from 'react-localize-redux';

class SearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery:'',
      activeTab: 'all',
      loading:false,
      typing: false,
      typingTimeout: 0
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let _this = this
    setTimeout(function() {
      _this.searchInput.focus()
    }, 500);
  }

  renderSuggestion(){
    let suggestions=["Roman Fritschi", "Korky Bucek", "Yong Tonghyon", "Construction agreement"]
    return (
      <div class="search-suggestion">
        <ul>
          {suggestions.map((item,index)=>{
            return <li><a onClick={()=> this.clickSuggestion(item)}>{item}</a></li>
          })}
        </ul>
      </div>
    )
  }

  clickSuggestion(item){
    this.setState({searchQuery:item}, ()=>{
      this.props.searchOnHome(this.state.searchQuery, this.stopLoading.bind(this))
    })
  }

  stopLoading(){
    this.setState({loading:false})
  }

  renderSwitch(param, activeTab) {
    switch(activeTab) {
      case 'lawyers':
        return param.lawyers.length;
      case 'contracts':
        return param.contracts.length;
      case 'articles':
        return param.articles.length
      default:
        return '0';
    }
  }

  changeActiveTab(value) {
    this.setState({activeTab: value})
  }

  displayResultsFor(items, tabLabel, title, cardTitle, link) {
    let { translate } = this.props
    if(items.length > 0 && (this.state.activeTab == tabLabel || this.state.activeTab == 'all')) {
      return (
        <SearchedItemCard
          title={title}
          items={items}
          cardTitle={cardTitle}
          link={link}
        />
      )
    } else {
      return this.state.activeTab == tabLabel && <NoResultsSearch translate={translate} />
    }
  }

  renderResults(){
    let { translate } = this.props
    let results = this.props.searchResults
    let length = results.articles.length + results.lawyers.length + results.contracts.length
    let activeTab = this.state.activeTab

    return (
      <div>
        <Tabs
          activeTab={this.state.activeTab}
          changeActiveTab={this.changeActiveTab.bind(this)}
          translate={translate}
        />
        <div class="search-result">
          <p><span>{activeTab != 'all' ? this.renderSwitch(results, activeTab) :length}</span> {translate('search.results')}</p>
        </div>
        <div class="search-result-area">
          {/* Lawyers */}
          {this.displayResultsFor(results.lawyers, 'lawyers', translate('search.lawyers'), translate('search.lawyer'), "/lawyers/")}
          {/* Contracts */}
          {this.displayResultsFor(results.contracts, 'contracts', translate('search.contracts_templates'),  translate('search.contract'), "/contracts/")}
          {/* Article */}
          {this.displayResultsFor(results.articles, 'articles', translate('search.articles'), translate('search.article'), "/article/")}
        </div>
      </div>
    )
  }

  rendernoResults(){
    let { translate } = this.props
    return <NoResultsSearch translate={translate}/>
  }

  searchInputFinishedWriting(event){
    this.setState({loading:true})
    const self = this;

    let input = event.target.value

    if (this.state.typingTimeout) {
       clearTimeout(this.state.typingTimeout);
    }

    self.setState({
       searchQuery: input,
       typing: false,
       typingTimeout: setTimeout(function () {
         self.props.searchOnHome(self.state.searchQuery, self.stopLoading.bind(self))
       }, 700)
    });
  }

  renderOptions(value){
    if(this.state.loading){
      return (
        <div style={{ position: 'absolute', height: '150%', width: '100%',
            display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b" height={'8%'} width={'8%'} />
        </div>
      )
    }

    switch(value){
      case 'suggestions':
        return this.renderSuggestion()
      case 'results':
        return this.renderResults()
      case 'noResults':
        return this.rendernoResults()
    }
  }

  render() {
    let { translate } = this.props
    let results = this.props.searchResults
    let hasResults =  results.articles && results.articles.length > 0 ||
    results.contracts && results.contracts.length > 0 ||
    results.lawyers &&results.lawyers.length > 0

    return (
      <section class="search">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 col-xl-6">
              <div class="search-top">
                <div class="search-form">
                  <input
                    ref={(input) => { this.searchInput = input; }}
                    class="search-input"
                    type="text"
                    name="search"
                    placeholder={translate('search.placeholder')}
                    value={this.state.searchQuery}
                    onChange={(event) => this.searchInputFinishedWriting(event)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                  <i class="icon-search"></i>
                </div>
                <div class="search-close">
                  <i class="icon-x"></i>
                </div>
              </div>
              {this.state.searchQuery.length > 0
                ? this.renderOptions(hasResults ? 'results' : 'noResults')
                : this.renderOptions('suggestions')}
            </div>
          </div>
        </div>
        <div class="search-overlay"></div>
      </section>
    )
  }
}

function mapStateToProps(state){
  return ({
    translate: getTranslate(state.locale),
    searchResults: state.searchResultsHome
  })
}

export default connect(mapStateToProps, { searchOnHome })(SearchModal);
