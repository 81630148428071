import React
  from 'react'
import Slider
  from 'react-rangeslider'

class RangeSlider extends React.Component {
  render() {
    let { translate, rangeObject } = this.props
    let { radius, handleOnChange } = rangeObject
    return (
      <div className="wal-form wal-form-group">
        <label for="radius">{translate('lawyers.radius')}</label>
        <div className="wal-form-range">
            <Slider
              value={radius}
              style={{backgroundColor:'red'}}
              backgroundColor={'red'}
              orientation="horizontal"
              onChange={handleOnChange}
            />
        </div>
      </div>
    )
  }
}

export default RangeSlider
