import React
    from 'react';

import Image
    from "../../components/shared/Image";


export default class KnowledgeCard extends React.Component {
  render() {
    return(
      <div className="col-md-4 col-lg-4">
        <div className="knowledge-item">
          <Image src={this.props.source} alt="knowledge" /> {/* to be replaced with <Image */}
          <div className="knowledge-content">
            <span className="tag">{this.props.type || "Type"}</span>
            <h1>{this.props.title}</h1>
            <div className="knowledge-content-bottom">
              <span className="author">by {this.props.author}</span>
              <span className="views"><i className="icon-eye"></i>112 views</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
