import { WEB_URL } from "../constants/types";

import api from "../api";

export async function singleArticle(id, getData, stopLoading) {
    console.log("asd")
    let url = `${WEB_URL}/articles/${id}`;

    api.get(url)
      .then((data) => {
        getData(data.data)
        stopLoading()
      })
      .catch((err) => {
      });
  }

  export async function articleWhenLogged(id, getData, stopLoading) {
    let url = `${WEB_URL}/articles/${id}`;

    api.get(url)
      .then((data) => {
        getData(data.data)
        stopLoading()
      })
      .catch((err) => {
      });
  }
