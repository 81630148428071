import React
    from 'react';

export default class FirstTimeModal extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
    };
  }

  render() {
    return (
        <div className="modal subscription-modal subscribe-show" style={{display:"block"}} id="subscription" tabindex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="subscription-modal-wrap">
                    <div className="subscription-modal-icon">
                        <i className="icon-subscribtion"></i>
                    </div>
                    <div className="subscription-modal-content">
                        <h1>Choose a subscription plan!</h1>
                        <p>You haven’t choose a subscription plane yet, please chose one in order to continue.</p>
                        <a href="" className="button button-bl">Choose a plan</a>
                        <a href="" className="button button-white">Use it as free for now</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
  }
}
