import React
    from 'react';

import Person
    from '../../../assets/img/person-1.png'

import { LoginForm, RegisterForm, ForgotPasswodForm }
  from '../../../components/auth'

import { getTranslate }
  from 'react-localize-redux';

import { reportLawyer }
  from '../../../helpers/reportLawyer'

class ReportLawyerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description:'',
      reason:'Inappropriate content'
    };
  }

  reportLawyer(){
    let {lawyerId} = this.props
    if(this.state.description.trim() == "") {
      alert("Message is blank")
    } else {
      reportLawyer(this.state.reason, this.state.description, 'lawyer', lawyerId)
      this.setState({description:'', reason:''})
      window.$('#report').modal('toggle');
    }
  }

  selectReason(data){
    this.setState({reason:data})
  }

  handleOnChange(evt) {
    const { value } = evt.target
    this.setState({ description: value })
  }

  render() {
    return (
        <div class="modal fade modal-ge" id="report" data-backdrop="static"
            tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-ge-wrap">
                        <div class="modal-ge-top">
                            <h1>Report lawyer</h1>
                            <p>Please fill informations on why you are reporting this lawyer, and our team will look into it and update you via notification.</p>
                        </div>
                        <div class="modal-ge-body">
                            <div class="wal-form wal-form-group">
                              <label for="select">Reason</label>
                              <div class="wal-form-sort-wrap">
                                <select
                                onChange={(event)=> this.selectReason(event.target.value)}
                                class="wal-form-control">
                                  <option value="Inappropriate content">Inappropriate content</option>
                                  <option value="Pretending to be someone else">Pretending to be someone else</option>
                                  <option value="Hate speech">Hate speech</option>
                                </select>
                              </div>
                            </div>
                            <div class="wal-form wal-form-group">
                              <label for="select">Tell us more (optional)</label>
                              <textarea class="wal-form-control" name="more"
                                 onChange={(event) => this.handleOnChange(event)}
                                 value={this.state.description}
                                 placeholder="Tell us more about your reason to report this lawyer"></textarea>
                            </div>
                        </div>
                        <div class="modal-ge-bottom modal-ge-bottom-buttons">
                            <a class="button button-white" data-dismiss="modal">Cancel</a>
                            <a onClick={()=> this.reportLawyer()} class="button button-bl">Report lawyer</a>
                        </div>
                        <div class="modal-ge-close" data-dismiss="modal"><i class="icon-x"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}


export default ReportLawyerModal
