import React
  from "react";

import ReactLoading
  from 'react-loading';

import moment
  from 'moment';

class MemberList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      callsItems: []
    };
  }

  displayItems() {
    let { items } = this.props
    return (
      items.map((item) => {
        return (
          <div className="settings-invite-list-item">
            <span className="email">{item.email}</span>
            <div className="settings-invite-list-item-right">
              <span className="date">{moment(item.created_at).format("DD MMM YYYY")}</span>
              <span className={`wal-status wal-status-${item.status}`}>{item.status}</span>
            </div>
          </div>
        )
      })
    )
  }

  render() {
    let { loading, translate, items, notFoundMessage } = this.props
    if(loading) {
      return (
        <div style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    if(items.length == 0) {
      return (
        <div style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p>{notFoundMessage}</p>
        </div>

      )
    }

    return (
      <div className="accordion-item">
        <div className="accordion-head">
          <div className="accordion-head-wrap collapsed" data-toggle="collapse" data-target="#list-invitations" aria-expanded="true">
            <h2>{translate("settings.invite.list_of_invitations")}</h2>
            <i className="icon-next"></i>
          </div>
        </div>
        <div className="accordion-body collapse show" id="list-invitations" data-parent="#invite-accordion">
          <div className="accordion-body-wrap">
            <div className="settings-invite-list">
              {this.displayItems()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MemberList;
