import React from "react";

import { Link } from "react-router-dom";

import ContractsSmall from "../../../components/home/ContractsSmall";

import LawyerInfoWide from "../../../components/shared/LawyerInfoWide";

import Hero from "../../../assets/img/hero-img.png";

import DownloadAppSection from "../../../components/shared/DownloadAppSection";

import HeroBackground from "../../../assets/img/hero-bg.jpg";

import { getTranslate } from "react-localize-redux";

import { fetchTopRatedLawyers } from "../../../actions/lawyersActions";

import { fetchContracts } from "../../../actions/contractsActions";

import { connect } from "react-redux";

import _ from "lodash";

import ReactLoading from 'react-loading';

import ContractCard from '../../../components/contracts/ContractCard'

import { numberOfLawyers } from '../../../helpers/lawyers'

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topRatedLawyersLoading:true,
      contractsLoading:true,
      numberOfLawyers: 0
    };
  }

  componentDidMount() {
    numberOfLawyers(this.fetchNumberOfLawyers.bind(this))
    this.requestData();
  }

  componentWillReceiveProps(newProps) {
    if(this.props.allContracts != newProps.allContracts) {
      window.slider();
    }
  }

  fetchNumberOfLawyers(data) {
    this.setState({numberOfLawyers: data.data.total_number_of_lawyers})
  }

  async requestData() {
    await this.props.fetchTopRatedLawyers(this.topRatedLawyersStopLoading.bind(this));
  }

  async topRatedLawyersStopLoading(){
    this.setState({topRatedLawyersLoading:false})
    await this.props.fetchContracts(this.contractsStopLoading.bind(this));
  }

  contractsStopLoading(){
    this.setState({contractsLoading:false})
  }

  displaySliderItems(topRatedLawyers) {
    let lawyers = topRatedLawyers && topRatedLawyers.data;
    return (
      lawyers &&
      lawyers.map((i) => {
        return (
          <LawyerInfoWide id={i.id} name={i.name} key={i.id} imageSource={i.avatar} rating={i.rating}/>
        );
      })
    );
  }

  displayNumerOfLawyers() {
    let { numberOfLawyers } = this.state
    if(numberOfLawyers > 150) {
      return numberOfLawyers
    } else {
      return 150
    }
  }

  render() {
    let { translate } = this.props;
    let contracts = this.props.allContracts && this.props.allContracts;
    return (
      <div>
        <title>{translate("home.app_title")} | WAL</title>
        <main className="main">
          <section
            className="hero pd-rl"
            style={{ backgroundImage: `url(${HeroBackground})` }}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="hero-img">
                  <img src={Hero} alt="hero-img" />
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="hero-content">
                    <h1>{translate("home.conent_title")}</h1>
                    <p>{translate("home.content_description")}</p>
                    <div className="hero-button">
                      <Link
                        className="button button-transparent button-transparent-border"
                        to={`/lawyers`}
                      >
                        <i className="icon-lawyers"></i>
                        {translate("home.buttons.view_lawyers")}
                      </Link>
                      <Link
                        className="button button-transparent button-transparent-border"
                        to={`/contracts`}
                      >
                        <i className="icon-lawyers"></i>
                        {translate("home.buttons.view_contracts")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="hero-lawyers">
                    <span>+{this.displayNumerOfLawyers()}</span>
                    <p>{translate("home.lawyers_in_wal")}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="rated-lawyers mg-top-50 pd-rl">
            <div className="container-fluid">
              <div className="main-title">
                <h1>{translate("home.top_rated_lawyers")}</h1>
                <Link to={`/lawyers`}>{translate("home.view_more")}</Link>
              </div>
              <div className="rated-lawyers-wrap">
                {
                this.state.topRatedLawyersLoading
                  ?
                    <ReactLoading type="spin" color="#bea58b"/>
                  :
                  this.displaySliderItems(this.props.topRatedLawyers)}
              </div>
            </div>
          </section>

          <section className="contracts mg-top-112 pd-rl">
            <div className="container-fluid">
              <div className="main-title">
                <h1>{translate("home.contracts_and_templates")}</h1>
                <Link to={`/contracts`}>{translate("home.view_more")}</Link>
              </div>
              <div className="row">
                {
                this.state.contractsLoading
                  ?
                    <ReactLoading type="spin" color="#bea58b"/>
                  :
                  contracts.data &&
                    contracts.data.map((item, index) => {
                      return (
                        <div className="col-md-12 col-lg-12 col-xl-6">
                          <ContractCard
                            item={item}
                          />
                        </div>
                      );
                    })}
              </div>
            </div>
          </section>

          <DownloadAppSection translate={translate} />

        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    translate: getTranslate(state.locale),
    topRatedLawyers: state.topRatedLawyers,
    allContracts: state.allContracts,
  };
}

export default connect(mapStateToProps, {
  fetchTopRatedLawyers,
  fetchContracts,
})(LandingPage);
