import React
  from "react";

import { connect }
  from "react-redux";

import { CustomTextInput, CustomSelect }
  from '../../../components/shared/forms';

import { fetchCoutries }
  from '../../../actions/selectedItemsActions';

import { ConfirmChangeType }
  from '../../../components/shared/modals/setting';

import Image
  from '../../../components/shared/Image';

import { capitalize }
  from '../../../services/manipulateWithString';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class LawyerForm extends React.Component {
  constructor(props) {
    super(props);
    this.props = {
      loading: true
    };
  }

  render() {
    const { currentUser, translate } = this.props
    let { error, errors, updateParam, } = this.props
    let { company_name, type, email, phone, address, name, branch, employee_number } = updateParam
    let { gender, nationality_id, nationality } = currentUser

    return (
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <CustomTextInput
            labelName={translate('auth.full_name')}
            name={'name'}
            value={name}
            handleChange={this.props.handleChangeState.bind(this)}
            errors={errors}
          />
        </div>
        { type == "company" &&
          <>
            <div className="col-md-12 col-lg-12">
              <CustomTextInput
                labelName={translate('auth.company_name')}
                name={'company_name'}
                value={company_name}
                handleChange={this.props.handleChangeState.bind(this)}
                errors={errors}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.branch')}
                    name={'branch'}
                    value={branch}
                    handleChange={this.props.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.branch_placeholder')}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.employee_number')}
                    name={'employee_number'}
                    type={"number"}
                    value={employee_number}
                    handleChange={this.props.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.employee_number_placeholder')}
                  />
                </div>
              </div>
            </div>
          </>
        }
        <div className="col-md-6 col-lg-6">
          <CustomTextInput
            labelName={translate('auth.email_address')}
            name={'email'}
            value={email}
            handleChange={this.props.handleChangeState.bind(this)}
            errors={errors}
            disabled
          />
        </div>
        <div className="col-md-6 col-lg-6">
          <div className={`wal-form wal-form-group ${errors && errors[name] && 'wal-form-error'}`}>
            <label>{translate('auth.phone')}</label>
            <PhoneInput
              country={'ch'}
              value={phone}
              handleChange={this.props.handleChangeCountry.bind(this)}
              onChange={phone => this.props.handleChangeState({currentTarget: {name: "phone", value: phone}})}
              inputStyle={{width: '100%'}}
              value={phone}
              inputProps={{
                name: 'phone'
              }}
            />
            { errors && errors[name] &&
              <div className="error">
                <span>{errors[name]}</span>
              </div>
            }
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <CustomSelect
            options={this.props.countries}
            labelName={translate('auth.countries')}
            name={'nationality_id'}
            errors={errors}
            handleChange={this.props.handleChangeCountry.bind(this)}
            placeholder={translate('auth.select_country')}
            value={nationality ? {value: nationality.id, label: nationality.name} : null}
          />
        </div>
        <div className="col-md-6 col-lg-6">
          <CustomSelect
            options={this.props.ganderOptions}
            labelName={translate('auth.gender')}
            name={'gender'}
            errors={errors}
            handleChange={this.props.handleChangeGender.bind(this)}
            placeholder={"Select gender"}
            value={{ value: gender, label: capitalize(gender) }}
          />
        </div>
        <div className="col-md-6 col-lg-6">
          <CustomTextInput
            labelName={translate('auth.address')}
            name={'address'}
            value={address}
            handleChange={this.props.handleChangeState.bind(this)}
            errors={errors}
          />
        </div>
      </div>
    )
  }
};

export default LawyerForm;
