import api from "../api";

import {WEB_URL, GET_ARTICLE_REVIEWS } from "../constants/types";

export function getArticleReviews(id, addLastPage, currentPage, allReviewsNumber) {
  return async function (dispatch) {
    let url = `${WEB_URL}/articles/${id}/reviews?sort=-created_at&per_page=5&page=${currentPage}`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: GET_ARTICLE_REVIEWS,
          payload: data.data.data,
        });
        addLastPage(data.data.last_page)
        allReviewsNumber(data)
      })
      .catch((err) => {});
  };
}
