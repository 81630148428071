
import {
    FETCH_LAWYER_ARTICLES
  } from '../constants/types';

  export default function(state=[], action){
    switch(action.type){
      case FETCH_LAWYER_ARTICLES:
        return action.payload
      default:
        return state
    }
  }
