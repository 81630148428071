import api from "../api";

import {CURRENCY, WEB_URL} from "../constants/types";

export function fetchCurrencies() {
  return async function (dispatch) {
    let url = `${WEB_URL}/product-prices`;
    api.get(url)
      .then((data) => {
        dispatch({
          type: CURRENCY,
          payload: data.data.data,
        });
      })
      .catch((err) => {
      });
  };
}
