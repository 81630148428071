import React
  from 'react';

import Person
  from '../../assets/img/person-1.png'

import { Link }
  from "react-router-dom";

import Image
  from '../../components/shared/Image'

import ReactLoading
  from 'react-loading';

import ContractCard
  from '../../components/contracts/ContractCard'

import {  EmptyResult }
  from '../../components/shared/filters';

import { connect }
  from 'react-redux';

import { getTranslate }
  from 'react-localize-redux';

class ContractsSearched extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  displayContracts() {
    let { items, searchLoading, translate } = this.props
    if(searchLoading) {
      return (
        <div style={{ position: 'absolute', height: '50%', width: '100%',
                      display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }
    if(items && items.length > 0) {
      return (
        <div className="wal-results-wrap">
          {items.map((item) => {
            return (
              <ContractCard item={item} />
            )
          })}
        </div>
      )
    } else {
      return (
        <EmptyResult
          icon={"type-contract"}
          title={translate("contracts.empty_title")}
          description={translate("contracts.empty_description")}
          action={{label: translate("contracts.empty_action"), goTo: "/contracts"}}
        />
      )
    }
  }

  render() {
    return(
      this.displayContracts()
    )
  }
}

function mapStateToProps(state){
  return ({
            translate: getTranslate(state.locale),
            contract: state.contract,
          })
}

export default connect(mapStateToProps, { })(ContractsSearched);
