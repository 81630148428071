import React from "react";

import { Link } from "react-router-dom";

import { getTranslate } from "react-localize-redux";

import { fetchTopRatedLawyers } from "../../../actions/lawyersActions";

import { fetchContracts } from "../../../actions/contractsActions";

import { connect } from "react-redux";

import _ from "lodash";

import ReactLoading from 'react-loading';

import { RecommendedLawyers, Knowledge } from "../../../components/shared/sliders";

import LawyersInYourRegion from '../../../components/shared/LawyersInYourRegion';

import CourtsCases from '../../../components/home/CourtsCases';

import ContractAndTemplatesOfUser from '../../../components/home/ContractAndTemplatesOfUser';

import { lawyersRecommended } from "../../../actions/lawyersActions";

import { getSubInfo, removeSubInfo } from "../../../helpers";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      recommendedLawyers: null
    };
  }

  async componentDidMount() {
    await this.requestData()
    // removeSubInfo()
  }

  async requestData()  {
    await this.props.lawyersRecommended(this.stopLoading.bind(this));
  }

  stopLoading() {
    this.setState({loading: false}, () => window.slider())
    this.checkSubscription()
  }

  checkSubscription() {
    let { current_user } = this.props
    if(current_user && !getSubInfo()) {
      window.$('#choose_subscription').modal('toggle');
    }
  }

  render() {
    let { translate } = this.props

    if(this.state.loading) {
      return (
        <div style={{marginLeft: "48%", marginTop: "15%"}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <div>
        <title>{translate("home.app_title")} | WAL</title>
        <main class="main">
          <RecommendedLawyers />
          <LawyersInYourRegion cover={"mg-top-160"} />
          <Knowledge
            title={translate("header.tabs.knowledge")}
            cover={"mg-top-112"}
            viewMore
          />
          {/* <CourtsCases /> */}
          <ContractAndTemplatesOfUser />
        </main>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    current_user: state.currentUser,
    translate: getTranslate(state.locale),
    recommendedLawyers: state.lawyersRecommended
  };
}

export default connect(mapStateToProps, { lawyersRecommended })(HomePage);
