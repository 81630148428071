import React from 'react';
import MessageActions from "./MessageActions";
import moment from "moment";

import {getApiUrl, getToken} from '../../../helpers/index'

class Contract extends React.Component {
    render() {

        const contract = JSON.parse(this.props.body)

        return (
            <div className={this.props.self ? "messages-item self" : "messages-item"}>
                <div className="messages-item-wrap">
                    <div className="messages-file">
                        <div className="messages-file-wrap">
                            <div className="messages-file-icon">
                                <i className="icon-pdf-file"></i>
                            </div>
                            <div className="messages-file-content">
                                <div className="messages-file-info">
                                    <h2>{contract.name}</h2>
                                </div>
                                <div className="messages-file-download">
                                    <a download
                                       href={getApiUrl() + 'contracts/' + contract.id + '/download?token=' + getToken()}>
                                        <i className="icon-download"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="messages-time">
                        <span>{moment(this.props.created_at).fromNow(true)}</span>
                    </div>
                    <MessageActions {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Contract
