import React
    from 'react'

const AuthButtonsSection = (props) => {
  let {translate} = props
  return (
    <div className="header-button">
      <a href="" className="button button-transparent"
        onClick={()=> props.modalClick(0)}
        data-toggle="modal" data-target="#login">{translate('header.tabs.buttons.login')}</a>
      <a href="" className="button button-primary"
        onClick={()=> props.modalClick(1)}
        data-toggle="modal" data-target="#login"><i className="icon-plus"></i>{translate('header.tabs.buttons.register')}</a>
    </div>
  )
}

export default AuthButtonsSection
