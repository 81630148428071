import React from "react";

import { connect } from "react-redux";

import { loadProfile } from "../../../actions/auth";

import { fetchUserArticlesBookmarks,
         fetchUserContractsBookmarks,
         fetchUserLawyersBookmarks,
         fetchUserContracts,
         emptyRedux
       } from "../../../actions/profile";

import Image from "../../../components/shared/Image";

import { IMAGE_BASE } from "../../../constants/types";

import Maps from "../../../components/lawyers/Map";

import ReactLoading from 'react-loading';

import AboutMeSection from '../../../components/profile/AboutMeSection'

import BookmarksSection from '../../../components/profile/BookmarksSection'

import ContractsSection from '../../../components/profile/ContractsSection'

import { getTranslate } from 'react-localize-redux';

class CurrentProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      lawyersPage: 1,
      bookmarkContractsPage: 1,
      contractsPage:1
    };
  }

  componentDidMount() {
    this.requestData();
  }

  componentWillUnmount(){
    this.props.emptyRedux()
  }

  async requestData() {
    this.props.loadProfile();
    this.props.fetchUserArticlesBookmarks(this.stopLoading.bind(this)); //fix after the design
    this.props.fetchUserContractsBookmarks(this.lastContractsPage.bind(this), this.state.bookmarkContractsPage);
    this.props.fetchUserLawyersBookmarks(this.lastLawyerPage.bind(this), this.state.lawyersPage);
    this.props.fetchUserContracts(this.lastUserContractsPage.bind(this), this.state.contractsPage);
  }

  loadMoreLawyers(){
    this.setState({lawyersPage:this.state.lawyersPage+1}, () => {
      this.props.fetchUserLawyersBookmarks(this.lastLawyerPage.bind(this), this.state.lawyersPage);

    })
  }

  loadMoreUserContracts(){
    this.setState({contractsPage:this.state.contractsPage+1}, () => {
      this.props.fetchUserContracts(this.stopLoading.bind(this), this.state.contractsPage);
    })
  }

  loadMoreContracts(){
    this.setState({bookmarkContractsPage:this.state.bookmarkContractsPage+1}, () => {
      this.props.fetchUserContractsBookmarks(this.lastContractsPage.bind(this), this.state.bookmarkContractsPage);

    })
  }

  lastContractsPage(data){
    this.setState({contractsLastPage: data})
  }

  lastUserContractsPage(data){
    this.setState({userContractsLastPage: data})
  }

  lastLawyerPage(data){
    this.setState({lawyersLastPage: data})
  }

  stopLoading(){
      this.setState({loading:false})
  }
  render() {
    let currentUser = this.props.current_user;
    let { translate } = this.props
    if(this.state.loading || !currentUser){
      return  (
        <div style={{ height: 500, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <div>
        <title>{currentUser && currentUser.name} | WAL</title>
        <main class="main">
          <section
            class="profile pd-rl"
            style={{
              backgroundImage: `url(${IMAGE_BASE + currentUser.avatar})`,
            }}
          >
            <div class="container-fluid">
              <div class="profile-wrap">
                <div class="profile-top"></div>
                <div class="profile-bottom">
                  <div class="row align-items-end">
                    <div class="col-md-12 col-lg-8">
                      <div class="profile-info">
                        <div class="profile-image">
                          <Image src={currentUser.avatar} alt="person" />
                        </div>
                        <div class="profile-info-wrap">
                          <div class="profile-info-top">
                            <div class="profile-status">
                              <div
                                class={
                                  currentUser.is_online
                                    ? "status active"
                                    : "status inactive"
                                }
                              ></div>
                              <span>
                                {currentUser.is_online ? translate('profile.online') :  translate('profile.offline')}
                              </span>
                            </div>
                          </div>
                          <div class="profile-name">
                            <h1>{currentUser.name}</h1>
                          </div>
                          <div class="profile-rating-state">
                            <div class="profile-type">
                              <span>
                                <i class="icon-calendar"></i>14 {translate('profile.appointments')}
                              </span>
                            </div>
                            <div class="profile-state">
                              <i class="icon-contracts"></i>
                              <span>{this.props.contracts.length} {translate('profile.contracts')}</span>
                            </div>
                          </div>
                          <div class="profile-type">
                            <span class="type">@{currentUser.username}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="profile-buttons">
                        <a href="/settings" class="button button-primary">
                          {translate('profile.edit_profile')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="profile-content mg-top-40 pd-rl">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-8">
                  <ul class="profile-tab nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#about"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="about"
                      >
                        {translate('profile.about_me')}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#bookmarks"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="bookmarks"
                      >
                        {translate('profile.bookmarks')}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#contracts"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="contracts"
                      >
                        {translate('profile.my_contracts')}
                      </a>
                    </li>
                  </ul>
                  <div class="profile-tab-content tab-content" id="tabs">
                  <AboutMeSection
                    currentUser={currentUser}
                    translate={translate}
                  />
                  <BookmarksSection
                    contractBookmarks={this.props.contractBookmarks}
                    lawyerBookmarks={this.props.lawyerBookmarks}
                    lawyersLastPage={this.state.lawyersLastPage}
                    lawyersPage={this.state.lawyersPage}
                    lawyerMoreClick={()=> this.loadMoreLawyers()}
                    contractsLastPage={this.state.contractsLastPage}
                    bookmarkContractsPage={this.state.bookmarkContractsPage}
                    moreContractsBookmarksClick={()=> this.loadMoreContracts()}
                    translate={translate}
                  />
                  <ContractsSection
                    contracts={this.props.contracts}
                    userContractsLastPage={this.state.userContractsLastPage}
                    contractsPage={this.state.contractsPage}
                    userMoreContracts={()=> this.loadMoreUserContracts()}
                    translate={translate}
                  />
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-xl-4">
                  <div class="profile-aside">
                    <div class="profile-aside-content">
                      <div class="profile-aside-content-item">
                        <h3>{translate('profile.email')}</h3>
                        <p>{currentUser.email}</p>
                        <a href={"mailto:" + currentUser.email} class="next">
                          <i class="icon-next"></i>
                        </a>
                      </div>
                      <h3>{translate('profile.language')}</h3>
                      {currentUser.languages.length > 0 ? (
                        currentUser.languages.map((item) => (
                          <p>{item.name}</p>
                        ))
                      ) : (
                        <p>{translate('profile.no_languages')}</p>
                      )}
                      <h3>{translate('profile.location')}</h3>
                      <p>{currentUser.address}</p>
                    </div>
                    <div class="profile-aside-map">
                      <Maps
                        lat={currentUser.latitude}
                        long={currentUser.longitude}
                        lawyers={[currentUser]}
                      />
                    </div>
                    <div class="profile-aside-copy">
                      <div class="copy-link">
                        <input
                          type="text"
                          class="wal-form-control"
                          value="wal.worl/as2049sc.12"
                          readonly=""
                        />
                        <a class="copy">
                          <i class="icon-copy"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    articleBookmarks:state.currentUserBookmarksArticles,
    contractBookmarks:state.currentUserBookmarksContracts,
    lawyerBookmarks:state.currentUserBookmarksLawyers,
    current_user: state.currentUser,
    bookmarks: state.currentUserBookmarks,
    contracts: state.currentUserContracts,
    translate: getTranslate(state.locale)
  };
}

export default connect(mapStateToProps, {
  loadProfile,
  fetchUserArticlesBookmarks,
  fetchUserContractsBookmarks,
  fetchUserLawyersBookmarks,
  fetchUserContracts,
  emptyRedux
})(CurrentProfile);
