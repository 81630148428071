import React from "react";

import Image
  from '../../components/shared/Image'

import { Link }
  from "react-router-dom";

export default class LawyerInfoWide extends React.Component {

  render() {
    return (
      <Link to={'/lawyers/'+this.props.id} key={this.props.index} className="rated-lawyers-item">
        <Image
          src={this.props.imageSource}
          alt="lawyer"
        />
        <div className="rated-lawyers-content">
          <h1>{this.props.name}</h1>
          <div className="rated-lawyers-bottom">
            <div className="rated-stars">
                {[0,1,2,3,4].map((index)=>{
                    return  <i class={index+1 <= Math.round(parseInt(this.props.rating))
                        ? "icon-star-filled" : "icon-star"}></i>
                    })}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}
