import React
  from 'react';

import { CustomTextInput, CustomPasswordInput, RadioSelectInput, CustomSelect }
  from '../../components/shared/forms';

import { SocialMediaButtons }
  from './';

import { signUp }
  from '../../actions/auth';

import { fetchCoutries, fetchInterests }
  from '../../actions/selectedItemsActions';

import { connect }
  from 'react-redux';

import ReactLoading
  from 'react-loading';

import Select
  from 'react-select'

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestParams: {
        first_name: null,
        last_name: null,
        email: null,
        username: null,
        password: null,
        password_confirmation: null,
        type: null,
        phone: null,
        address: null,
        nationality_id: null,
        interests: [],
        gender: null,
        company_name: null,
        branch: null,
        employee_number: null
      },
      error: null,
      errors: null,
      requestLogin: false,
      passwordTypeInput: "password",
      passwordConfirmationTypeInput: "password"
    };
  }

  async componentDidMount() {
    await this.props.fetchCoutries()
    await this.props.fetchInterests()
  }

  registerRequest() {
    let { requestParams } = this.state
    this.setState({requestLogin: true})
    this.props.signUp(requestParams, this.stopLoading.bind(this), this.handleErrors.bind(this))
  }

  stopLoading(){
    this.setState({requestLogin: false})
  }

  handleErrors(data) {
    this.setState({errors: data.errors})
  }

  handleChangeState(e) {
    let { errors, requestParams } = this.state;
    const { name, value } = e.currentTarget;
    this.setState({requestParams: {...requestParams, [name]: value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors[name.toString()]
      this.setState({errors: costum_errors})
    }
  }

  selectType(type) {
    let {requestParams } = this.state;
    this.setState({requestParams: {...requestParams, type: type}})
  }

  genderType(gender) {
    let { requestParams } = this.state;
    this.setState({requestParams: {...requestParams, gender: gender}})
  }

  displayRequestButton() {
    let { requestLogin } = this.state
    let { translate } = this.props
    if(requestLogin) {
      return (
        <div style={{marginLeft: "43%"}}>
          <ReactLoading type="spin" color="#bea58b" height={'20%'} width={'20%'} />
        </div>
      )
    } else {
      return (
        <input
          type="submit"
          className="button button-primary"
          value={translate('auth.sign_up')}
          onClick={() => this.registerRequest()}
        />
      )
    }
  }

  handleChangeCountry(selectedOption) {
    let { requestParams, errors } = this.state
    this.setState({requestParams: {...requestParams, nationality_id: selectedOption.value}})

    if(errors) {
      var costum_errors = errors
      delete costum_errors["nationality_id"]
      this.setState({errors: costum_errors})
    }
  }

  handleChangeInterests(selectedOptions) {
    let { requestParams, errors } = this.state
    let interestSelected = []

    if(selectedOptions) {
      selectedOptions.map((opt) => {
        interestSelected.push(opt.value)
      })
      this.setState({requestParams: {...requestParams, interests: interestSelected}})
    } else {
      this.setState({requestParams: {...requestParams, interests: []}})
    }

    if(errors) {
      var costum_errors = errors
      delete costum_errors["interests"]
      this.setState({errors: costum_errors})
    }
  }

  render() {
    let { translate } = this.props
    let { requestParams, errors } = this.state
    let type_error = this.state.errors && this.state.errors.type && !this.state.requestParams.type
    let gender_error = this.state.errors && this.state.errors.gender && !this.state.requestParams.gender

    return(
      <div className={this.props.active == 1 ? "tab-pane fade show active" : "tab-pane fade"} id="m-register" role="tabpanel">
        <SocialMediaButtons
          translate={translate}
          facebookTextButton={translate('auth.register_with_fb')}
          googleTextButton={translate('auth.register_with_google')}
          startLoading={() => this.setState({requestLogin: true})}
          stopLoading={() => this.stopLoading()}
        />
        <div className="login-form">
          <div className="row">
            <RadioSelectInput
              options={[{value: "user", label: translate('auth.private'), logo: "icon-users"},
                        {value: "company", label: translate('auth.company'), logo: "icon-companies"}]}
              translate={translate}
              typeError={type_error}
              requestParams={requestParams}
              selectType={this.selectType.bind(this)}
              errors={errors}
              mainObject={requestParams.type}
              radioTitle={translate('auth.profile_type')}
              textError={this.state.errors && this.state.errors.type}
              selectName={"type"}
            />
            { requestParams.type == "company" &&
              <>
                <div className="col-md-12 col-lg-12">
                  <CustomTextInput
                    labelName={translate('auth.company_name')}
                    name={'company_name'}
                    value={requestParams.company_name}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.company_placeholder')}
                  />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <CustomTextInput
                        labelName={translate('auth.branch')}
                        name={'branch'}
                        value={requestParams.branch}
                        handleChange={this.handleChangeState.bind(this)}
                        errors={errors}
                        placeholder={translate('auth.branch_placeholder')}
                      />
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <CustomTextInput
                        labelName={translate('auth.employee_number')}
                        name={'employee_number'}
                        type={"number"}
                        value={requestParams.employee_number}
                        handleChange={this.handleChangeState.bind(this)}
                        errors={errors}
                        placeholder={translate('auth.employee_number_placeholder')}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.first_name')}
                    name={'first_name'}
                    value={this.state.first_name}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.first_name_placeholder')}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.last_name')}
                    name={'last_name'}
                    value={this.state.last_name}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.last_name_placeholder')}
                  />
                </div>
              </div>
            </div>
            <RadioSelectInput
              options={[{value: "male", label: translate('auth.male'), logo: "icon-user"},
                        {value: "female", label: translate('auth.female'), logo: "icon-user"}]}
              translate={translate}
              typeError={gender_error}
              requestParams={requestParams}
              selectType={this.genderType.bind(this)}
              errors={errors}
              mainObject={requestParams.gender}
              radioTitle={translate('auth.gender')}
              textError={this.state.errors && this.state.errors.gender}
              selectName={"gender"}
              noIcon
            />
            <div className="col-md-12 col-lg-12">
              <CustomSelect
                options={this.props.countries}
                labelName={translate('auth.countries')}
                name={'nationality_id'}
                errors={errors}
                handleChange={this.handleChangeCountry.bind(this)}
                placeholder={translate('auth.select_country')}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomSelect
                options={this.props.interests}
                isMulti
                labelName={translate('auth.interests')}
                name={'interests'}
                errors={errors}
                handleChange={this.handleChangeInterests.bind(this)}
                placeholder={translate('auth.select_interests')}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomTextInput
                labelName={translate('auth.username')}
                name={'username'}
                value={this.state.username}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
                placeholder={translate('auth.username_placeholder')}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.phone')}
                    name={'phone'}
                    value={this.state.phone}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.phone_placeholder')}
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <CustomTextInput
                    labelName={translate('auth.address')}
                    name={'address'}
                    value={this.state.address}
                    handleChange={this.handleChangeState.bind(this)}
                    errors={errors}
                    placeholder={translate('auth.address_placeholder')}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomTextInput
                labelName={translate('auth.email_address')}
                name={'email'}
                value={this.state.email}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
                placeholder={translate('auth.email_address_placeholder')}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomPasswordInput
                labelName={translate('auth.password')}
                name={'password'}
                value={this.state.password}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
                typeInput={this.state.passwordTypeInput}
                changeInputType={() => this.setState({passwordTypeInput: this.state.passwordTypeInput == "text" ? "password" : "text"})}
                withInfo
                translate={translate}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <CustomPasswordInput
                labelName={translate('auth.confirm_password')}
                name={'password_confirmation'}
                value={this.state.password_confirmation}
                handleChange={this.handleChangeState.bind(this)}
                errors={errors}
                typeInput={this.state.passwordConfirmationTypeInput}
                changeInputType={() => this.setState({passwordConfirmationTypeInput: this.state.passwordConfirmationTypeInput == "text" ? "password" : "text"})}
                translate={translate}
              />
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="login-button">
                {this.displayRequestButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  return ({
    countries: state.countries,
    interests: state.interests
  })
}

export default connect(mapStateToProps, { signUp, fetchCoutries, fetchInterests })(RegisterForm);
