import React from "react";
import { connect } from "react-redux";
import { getHistoryOrderStatistic } from "../../../actions/orderActions";
import ReactLoading from 'react-loading';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      tabs: [{index: 0, title: props.translate("settings.order_history.tabs.total"), label: "total", icon: "icon-order-history"},
             {index: 1, title: props.translate("settings.order_history.tabs.calls"), label: "calls", icon: "icon-call"},
             {index: 2, title: props.translate("settings.order_history.tabs.contracts"), label: "contracts", icon: "icon-contract"},
             {index: 3, title: props.translate("settings.order_history.tabs.chats"), label: "chats", icon: "icon-chat"}]
    };
  }

  componentDidMount() {
    this.props.getHistoryOrderStatistic()
  }

  displayTabs() {
    let { tabs } = this.state
    let { changePage, currentPage, orderStatistic } = this.props

    return (
      tabs.map((tab) => {
        return (
          <div className={`settings-tab-item ${currentPage == tab.index ? 'active' : ''}`}>
            <a href="#" onClick={() => changePage(tab.index)}  className="settings-tab-item-wrap">
              <div className="settings-tab-item-content">
                <span>{tab.title}</span>
                <div className="settings-tab-item-info">
                  <i className={`${tab.icon == 'icon-contract' ? 'icon-contracts' : tab.icon}`}></i>
                  <p>{orderStatistic.[tab.label]}</p>
                </div>
              </div>
            </a>
          </div>
        )
      })
    )
  }

  render() {
    let { changePage, currentPage, orderStatistic } = this.props
    if(orderStatistic.length < 1) {
      return (
        <div style={{ height: 200, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ReactLoading type="spin" color="#bea58b"/>
        </div>
      )
    }

    return (
      <div className="settings-tab">
        <div className="settings-tab-wrap">
          {this.displayTabs()}
        </div>
      </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    current_user: state.currentUser,
    orderStatistic: state.orderStatistic
  };
}

export default connect(mapStateToProps, { getHistoryOrderStatistic })(Tabs);
