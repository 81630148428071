import React, {Component} from 'react';

import DefaultAvatar from '../../../assets/img/users.svg'
import {imageUrl} from "../../../helpers";

import DisplayRating from "../../shared/DisplayRating";

import moment from "moment";

class ContractsModal extends Component {

    state = {
        name: "",
        selected: {}
    }

    componentDidMount() {
        this.onSearch();
    }

    onSearchChange = (event) => {
        event.preventDefault();

        this.setState({name: event.target.value})
    }

    onSearchKeyPress = (event) => {
        if (event.key.toLowerCase() == 'enter') {
            this.onSearch();
        }
    }

    onSearch = () => {
        this.props.purchasedContracts({name: this.state.name});
    }

    selectContract = (contract) => {
        this.setState({
            selected: contract
        })
    }

    onUpload = (event) => {
        event.preventDefault();
        if (this.state.selected?.id) {
            this.props.addMessage({
                conversation_id: this.props.conversation.id,
                body: JSON.stringify({
                    id: this.state.selected.id,
                    name: this.state.selected.name,
                    file: this.state.selected.docs[0].name,
                    type: this.state.selected.docs[0].type
                }),
                type: 'contract'
            })
            this.props.closeContractModal();
            this.setState({
                selected: {}
            })
        }
    }

    render() {
        return (
            <div className={"modal fade modal-ge upload-contract-modal" + (this.props.open ? " show" : "")}
                 style={{display: (this.props.open ? "block" : "none")}} id="upload-contract" data-backdrop="static"
                 tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="upload-contract-wrap">
                            <div className="upload-contract-top">
                                <h1>Choose contract to upload</h1>
                                <p>Select contracts you want to upload to this chat. You can only send contracts you
                                    already bought</p>
                                <div className="upload-contract-top-wrap">
                                    <span>{this.props.contracts.contracts.length} total</span>
                                    <div className="upload-contract-search">
                                        <div className="wal-form wal-form-group wal-form-search">
                                            <input
                                                type="search"
                                                name="name"
                                                placeholder="Search for contracts"
                                                className="wal-form-control"
                                                onChange={this.onSearchChange}
                                                value={this.state.name}
                                                onKeyPress={this.onSearchKeyPress}
                                            />
                                        </div>
                                        <button onClick={this.onSearch} className="button button-white">Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="upload-contract-body">
                                <div className="upload-contract-body-wrap">
                                    {
                                        this.props.contracts.contracts.map((contract, index) => {
                                            return (
                                                <div key={contract.id}
                                                     className={"contracts-item" + (this.state.selected.id === contract.id ? " selected" : "")}>
                                                    <a onClick={() => this.selectContract(contract)} href="#"
                                                       className="contracts-item-wrap">
                                                        <div className="contracts-left">
                                                            <div className="contracts-img">
                                                                {
                                                                    contract.lawyer.avatar
                                                                        ? <img src={imageUrl(contract.lawyer.avatar)}
                                                                               alt={contract.lawyer.name}/>
                                                                        : <img src={DefaultAvatar}
                                                                               alt={contract.lawyer.name}/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="contracts-center">
                                                            <div className="contracts-content">
                                                                {
                                                                    /*
                                                                    <div className="contracts-icon">
                                                                        <i className="icon-open-cases-docs"></i>
                                                                    </div>
                                                                     */
                                                                }
                                                                <div className="contracts-content-wrap">
                                                                    <div className="contracts-title">
                                                                        <h1>{contract.name}</h1>
                                                                    </div>
                                                                    <div className="contracts-info">
                                                                        <DisplayRating
                                                                            stars={[0, 1, 2, 3, 4]}
                                                                            currentRate={contract.rating}
                                                                        />
                                                                        {
                                                                            contract.categories.length > 0 &&
                                                                            <p>{contract.categories.map(c => c.name).join(',')}</p>
                                                                        }
                                                                        <span>{moment(contract.created_at).format("DD MMM YYYY")}</span>
                                                                        {
                                                                            contract.languages.length > 0 &&
                                                                            <span>{contract.languages[0].name}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="upload-contract-bottom">
                                <button onClick={this.props.closeContractModal} className="button button-white"
                                        data-dismiss="modal">Cancel
                                </button>
                                <button onClick={this.onUpload} className="button button-primary">Upload</button>
                            </div>
                            <div className="upload-contract-close" data-dismiss="modal">
                                <i onClick={this.props.closeContractModal} className="icon-x"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContractsModal;