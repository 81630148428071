import { WEB_URL } from "../constants/types";

import api from "../api";

import qs from 'qs';

export async function getCards(catchResult) {
  let url = `${WEB_URL}/credit_cards`;

  api.get(url)
    .then((data) => {
      catchResult(data.data)
    })
    .catch((err) => {
    });
}

export async function addCard(params, successCatchResult, errorCatchResult) {
  let url = `${WEB_URL}/credit_cards`;

  let data = {
    "card_number": params.card_number,
    "expiration_year": params.expire.split("/")[1] ? params.expire.split("/")[1] : "",
    "expiration_month": params.expire.split("/")[0],
    "cvc": params.cvc,
    "card_owner": params.card_owner
  }

  api.post(url, qs.stringify(data))
    .then((data) => {
      console.log(data)
      successCatchResult(data.data)
    })
    .catch((err) => {
      console.log(err)
      errorCatchResult(err.data)
    });
}

export async function purchaseEntry(params, successCatchResult, errorCatchResult) {
  let url = `${WEB_URL}/purchases`;

  let data = {
    "user_card_id": params.cardId,
    "entity_type": params.entityType,
    "entity_id": params.entityId
  }

  api.post(url, qs.stringify(data))
    .then((data) => {
      successCatchResult()
    })
    .catch((err) => {
      errorCatchResult(err.data)
    });
}


export async function deleteCard(id, successCatchResult) {
  let url = `${WEB_URL}/credit_cards/${id}`;

  api.delete(url)
    .then((data) => {
      successCatchResult()
    })
    .catch((err) => {
    });
}
