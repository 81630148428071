import React
  from 'react';

import Person
  from '../../assets/img/person-1.png'

import Verify
  from '../../assets/img/icons/verify.svg'

import Image
  from '../../components/shared/Image'

import DisplayRating
  from '../../components/shared/DisplayRating'

import CategorySvg
  from '../../components/shared/CategorySvg'

import { bookmarkItem, deleteBookmarkItem }
  from '../../helpers/bookmarkItem'

import { Link }
  from "react-router-dom";

import { getToken }
  from "../../helpers";

class LawyerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBookmarked: this.props.item.is_bookmarked
    };
  }

  triggerLawyerBookmark(e) {
    e.preventDefault()
    let { id } = this.props.item
    let { isBookmarked } = this.state
    if(getToken()) {
      isBookmarked ? deleteBookmarkItem(id, "lawyer", this.catchResult.bind(this)) : bookmarkItem(id, "lawyer", this.catchResult.bind(this))
    } else {
      window.$('#login').modal('toggle')
    }
  }

  catchResult(data) {
    data == 0 ? this.setState({isBookmarked: false}) : this.setState({isBookmarked: true})
  }

  displayCategories() {
    let { categories } = this.props.item
    let collectMainCategory = []
    categories.map((category) => {
      if(category.parent_category_relation) {
        collectMainCategory.push(category.parent_category_relation)
      }
    })
    const mainUniqueCategories = [...new Map(collectMainCategory.map(item => [JSON.stringify(item), item])).values()]
    return (
      mainUniqueCategories.map((category, index) => {
        return (
          <CategorySvg index={category.id}/>
        )
      })
    )
  }

  render() {
    let { id, rating, name, bar_verified, avatar, description, address, approved, region, is_bookmarked, is_online, zip_code, categories } = this.props.item
    let { isBookmarked } = this.state
    let fixedDescription = description && description.replace(/(<([^>]+)>)/ig,' ')

    console.log( this.props.item
    )
    return(
      <div class="lawyers-item">
          <div class="lawyers-img">
            <Image src={avatar} alt="person"/>
            <div class={`status ${is_online ? 'active ' : 'inactive'}`}></div>
          </div>
          <Link to={'/lawyers/' + id} >
          <div class="lawyers-content">
            <div class="lawyers-title">
              <div class="lawyers-title-wrap">
                <h1>{name}</h1>
              </div>
              <div class="lawyers-type">
                { this.displayCategories() }
              </div>
            </div>
            <div class="lawyers-info">
              <DisplayRating
                stars={[0,1,2,3,4]}
                currentRate={rating}
              />
              <span class="type">{fixedDescription && fixedDescription.length > 75
                ? fixedDescription.slice(0,35) + "..."
                : fixedDescription}</span>
              { region && region != "null" &&
                <span class="distance">{`${region} km`}</span>
              }
              <span class="city">{address}</span>
            </div>
          </div>
          <div class="lawyers-bookmarks">
            { <a href="#" onClick={this.triggerLawyerBookmark.bind(this)}>
                <i class={` ${isBookmarked ? 'icon-bookmarks-filled' : 'icon-bookmarks'}`}></i>
              </a> }
          </div>
          </Link>
        </div>
      )
    }
  }

export default LawyerCard
