import {combineReducers} from 'redux';

import {localeReducer} from 'react-localize-redux';
import currentUser from './currentUserReducer'

import topRatedLawyersReducer from './topRatedLawyers'

import lawyerRecommendedReducer from './lawyerRecommended'

import topRatedContractsReducer from './topRatedContracts'

import allContractsReducer from './allContracts'

import contractsWithFiltersReducer from './contractsWithFiltersReducer'

import lawyersByRegionReducer from './lawyersByRegion'

import lawyersByFilterReducer from './lawyersByFilter'

import allLawyersReducer from './allLawyers'

import lawyersForMapReducer from './lawyersForMap'

import countriesReducer from './countriesReducer'

import interestsReducer from './interestsReducer'

import lawyerByIdReducer from './lawyerById'

import contractReducer from './contractReducer'

import lawyersContractsReducer from './lawyerContracts'

import lawyersArticlesReducer from './lawyerArticles'

import articleReducer from './articleReducer'

import currentUserContractsReducer from './currentUserContracs'

import currentUserBookmarksLawyersReducer from './currentUserBookmarksLawyers'

import currentUserBookmarksArticlesReducer from './currentUserBookmarksArticles'

import currentUserBookmarksContractsReducer from './currentUserBookmarksContracts';

import guestArticlesReducer from './guestAritcleReducer'

import searchResultsHomeReducer from './searchResultsHome'

import articleReviewsReducer from './articleReviews'

import articleForSlider from './articleForSliderReducer'

import orderStatistic from './orderStatisticReducer'
import chat from './chat'

import notificaionListReducer from './notificaionItems'

import purchasedContracts from './purchasedContracts'

import currencyReducer from './currencyReducer'

const appReducer = combineReducers({
    locale: localeReducer,
    currentUser: currentUser,
    topRatedLawyers: topRatedLawyersReducer,
    lawyersRecommended: lawyerRecommendedReducer,
    allContracts: allContractsReducer,
    contract: contractReducer,
    topRatedContracts: topRatedContractsReducer,
    contractsWithFilters: contractsWithFiltersReducer,
    lawyersByRegion: lawyersByRegionReducer,
    lawyersByFilter: lawyersByFilterReducer,
    allLawyers: allLawyersReducer,
    lawyersForMap: lawyersForMapReducer,
    countries: countriesReducer,
    interests: interestsReducer,
    lawyerById: lawyerByIdReducer,
    lawyersContracts: lawyersContractsReducer,
    lawyersArticles: lawyersArticlesReducer,
    articles: articleReducer,
    currentUserContracts: currentUserContractsReducer,
    currentUserBookmarksLawyers: currentUserBookmarksLawyersReducer,
    currentUserBookmarksArticles: currentUserBookmarksArticlesReducer,
    currentUserBookmarksContracts: currentUserBookmarksContractsReducer,
    guestArticles: guestArticlesReducer,
    searchResultsHome: searchResultsHomeReducer,
    articleReviews: articleReviewsReducer,
    articleForSlider: articleForSlider,
    orderStatistic: orderStatistic,
    chat: chat,
    purchasedContracts: purchasedContracts,
    notificaionList: notificaionListReducer,
    currencies: currencyReducer
});

export default appReducer;
